import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState } from 'formstate';
import styled from 'styled-components';
import { RecipeFormState } from 'stores/ui/recipes';
import Recipe from 'models/recipe';
import { isNumber } from 'utils/forms/validators';
import { TextField, RaisedButton, Paper } from 'material-ui';

interface Props {
    recipe: Recipe;
    formState: FormState<RecipeFormState>;
    formStore: RecipeFormState;
    onSubmit: (event: React.FormEvent<HTMLFormElement>, recipe: Recipe) => Promise<void>;
    isPristine: (recipe: Recipe) => boolean;
}

const StyledPaper = styled(Paper)`
    width: 600px;
    margin-top: 30px;
`;

const SubmitButton = styled(RaisedButton)`
    margin: 20px 20px 20px 0px;
`;

const Form = styled.form`
    padding: 15px;
    display: flex;
    flex-direction: column;
`;

@observer
class RecipeComponent extends React.Component<Props, {}> {
    
    setPrice = (value: string) => {
        if (isNumber(value)) {
            let valueNumber = parseInt(value, 10);
            this.props.formStore.price.onChange(valueNumber);
        }
    }

  render() {
    let { recipe, formState, formStore, onSubmit, isPristine } = this.props;
    
    return (
      <StyledPaper>
        <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                onSubmit(e, recipe);
            }}
        >            
            <h2>
                {recipe.recipeInformation[1].name}
            </h2>
            <TextField
                floatingLabelText={'Pris (SEK)'}
                type="number"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    this.setPrice(e.currentTarget.value)}
                value={(formStore.price.value || formStore.price.value === 0) ? formStore.price.value : ''}
                errorText={formStore.price.error}
            />
            <TextField
                multiLine={true}
                floatingLabelText={'Receptbeskrivning (Svenska)'}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.sweDescription.onChange(e.currentTarget.value)}
                value={formStore.sweDescription.value}
                errorText={formStore.sweDescription.error}
                rows={1}
                rowsMax={12}
            />
            <TextField
                multiLine={true}
                floatingLabelText={'Receptbeskrivning (Engelska)'}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.engDescription.onChange(e.currentTarget.value)}
                value={formStore.engDescription.value}
                errorText={formStore.engDescription.error}
                rows={1}
                rowsMax={12}
            />
            <div>
                <SubmitButton
                    primary={true}
                    type="submit"
                    label={'Spara'}
                    disabled={formState.hasFieldError || isPristine(recipe)}
                    style={{ width: '200px' }}
                />
            </div>
        </Form>
      </StyledPaper>
    );
  }
}

export default RecipeComponent;