import * as React from 'react';
import LogInModal from './components/LogInModal';

class LogInPage extends React.Component {
  render() {
    return (
      <LogInModal />
    );
  }
}

export default LogInPage;
