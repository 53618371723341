import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import { EditorState } from "draft-js";
import { Paper, TextField } from "material-ui";
import { observer } from "mobx-react";
import { MenuItem } from "models/menu-item";
import { Language } from "models/translatable";
import * as React from "react";
import { Editor } from "react-draft-wysiwyg";
import InformationStore from "stores/domain/information";
import styled from "styled-components";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import BodyActions from "./BodyActions";

interface Props {
  informationStore: InformationStore;
  onAddItem: Function;
  onSaveItem: Function;
  language: Language;
  onDeleteItem: Function;
  moveUp: (item: MenuItem) => void;
  moveDown: (item: MenuItem) => void;
}

const StyledPaper = styled(Paper)`
  flex: 5 0 200px;
  padding: 20px;
  margin-left: 30px;
  margin-bottom: 30px;
`;

@observer
export default class BodyComponent extends React.Component<Props, {}> {
  handleChange = (e: EditorState) => {
    this.store.setSelectedEditorState(e, this.props.language);
  };
  handleTitleChange = (event: React.FormEvent<{}>, newTitle: string) => {
    this.store.setSelectedTitle(newTitle, this.props.language);
  };

  onAddMenu = () => {
    if (this.selectedItem) {
      this.props.onAddItem(this.selectedItem.id);
    }
  };

  onDeleteMenu = () => {
    this.props.onDeleteItem(this.selectedItem);
  };

  onSaveItem = () => {
    this.props.onSaveItem(this.selectedItem);
  };

  onMoveUp = () => {
    if (this.selectedItem !== null) {
      this.props.moveUp(this.selectedItem);
    }
  };
  onMoveDown = () => {
    if (this.selectedItem !== null) {
      this.props.moveDown(this.selectedItem);
    }
  };
  get store() {
    return this.props.informationStore;
  }

  get selectedItem() {
    return this.store.selectedItem;
  }

  get content() {
    if (this.selectedItem) {
      return this.selectedItem.menuItemContent.find((content) => {
        return this.props.language === content.language;
      });
    } else {
      return false;
    }
  }

  get EditorWrapperStyle() {
    return {
      border: "1px solid rgba(100,100,100,0.2)",
      margin: "20px auto",
    };
  }
  get EditorStyle(): React.CSSProperties {
    return {
      padding: "0 10px",
      maxHeight: "50vh",
      overflowY: "scroll",
    };
  }

  render() {
    if (this.store.isLoading) {
      return (
        <StyledPaper>
          <LoadingSpinner />
        </StyledPaper>
      );
    } else if (this.selectedItem && this.content) {
      return (
        <StyledPaper>
          <TextField
            floatingLabelText="Titel"
            hintText="Hur gör man.."
            value={this.content.title}
            onChange={this.handleTitleChange}
          />
          <Editor
            editorState={this.content.editorState}
            onEditorStateChange={(e: EditorState) => this.handleChange(e)}
            editorStyle={this.EditorStyle}
            wrapperStyle={this.EditorWrapperStyle}
          />
          <BodyActions
            onAdd={this.onAddMenu}
            onDelete={this.onDeleteMenu}
            onSave={this.onSaveItem}
            onMoveUp={this.onMoveUp}
            onMoveDown={this.onMoveDown}
            isSubitem={this.selectedItem.parentId !== null}
          />
        </StyledPaper>
      );
    } else {
      return (
        <StyledPaper>
          <p>Du måste välja en menyrad för att kunna ändra dess inehåll</p>
        </StyledPaper>
      );
    }
  }
}
