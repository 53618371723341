import * as React from 'react';
import styled from 'styled-components';
import { TextField, IconButton } from 'material-ui';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import { ActionDelete } from 'material-ui/svg-icons';

interface Props {
  vismaId: FieldState<number>;
  vismaCustomerNumber: FieldState<number>;
  onDelete: () => void;
  showButton: boolean;
}

const Input = styled(TextField)`
  flex: 1 0 auto;
  margin-right: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
@observer
class VismaInput extends React.Component<Props> {
  render() {
    const { vismaId, vismaCustomerNumber, onDelete, showButton: allowDelete } = this.props;
    return (
      <Wrapper>
        <Input
          type="number"
          value={vismaId.value}
          errorText={vismaId.error}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            vismaId.onChange(parseInt(e.currentTarget.value, 10))
          }
          floatingLabelText="Användar-ID i Visma"
        />
        <Input
          type="number"
          value={vismaCustomerNumber.value}
          errorText={vismaCustomerNumber.error}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            vismaCustomerNumber.onChange(parseInt(e.currentTarget.value, 10))
          }
          floatingLabelText="Kundnummer i Visma"
        />
        {allowDelete && (
          <IconButton onClick={onDelete}>
            <ActionDelete />
          </IconButton>
        )}
      </Wrapper>
    );
  }
}
export default VismaInput;
