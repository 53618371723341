import { action, makeObservable, observable } from "mobx";

export default class InformationUiStore {
  @observable dialogIsOpen: boolean = false;
  @observable snackbarIsOpen: boolean = false;
  @observable snackbarMessage: string = "";

  constructor() {
    makeObservable(this);
  }

  @action
  closeDialog() {
    this.dialogIsOpen = false;
  }

  @action
  openDialog() {
    this.dialogIsOpen = true;
  }

  @action
  openSnackbar(message: string) {
    this.snackbarIsOpen = true;
    this.snackbarMessage = message;
  }

  @action
  closeSnackbar() {
    this.snackbarIsOpen = false;
  }
}

export const informationUiStore = new InformationUiStore();
