import { FlatButton } from "material-ui";
import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import NewsContainer from "../../components/NewsContainer";

interface Props {}

interface PropsWithRoute extends Props, RouteComponentProps<{ id: string }> {}

class EditNewsPage extends React.Component<PropsWithRoute, {}> {
  render() {
    let id = parseInt(this.props.match.params.id, 10);

    return (
      <div>
        <Link to="/news">
          <FlatButton
            icon={<NavigationArrowBack />}
            label="Tillbaka"
            labelPosition="after"
          />
        </Link>
        <h1>Redigera nyhet</h1>
        <NewsContainer
          id={id}
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default withRouter<PropsWithRoute, typeof EditNewsPage>(EditNewsPage);
