import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { formatDateString } from "utils/dates";
import {
  Country,
  GetSamplingOrder,
  GetSamplingOrderVariables,
  SamplingOrderType,
} from "__generated__/graphql";

const GET_SAMPLING_ORDER = gql`
  query GetSamplingOrder($orderId: Int!) {
    adminScope {
      samplingOrders(first: 1, where: { and: { orderId: { eq: $orderId } } }) {
        nodes {
          id
          type
          distilleryDateTime
          systembolagetRequestNumber

          event {
            id
            vismaId
            name
            dateTime
            country
          }
        }
      }
    }
  }
`;

const mapType = (action?: SamplingOrderType) => {
  switch (action) {
    case SamplingOrderType.DISTILLERY:
      return "Destilleriet";
    case SamplingOrderType.SYSTEMBOLAGET:
      return "Systembolaget";
    case SamplingOrderType.EVENT:
      return "Evenemang";

    default:
      return "Okänd";
  }
};

const mapCountry = (action?: Country | null) => {
  switch (action) {
    case Country.SWEDEN:
      return "Sverige";
    case Country.DENMARK:
      return "Danmark";
    case Country.ESTONIA:
      return "Estland";
    case Country.GERMANY:
      return "Tyskland";

    default:
      return "Okänd";
  }
};

const SamplingOrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { loading, data } = useQuery<
    GetSamplingOrder,
    GetSamplingOrderVariables
  >(GET_SAMPLING_ORDER, {
    variables: { orderId: Number(orderId) },
  });
  const order = data?.adminScope.samplingOrders?.nodes![0]!;

  if (loading) return <Spinner />;

  if (!order) return null;

  return (
    <>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Typ</StatLabel>
            <StatNumber fontSize="lg">{mapType(order.type)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Provningstillfälle</StatLabel>
            <StatNumber fontSize="lg">{order.event?.name}</StatNumber>
            <StatNumber fontSize="lg">
              {mapCountry(order.event?.country)}
            </StatNumber>
            <StatNumber fontSize="lg">
              {formatDateString(order.event?.dateTime || "")}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Systembolags-nummer</StatLabel>
            <StatNumber fontSize="lg">
              {order.systembolagetRequestNumber}
            </StatNumber>
          </Stat>
        </StatGroup>
      </Box>
    </>
  );
};

export default SamplingOrderDetails;
