import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import User from "models/user";
import UserPageForm, { VismaInput, VismaInputs } from "models/user-page-form";
import UsersService, { SaveUserViewModel } from "services/users";
import { usersStore } from "stores/domain/users";
import { isEmail, minNumber, requiredType } from "utils/forms/validators";

class UsersPageUiStore {
  @observable searchTerm: string = "";
  @observable isSearching: boolean = false;
  @observable noUsersFound: boolean = false;
  @observable editingUserId: string | null = null;
  @observable saveError: string | null = null;
  @observable deleteError: string = "";
  @observable vismaInputs: VismaInputs = new FormState([] as any);

  @observable email: FieldState<string> = new FieldState("").validators(
    requiredType<string>("Email krävs"),
    isEmail("Ange en giltig email adress")
  );

  @observable emailConfirmed: FieldState<boolean> = new FieldState(
    false
  ).validators();

  @observable form: FormState<UserPageForm> = new FormState({
    email: this.email,
    emailConfirmed: this.emailConfirmed,
    vismaInputs: this.vismaInputs,
  });

  constructor() {
    makeObservable(this);
  }

  @action deleteVismaRow(index: number) {
    if (this.vismaInputs.$.length <= 1) {
      this.deleteError = "Det måste finnas minst ett visma konto";
    } else {
      this.vismaInputs.$.splice(index, 1);
    }
  }

  @action
  resetForm() {
    this.email.reset();
    this.emailConfirmed.reset();
    this.resetVismaInputs();
    this.form.clearFormError();
  }

  @action
  setForm(user: User) {
    this.email.value = user.email || "";
    this.emailConfirmed.value = user.emailConfirmed || false;
    this.resetVismaInputs();
    user.vismaUsers.forEach((vismaUser) =>
      this.vismaInputs.$.push(
        this.createVismaRow(
          vismaUser.id,
          vismaUser.vismaUserId,
          vismaUser.vismaCustomerNumber
        )
      )
    );
  }

  @computed
  get editingUser(): User | undefined {
    if (this.editingUserId === null) {
      return;
    }
    return usersStore.users.find((u) => u.id === this.editingUserId);
  }

  @action
  searchUsers = async () => {
    this.isSearching = true;
    let response = await UsersService.searchUsers(this.searchTerm);

    if (!response.succeeded) {
      usersStore.replaceUsers([]);
      throw new Error(`Couldn't fetch users`);
    }

    let users = response.data;

    if (!users || users.length === 0) {
      this.noUsersFound = true;
      this.isSearching = false;
      usersStore.replaceUsers([]);
      return;
    }

    this.noUsersFound = false;
    usersStore.replaceUsers(users);
    this.isSearching = false;
  };

  @action
  changeSearchTerm = (newValue: string) => {
    this.searchTerm = newValue;
  };

  @action
  addVismaInput() {
    this.vismaInputs.$.push(this.createVismaRow());
  }

  // Edit User Modal

  @action
  showEditUserModal = (userId: string) => {
    this.editingUserId = userId;

    const editingUser = this.editingUser;
    if (editingUser) {
      this.setForm(editingUser);
    }
  };

  @action hideEditUserModal = () => {
    this.editingUserId = null;
    this.saveError = null;
  };

  @action saveUser = async () => {
    const validated = await this.form.validate();
    if (validated.hasError || this.vismaInputs.$.length < 1) {
      // Shouldn't be able to save a user with errors
      return;
    }
    try {
      this.saveError = null;
      var response = await UsersService.saveUser(this.viewModel);

      if (response.succeeded) {
        this.hideEditUserModal();
        usersStore.replaceUser(response.data);
      } else if (response.hasErrors) {
        switch (response.error!.code) {
          case "DuplicateUserName":
          case "DuplicateEmail":
            this.saveError = "Den här e-postadressen används redan";
            break;
          default:
            this.saveError = response.error!.code;
        }
      }
    } catch (e) {
      this.saveError = "Ett okänt fel har uppåt";
    }
  };

  @action
  private resetVismaInputs() {
    this.vismaInputs.$.splice(0, this.vismaInputs.$.length);
  }

  private createVismaRow(
    localId: number | null = null,
    vismaId: number = 0,
    customerNumber: number = 0
  ): VismaInput {
    return new FormState({
      vismaId: this.createVismaIdField(vismaId),
      vismaCustomerNumber: this.createVismaCustomerNumberField(customerNumber),
      id: this.createLocalIdField(localId),
    });
  }

  private createLocalIdField(id: number | null = null) {
    return new FieldState(id);
  }
  private createVismaIdField(id: number = 0) {
    return new FieldState(id).validators(
      requiredType<number>("Visma id krävs"),
      minNumber(0, "Användar-ID måste vara större än noll")
    );
  }

  private createVismaCustomerNumberField(customerNumber: number = 0) {
    return new FieldState(customerNumber).validators(
      minNumber(-1, "Kundnummer får inte vara negativt")
    );
  }

  @computed
  private get viewModel(): SaveUserViewModel {
    return {
      id: this.editingUserId!,
      email: this.form.$.email.$,
      emailConfirmed: this.form.$.emailConfirmed.$,
      vismaUsers: this.form.$.vismaInputs.$.map((input) => {
        const { vismaId, vismaCustomerNumber, id } = input.$;
        return {
          id: id.$,
          vismaUserId: vismaId.$,
          vismaCustomerNumber: vismaCustomerNumber.$,
        };
      }),
    };
  }
}

export default UsersPageUiStore;
export const usersPageUiStore = new UsersPageUiStore();
