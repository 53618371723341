import { gql, useQuery } from "@apollo/client";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/DataTable";
import { useRef, useState } from "react";
import { RiUpload2Fill } from "react-icons/ri";
import { useHistory, useParams } from "react-router";
import UploadFileService from "services/upload-file-service";
import { formatDate } from "utils/dates";
import {
  CaskStatus,
  GetCask,
  GetCaskVariables,
  GetCask_adminScope_casks_nodes_caskUsers,
} from "__generated__/graphql";
import CaskOrdersTable from "./CaskOrdersTable";
import VismaOrderHistoryTable from "./VismaOrderHistoryTable";

const GET_CASK = gql`
  query GetCask($vismaId: Int!) {
    adminScope {
      casks(first: 1, where: { and: { vismaId: { eq: $vismaId } } }) {
        nodes {
          id
          vismaId
          vismaCaskNumber
          caskSign
          status
          notRefillable
          caskTypeText
          recipeText
          volumeFilled
          stockNumber
          loanClaim
          alcoholPercentage
          filled
          liquorFilled
          percentSmoked
          warehouseArea
          timesRefilled
          currentVolume
          loadVolume
          lastSyncedWithVisma
          imageThumb
          thumbImage
          fullImage
          recipe {
            id
            recipeInformation {
              language
              recipeId
              name
              description
            }
          }
          caskType {
            vismaId
            caskTypeInformation {
              language
              name
              description
            }
          }
          caskUsers {
            id
            userType
            vismaUser {
              vismaUserId
              vismaCustomerNumber
              phoneNumber
              lastSyncedWithVisma
            }
            user {
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const columnHelper =
  createColumnHelper<GetCask_adminScope_casks_nodes_caskUsers>();

const columns = [
  columnHelper.accessor("userType", {
    cell: (info) => (info.getValue() === "OWNER" ? "Ägare" : "Delägare"),
    header: "Typ",
  }),
  columnHelper.accessor("vismaUser", {
    cell: (info) => info.getValue()?.vismaUserId,
    header: "Visma-id",
  }),
  columnHelper.accessor("vismaUser", {
    cell: (info) => info.getValue()?.vismaCustomerNumber,
    header: "Visma-Kundnummer",
  }),
  columnHelper.accessor("user", {
    cell: (info) =>
      `${info.getValue()?.firstName} ${info.getValue()?.lastName}`,
    header: "Namn",
  }),
  columnHelper.accessor("user.email", {
    cell: (info) => info.getValue(),
    header: "E-post",
  }),
  columnHelper.accessor("vismaUser", {
    cell: (info) => info.getValue()?.phoneNumber,
    header: "Telefon",
  }),
  columnHelper.accessor("vismaUser", {
    cell: (info) => formatDate(info.getValue()?.lastSyncedWithVisma || ""),
    header: "Synkades",
  }),
];

const mapCaskStatus = (status: CaskStatus) => {
  switch (status) {
    case CaskStatus.REMOVED:
      return "Avbruten";
    case CaskStatus.FILLED:
      return "Fylld";
    case CaskStatus.FULL_BOTTLED:
      return "Buteljerad";
    case CaskStatus.FULL_BOTTLING_ORDERED:
      return "Buteljering beställd";
    case CaskStatus.NOT_FILLED:
      return "Tom";

    default:
      return "Okänd";
  }
};

const CaskPage = () => {
  const toast = useToast();
  const [fullImage, toggleImageSize] = useState<boolean>(false);
  const { vismaId } = useParams<{ vismaId: string }>();
  const { loading, data, refetch } = useQuery<GetCask, GetCaskVariables>(
    GET_CASK,
    {
      variables: { vismaId: Number(vismaId) },
    }
  );
  const cask = data?.adminScope.casks?.nodes![0]!;
  const history = useHistory();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChanged = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const target = evt.target as HTMLInputElement;

    if (target.files === null) {
      return;
    }

    toast({
      id: cask.id,
      title: "Fatbild laddas upp.",
      description: `Fatbilden för fatet laddas upp.`,
      status: "loading",
      duration: 20000,
    });

    UploadFileService.uploadCaskImage(target.files[0], cask!.id).then(
      async () => {
        toast.update(cask.id, {
          title: "Fatbild laddades upp.",
          description: `Fatbilden för fatet laddades upp.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await refetch();
      }
    );
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Box mb={4}>
        <Button
          mb={4}
          leftIcon={<ChevronLeftIcon />}
          variant="outline"
          onClick={() => history.goBack()}
        >
          Tillbaka
        </Button>
      </Box>
      <Heading size="lg" mb={4}>
        {`Fat ${cask.vismaId}`}
      </Heading>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Visma-id</StatLabel>
            <StatNumber fontSize="lg">{cask.vismaId}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Fatnummer</StatLabel>
            <StatNumber fontSize="lg">{cask.vismaCaskNumber}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Fatskylt</StatLabel>
            <StatNumber fontSize="lg">{cask.caskSign}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Lagerplats</StatLabel>
            <StatNumber fontSize="lg">{cask.stockNumber}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Lagerhus</StatLabel>
            <StatNumber fontSize="lg">{cask.warehouseArea}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Synkades</StatLabel>
            <StatNumber fontSize="lg">
              {formatDate(cask.lastSyncedWithVisma || "")}
            </StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Status</StatLabel>
            <StatNumber fontSize="lg">{mapCaskStatus(cask.status)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Tillgänglig för refill</StatLabel>
            <StatNumber fontSize="lg">
              {cask.notRefillable ? "Nej" : "Ja"}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Fattyp</StatLabel>
            <StatNumber fontSize="lg">{cask.caskTypeText}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Lånekrav</StatLabel>
            <StatNumber fontSize="lg">{cask.loanClaim}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Återfylld antal gånger</StatLabel>
            <StatNumber fontSize="lg">{cask.timesRefilled}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Alkoholhalt</StatLabel>
            <StatNumber fontSize="lg">{cask.alcoholPercentage}</StatNumber>
          </Stat>
        </StatGroup>
      </Box>
      <Heading size="lg" mb={2}>
        Fatbild
      </Heading>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <Box>
          {cask.imageThumb ? (
            <Image
              onClick={() => toggleImageSize(!fullImage)}
              src={fullImage ? cask.fullImage! : cask.thumbImage!}
              cursor="pointer"
            />
          ) : (
            <Text fontSize="lg">Fatet saknar bild</Text>
          )}
          <Input
            hidden
            ref={fileInputRef}
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(e) => onFileChanged(e)}
          />
          <Button
            mt={4}
            bg="white"
            size={"lg"}
            rightIcon={<RiUpload2Fill size="1.25rem" />}
            onClick={() => fileInputRef.current?.click()}
          >
            {cask.imageThumb ? "Ändra fatbild" : "Lägg till fatbild"}
          </Button>
        </Box>
      </Box>
      <Box p={4}>
        <Heading size="lg" mb={2}>
          Fatägare
        </Heading>
        <DataTable
          data={cask.caskUsers as GetCask_adminScope_casks_nodes_caskUsers[]}
          columns={columns}
        />
      </Box>
      {cask.id && <CaskOrdersTable caskId={cask.id} />}
      {cask.id && <VismaOrderHistoryTable caskId={cask.id} />}
    </>
  );
};

export default CaskPage;
