import User from "models/user";
import { HOST } from "./api";

// remove
import { envConfig } from "config";
import {
  JsonApiError,
  JsonApiResponse,
  toJsonApiResponse,
} from "utils/json-api";
import Api from "./api";

export interface RegistrationViewModel {
  firstName: string;
  lastName: string;
  password: string;
  repeatedPassword: string;
  email: string;
  language: string;
}

export interface LoginResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

const CLIENT_ID = envConfig.REACT_APP_CLIENT_ID;
const SCOPE = ["boxwebapi", "offline_access", "openid", "profile"];

export const LS_ACCESS_TOKEN = "box_access_token";
export const LS_REFRESH_TOKEN = "box_refresh_token";
export const LS_EXPIRATION = "box_expiration";

const loginBody = {
  client_id: CLIENT_ID,
  grant_type: "password",
  scope: SCOPE.join(" "),
};

const refreshTokenBody = {
  client_id: CLIENT_ID,
  grant_type: "refresh_token",
};

interface LoginJsonResponse {
  error: string;
  description: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

interface LoginJsonResponseData {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

class SessionService {
  get refreshToken(): string | null {
    return localStorage.getItem(LS_REFRESH_TOKEN);
  }

  async getCurrentUser(): Promise<JsonApiResponse<User>> {
    let response = await Api.get("accounts/me");
    return await toJsonApiResponse<User>(response);
  }

  async login(username: string, password: string) {
    let body = this.getUrlEncodedString({ ...loginBody, username, password });
    console.log(HOST);
    let request = new Request(`${HOST}/connect/token`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    });

    try {
      let response = await fetch(request);
      return this.getLoginResponse(response);
    } catch (error) {
      throw new Error(`Couldn't log in. ${error}`);
    }
  }

  async getLoginResponse(response: Response) {
    let loginResponse = <LoginJsonResponse>await response.json();
    let jsonApiResponse = new JsonApiResponse<LoginJsonResponseData>();
    let succeeded = response.status === 200;
    jsonApiResponse.statusCode = response.status;
    jsonApiResponse.succeeded = succeeded;

    if (!succeeded) {
      let errors: JsonApiError[] = [];
      let error = new JsonApiError();
      error.code = loginResponse.error;
      error.detail = loginResponse.description;
      errors.push(error);

      jsonApiResponse.errors = errors;
    }

    jsonApiResponse.data = {
      access_token: loginResponse.access_token,
      expires_in: loginResponse.expires_in,
      refresh_token: loginResponse.refresh_token,
    };

    return jsonApiResponse;
  }

  async refreshTokens() {
    let refreshToken = this.refreshToken;
    if (refreshToken == null) {
      throw new Error(`Couldn't find refresh token in local storage`);
    }
    let body = this.getUrlEncodedString({
      ...refreshTokenBody,
      refresh_token: refreshToken,
    });

    let request = new Request(`${HOST}/connect/token`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    });

    try {
      let response = await fetch(request);
      return this.getLoginResponse(response);
    } catch (error) {
      throw new Error(`Couldn't refresh tokens ${error}`);
    }
  }

  /**
   * Returns an urlEncoded string from a sent in object
   *
   * @param data object that's getting transformed
   */
  private getUrlEncodedString(data: any): string {
    if (!data) {
      return "";
    }

    let strings = Array<string>();

    for (var k in data) {
      if (data.hasOwnProperty(k)) {
        let key = encodeURIComponent(k);
        let value = encodeURIComponent(data[k]);
        strings.push(`${key}=${value}`);
      }
    }

    // replaces scope format
    return strings.join("&");
  }
}

export default new SessionService();
