import jwt_decode from "jwt-decode";
import { action, computed, makeObservable, observable } from "mobx";
import User, { AccountRole, AccountType } from "models/user";
import SessionService from "services/session";
import { caskTypesStore } from "stores/domain/cask-types";
import { newsStore } from "stores/domain/news";
import { recipesStore } from "stores/domain/recipes";

export const LS_ACCESS_TOKEN = "box_access_token";
export const LS_REFRESH_TOKEN = "box_refresh_token";
export const LS_EXPIRATION = "box_expiration";

class SessionStore {
  @observable user!: User;
  @observable isAuthenticated: boolean = false;

  constructor() {
    makeObservable(this);
  }

  private _accessToken: string | undefined;
  private _refreshToken: string | undefined;
  private _expirationDate: Date | undefined;

  @action
  async fetchUser() {
    let { succeeded, statusCode, data } = await SessionService.getCurrentUser();
    if (!succeeded) {
      if (statusCode !== 401) {
        this.logOut();
        return;
      }

      this.logOut();
      return;
    }

    this.isAuthenticated = true;
    this.user = data;
  }

  @computed
  get name() {
    if (!this.user || !this.user.vismaUser) {
      return "unknown";
    }

    return this.isPersonalAccount
      ? this.user.vismaUser.firstName + " " + this.user.vismaUser.lastName
      : this.user.vismaUser.orgName;
  }
  @computed
  get isPersonalAccount(): Boolean {
    if (!this.user) {
      return true;
    }

    return this.user.vismaUser.accountType === AccountType.Person;
  }

  get accessToken(): string | undefined {
    if (this._accessToken) {
      return this._accessToken;
    }

    let lsToken = localStorage.getItem(LS_ACCESS_TOKEN);
    let token = lsToken !== null ? lsToken : undefined;
    return token;
  }

  set accessToken(token: string | undefined) {
    this._accessToken = token;

    if (token) {
      localStorage.setItem(LS_ACCESS_TOKEN, token);
    } else {
      localStorage.removeItem(LS_ACCESS_TOKEN);
    }
  }

  get refreshToken(): string | undefined {
    if (this._refreshToken) {
      return this._refreshToken;
    }

    let lsToken = localStorage.getItem(LS_REFRESH_TOKEN);
    let token = lsToken !== null ? lsToken : undefined;
    return token;
  }

  set refreshToken(token: string | undefined) {
    this._refreshToken = token;

    if (token) {
      localStorage.setItem(LS_REFRESH_TOKEN, token);
    } else {
      localStorage.removeItem(LS_REFRESH_TOKEN);
    }
  }

  get expirationDate(): Date | undefined {
    if (this._expirationDate) {
      return this._expirationDate;
    }

    let value = localStorage.getItem(LS_EXPIRATION);
    if (!value) {
      return;
    }

    let expirationDate = new Date(parseInt(value, 10));
    this._expirationDate = expirationDate;
    return expirationDate;
  }

  set expirationDate(expirationDate: Date | undefined) {
    this._expirationDate = expirationDate;

    if (expirationDate instanceof Date) {
      localStorage.setItem(LS_EXPIRATION, expirationDate.getTime().toString());
    } else {
      localStorage.removeItem(LS_EXPIRATION);
    }
  }

  @action
  async logIn(username: string, password: string) {
    let response = await SessionService.login(username, password);
    let { access_token, expires_in, refresh_token } = response.data;

    if (response.succeeded) {
      if (this.getUserRole(access_token) !== AccountRole.Admin) {
        response.succeeded = false;
      } else {
        this.persistTokens(access_token, refresh_token, expires_in);
        this.logInCurrentUser();
      }
    }

    return response;
  }

  @action
  async logInCurrentUser() {
    // if user has never logged in aka no tokens in ls
    if (
      this.accessToken === undefined ||
      this.refreshToken === undefined ||
      this.expirationDate === undefined
    ) {
      this.logOut();
      return;
    }

    // if user has an access token that is expired or about to, renew it
    if (new Date().getTime() > this.refreshDate.getTime()) {
      let succeeded = await this.refreshTokens();
      if (!succeeded) {
        this.logOut();
        return;
      }
    }

    await this.fetchUser();
    await this.fetchData();

    this.startRefreshTimer();
  }

  async fetchData() {
    if (this.isAuthenticated) {
      await caskTypesStore.fetchCasks();
      await recipesStore.fetchRecipes();
      await newsStore.fetchNews();
    }
  }

  get refreshInMs() {
    return this.refreshDate.getTime() - new Date().getTime();
  }

  get refreshDate() {
    let refreshDate = new Date(this.expirationDate!.getTime());
    refreshDate.setMinutes(refreshDate.getMinutes() - 4);
    return refreshDate;
  }

  @action
  async startRefreshTimer() {
    if (this.isAuthenticated) {
      setTimeout(async () => {
        await this.refreshTokens();
        this.startRefreshTimer();
      }, this.refreshInMs);
    }
  }

  @action
  async refreshTokens(): Promise<boolean> {
    if (this.refreshToken !== undefined) {
      let response = await SessionService.refreshTokens();
      if (!response.succeeded) {
        this.logOut();
        return false;
      }

      let { access_token, expires_in, refresh_token } = response.data;
      this.persistTokens(access_token, refresh_token, expires_in);
    }

    return true;
  }

  @action
  logOut() {
    this.isAuthenticated = false;
    this.removeTokens();
  }

  /**
   * Saves tokens in local storage
   *
   * @param accessToken
   * @param refreshToken
   * @param expiresIn
   */
  private persistTokens(
    accessToken: string,
    refreshToken: string,
    expiresIn: number
  ): void {
    let expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + expiresIn);

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.expirationDate = expirationDate;
  }

  private removeTokens() {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.expirationDate = undefined;
  }

  private getUserRole(accessToken: string): AccountRole | undefined {
    if (accessToken) {
      let { role } = jwt_decode<any>(accessToken);
      switch (role) {
        case "user":
          return AccountRole.User;
        case "admin":
          return AccountRole.Admin;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }
}

export default SessionStore;
export const sessionStore = new SessionStore();
