import { gql, useQuery } from "@apollo/client";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/DataTable";
import { NavLink } from "react-router-dom";
import { formatDateString } from "utils/dates";
import {
  GetVismaOrderHistory,
  GetVismaOrderHistoryVariables,
  GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes,
  OrderStatus,
  VismaCaskOrderHistoryType,
} from "__generated__/graphql";
import DownloadSettlementButton from "./DownloadSettlementButton";

const GET_VISMA_ORDER_HISTORY = gql`
  query GetVismaOrderHistory($caskId: Int!) {
    adminScope {
      vismaCaskOrderHistory(where: { and: { caskId: { eq: $caskId } } }) {
        nodes {
          id
          customerNumber
          type
          vismaOrderId
          activityName
          actionName
          bottlingAlcohol
          bottlingRoundName
          bottlingVolumeName
          dilution
          status
          preferedDeliveryTime
          alcohol
          bottlingAlcohol
          comment
          isSettlement
          localVismaUser {
            id
            vismaUserId
            vismaCustomerNumber
            firstName
            lastName
            email
          }
          orderRows {
            description
            id
            finishDate
          }
        }
      }
    }
  }
`;

export const mapOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CANCELLED:
      return "Avbruten";
    case OrderStatus.COMPLETED:
      return "Slutförd";
    case OrderStatus.DECLINED:
      return "Avvisad";
    case OrderStatus.PENDING:
      return "Väntande";
    default:
      return "Okänd";
  }
};

export const mapOrderType = (status: VismaCaskOrderHistoryType) => {
  switch (status) {
    case VismaCaskOrderHistoryType.PRODUCTION:
      return "Produktion";
    case VismaCaskOrderHistoryType.PURCHASE:
      return "Köp";
    case VismaCaskOrderHistoryType.STOCK_TRANSFER:
      return "Förflyttning";
    default:
      return "Okänd";
  }
};

const columnHelper =
  createColumnHelper<GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes>();

const columns = [
  columnHelper.accessor("vismaOrderId", {
    cell: (info) => (
      <NavLink
        to={window.location.pathname + "/visma-order/" + info.row.original.id}
      >
        {info.getValue()}
      </NavLink>
    ),
    header: "Visma-id",
  }),
  columnHelper.accessor("type", {
    cell: (info) => mapOrderType(info.getValue()),
    header: "Typ",
  }),
  columnHelper.accessor("orderRows", {
    cell: (info) =>
      info.getValue()![0] ? info.getValue()![0]!.description : "",
    header: "Beskrivning",
  }),
  columnHelper.accessor("orderRows", {
    cell: (info) =>
      info.getValue()![0]
        ? formatDateString(info.getValue()![0]!.finishDate || "")
        : "",
    header: "Datum",
  }),
  columnHelper.accessor("isSettlement", {
    cell: (info) =>
      info.getValue() && (
        <Box display="flex">
          <DownloadSettlementButton
            vismaOrderHistoryId={info.cell.row.original.id}
            language="sv"
          />
          <Box ml={2}>
            <DownloadSettlementButton
              vismaOrderHistoryId={info.cell.row.original.id}
              language="en"
            />
          </Box>
        </Box>
      ),
    header: "Avräkningsnota",
  }),
  columnHelper.accessor("localVismaUser", {
    cell: (info) =>
      `${info.getValue()?.firstName} ${info.getValue()?.lastName}`,
    header: "Kund",
  }),
];

const VismaOrderHistoryTable: React.FC<{ caskId: number }> = ({ caskId }) => {
  const { loading, data } = useQuery<
    GetVismaOrderHistory,
    GetVismaOrderHistoryVariables
  >(GET_VISMA_ORDER_HISTORY, {
    variables: { caskId },
  });
  const orders = data?.adminScope.vismaCaskOrderHistory?.nodes;

  if (loading) return <Spinner />;

  return (
    <>
      <Box mt={4} p={4}>
        <Heading size="lg" mb={2}>
          Ordrar i Visma
        </Heading>
        <DataTable
          data={
            orders as GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes[]
          }
          columns={columns}
        />
      </Box>
    </>
  );
};

export default VismaOrderHistoryTable;
