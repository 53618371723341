import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { SyncVismaUser, SyncVismaUserVariables } from "__generated__/graphql";
import React from "react";

const SYNC_VISMA_USER_MUTATION = gql`
  mutation SyncVismaUser($vismaUserId: Int!) {
    syncVismaUser(vismaUserId: $vismaUserId) {
      name
    }
  }
`;

const VismaUserSyncer: React.FC = ({}) => {
  const toast = useToast();
  const [input, setInput] = React.useState("");
  const [submit, { loading }] = useMutation<
    SyncVismaUser,
    SyncVismaUserVariables
  >(SYNC_VISMA_USER_MUTATION);

  const submitVismaUser = async () => {
    try {
      var user = await submit({ variables: { vismaUserId: parseInt(input) } });

      toast({
        title: "Användaren synkad",
        description: `${user.data?.syncVismaUser.name} har synkats.`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setInput("");
    } catch (e) {
      console.error("Failed to sync visma user", e);
      toast({
        title: "Användaren hittades inte",
        description: "Kontrollera att du angav rätt aktörsnummer",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="450px">
      <FormLabel>Synka Visma-användare</FormLabel>
      <InputGroup>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          type="text"
          placeholder="Ange Visma-användarens aktörsnummer"
        />
        <InputRightElement width="4.5rem">
          <Button
            isLoading={loading}
            h="1.75rem"
            size="sm"
            onClick={submitVismaUser}
            isDisabled={loading || !input}
          >
            Synka
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default VismaUserSyncer;
