import { RaisedButton, TextField } from "material-ui";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import LoginFormUiStore from "stores/ui/login-form";
import styled from "styled-components";

interface ThemeProps {
  theme?: __MaterialUI.Styles.MuiTheme;
}

interface Props {
  loginFormUiStore: LoginFormUiStore;
  hasFieldError: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const Form = styled.form`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const FormError = styled.div`
  color: ${(props: ThemeProps) => "#f44336"};
`;

@observer
export default class UserRegisterForm extends Component<Props, {}> {
  render() {
    let { loginFormUiStore } = this.props;

    return (
      <Form onSubmit={this.props.onSubmit}>
        {loginFormUiStore.hasAuthenticationError ? (
          <FormError>Problem att logga in</FormError>
        ) : null}

        <TextField
          floatingLabelText="Email"
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            loginFormUiStore.email.onChange(e.currentTarget.value)
          }
          value={loginFormUiStore.email.value}
          errorText={loginFormUiStore.email.error}
        />
        <TextField
          type="password"
          floatingLabelText="Lösenord"
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            loginFormUiStore.password.onChange(e.currentTarget.value)
          }
          value={loginFormUiStore.password.value}
          errorText={loginFormUiStore.password.error}
        />
        <hr />
        <RaisedButton
          primary={true}
          type="submit"
          label="Logga in"
          disabled={this.props.hasFieldError}
        />
      </Form>
    );
  }
}
