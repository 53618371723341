import { inject, observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import SessionStore from "stores/domain/session";
import Drawer from "./Drawer";

interface Props {
  sessionStore?: SessionStore;
  children?: React.ReactNode;
}

@inject("sessionStore")
@observer
class DrawerContainer extends Component<Props, {}> {
  render() {
    let { isAuthenticated, user } = this.props.sessionStore!;
    let email: string | undefined = undefined;
    if (user && isAuthenticated) {
      email = user.email;
    }

    return (
      <Drawer isAuthenticated={isAuthenticated} userEmail={email}>
        {this.props.children}
      </Drawer>
    );
  }
}

export default DrawerContainer;
