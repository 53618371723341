import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import BottlingPriceVariables from "models/bottling-price-variables";

export type BottlingPriceVariablesForm = {
  vatRate: FieldState<number>;
  alcoholDutyRate: FieldState<number>;
  exchangeRate: FieldState<number>;
  vendorMarginRate: FieldState<number>;
  vendorFlatRate: FieldState<number>;
};

class BottlingPriceVariablesFormUiStore {
  @observable bottlingPriceVariablesForms: {
    bottlingPriceVariables: BottlingPriceVariables;
    formState: FormState<BottlingPriceVariablesForm>;
    formStore: BottlingPriceVariablesForm;
  }[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  resetForm() {
    this.bottlingPriceVariablesForms = [];
  }

  isPristine = (bottlingPriceVariables: BottlingPriceVariables): boolean => {
    let bottlingPriceVariablesInstance = this.bottlingPriceVariablesForms.find(
      (element) =>
        element.bottlingPriceVariables.id === bottlingPriceVariables.id
    );

    if (!bottlingPriceVariablesInstance) {
      throw new Error(`
        Could not check pristiness, bottling prices with id ${bottlingPriceVariables.id} couldn't be found
      `);
    }

    if (bottlingPriceVariablesInstance.formStore.exchangeRate.dirty) {
      return false;
    }
    if (bottlingPriceVariablesInstance.formStore.vatRate.dirty) {
      return false;
    }
    if (bottlingPriceVariablesInstance.formStore.alcoholDutyRate.dirty) {
      return false;
    }
    if (bottlingPriceVariablesInstance.formStore.vendorFlatRate.dirty) {
      return false;
    }
    if (bottlingPriceVariablesInstance.formStore.vendorMarginRate.dirty) {
      return false;
    }

    return true;
  };

  findFormStore(id: number): BottlingPriceVariablesForm {
    let bottlingPriceVariablesInstance = this.bottlingPriceVariablesForms.find(
      (element) => element.bottlingPriceVariables.id === id
    );

    if (!bottlingPriceVariablesInstance) {
      throw new Error(
        `Form store for bottling prices with id ${id} couldn't be found`
      );
    }
    return bottlingPriceVariablesInstance.formStore;
  }

  findForm(id: number): FormState<BottlingPriceVariablesForm> {
    let bottlingPriceVariablesInstance = this.bottlingPriceVariablesForms.find(
      (element) => element.bottlingPriceVariables.id === id
    );

    if (!bottlingPriceVariablesInstance) {
      throw new Error(
        `Form state for bottling prices with id ${id} couldn't be found`
      );
    }
    return bottlingPriceVariablesInstance.formState;
  }

  @action
  setForm(bottlingPriceVariables: BottlingPriceVariables) {
    let bottlingPriceVariablesInstance = this.bottlingPriceVariablesForms.find(
      (element) =>
        element.bottlingPriceVariables.id === bottlingPriceVariables.id
    );

    let exchangeRate = new FieldState(bottlingPriceVariables.exchangeRate);
    let vatRate = new FieldState(bottlingPriceVariables.vatRate);
    let alcoholDutyRate = new FieldState(
      bottlingPriceVariables.alcoholDutyRate
    );
    let vendorFlatRate = new FieldState(bottlingPriceVariables.vendorFlatRate);
    let vendorMarginRate = new FieldState(
      bottlingPriceVariables.vendorMarginRate
    );

    let formStore: BottlingPriceVariablesForm = {
      exchangeRate,
      vatRate,
      alcoholDutyRate,
      vendorFlatRate,
      vendorMarginRate,
    };

    let formState = new FormState<BottlingPriceVariablesForm>({
      exchangeRate,
      vatRate,
      alcoholDutyRate,
      vendorFlatRate,
      vendorMarginRate,
    });

    if (!bottlingPriceVariablesInstance) {
      this.bottlingPriceVariablesForms.push({
        bottlingPriceVariables,
        formState,
        formStore,
      });
    } else {
      bottlingPriceVariablesInstance.bottlingPriceVariables =
        bottlingPriceVariables;
      bottlingPriceVariablesInstance.formState = formState;
      bottlingPriceVariablesInstance.formStore = formStore;
    }
  }
}

export default BottlingPriceVariablesFormUiStore;
export const bottlingPriceVariablesFormUiStore =
  new BottlingPriceVariablesFormUiStore();
