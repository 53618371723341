import { Box, Progress, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useQueueState } from "rooks";
import CaskImagePreview from "./CaskImagePreview";

const ManualCaskImagesQueue: FC<{ files: File[] }> = ({ files }) => {
  const [initialLength] = useState(files.length);
  const [list, { dequeue }] = useQueueState(files);

  if (list.length === 0) return null;

  return (
    <Box>
      <Text fontSize="md" mb={4}>{`Behandlar bild ${
        initialLength - list.length + 1
      } av ${initialLength}`}</Text>
      <Progress
        value={((initialLength - list.length + 1) / initialLength) * 100}
      />
      <Box mt={8}>
        <CaskImagePreview image={list.at(0)!} dequeue={dequeue} />
      </Box>
    </Box>
  );
};

export default ManualCaskImagesQueue;
