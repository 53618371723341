import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { isEmail, required } from "utils/forms/validators";

type LoginFormState = {
  email: FieldState<string>;
  password: FieldState<string>;
};

class LoginFormUiStore {
  @observable
  hasAuthenticationError!: boolean;

  constructor() {
    makeObservable(this);
  }

  email: FieldState<string> = new FieldState("").validators(
    required("Email krävs"),
    isEmail("Ange en giltig emailadress")
  );

  password: FieldState<string> = new FieldState("").validators(
    required("Lösenord krävs")
  );

  form: FormState<LoginFormState> = new FormState({
    email: this.email,
    password: this.password,
  });

  @action
  resetForm() {
    this.email.reset();
    this.password.reset();
    this.hasAuthenticationError = false;
  }
}

export default LoginFormUiStore;
export const loginFormUiStore = new LoginFormUiStore();
