import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import BodyComponent from './BodyComponent';
import MenuComponent from './MenuComponent';
import InformationStore from 'stores/domain/information';
import styled from 'styled-components';
import { ContentState, convertToRaw } from 'draft-js';
import { Language } from 'models/translatable';
import { MenuItem } from 'models/menu-item';
import InformationService from 'services/information';
import { Snackbar } from 'material-ui';
import { InformationUiStore } from 'stores';

interface Props extends RouteComponentProps<{}> {
  informationStore?: InformationStore;
  informationUiStore?: InformationUiStore;
}
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-flow: row wrap;
`;

@inject('informationStore', 'informationUiStore')
@observer
export default class InformationContainer extends React.Component<Props, {}> {
  componentWillMount() {
    this.informationStore.fetchInformation();
    this.informationStore.setDefault();
  }

  onAddItem = async (parentId?: number) => {
    const response = await InformationService.createMenuItem({
      id: NaN,
      parentId: parentId,
      position: 100,
      menuContent: [
        {
          title: 'A Title',
          body: JSON.stringify(convertToRaw(ContentState.createFromText(''))),
          language: Language.English
        },
        {
          title: 'En Titel',
          body: JSON.stringify(convertToRaw(ContentState.createFromText(''))),
          language: Language.Swedish
        }
      ]
    });

    if (!response.succeeded || response.data === null) {
      throw new Error(`Couldn't Create menu item`);
    }

    this.informationStore.fetchInformation();
  }

  onSaveItem = async (item: MenuItem) => {
    const title = item.menuItemContent.find(
      content => content.language === Language.Swedish
    )!.title;
    try {
      this.informationStore.startLoading();
      const response = await InformationService.editMenuItem(
        this.createMenuItemVm(item)
      );
      this.informationStore.stopLoading();

      if (!response.succeeded) {
        if (response.statusCode === 403) {
          this.informationUiStore.openSnackbar(
            `"${title}" kunde inte sparas: Åkomst nekas`
          );
        } else {
          this.informationUiStore.openSnackbar(
            `"${title}" kunde inte sparas: ${response.error}`
          );
        }
      } else {
        this.informationUiStore.openSnackbar(`"${title}" har sparats`);
      }
    } catch (error) {
      this.informationStore.stopLoading();
      this.informationUiStore.openSnackbar(
        `"${title}" Kunde inte sparas: ${error}`
      );
    }
  }

  onDeleteItem = async (item: MenuItem) => {
    const response = await InformationService.deleteMenuItem(
      this.createMenuItemVm(item)
    );
    if (!response.succeeded) {
      if (response.statusCode === 403) {
        throw new Error('Could\'t change menu item: Access denied.');
      } else {
        throw new Error('Could\'t change menu item.');
      }
    }
    await this.informationStore.fetchInformation();
    this.informationStore.setDefault();
  }

  onMoveUp = (item: MenuItem) => {
    this.informationStore.updateMenuItems(
      this.moveItem(item, true, this.informationStore.menuItems)
    );
    this.informationStore.sortItems();
  }

  onMoveDown = (item: MenuItem) => {
    this.informationStore.updateMenuItems(
      this.moveItem(item, false, this.informationStore.menuItems)
    );
    this.informationStore.sortItems();
  }

  get informationUiStore() {
    return this.props.informationUiStore!;
  }

  get informationStore() {
    return this.props.informationStore!;
  }

  render() {
    const languages = [Language.Swedish, Language.English];
    return languages.map(language => {
      return (
        <div key={language}>
          <h2>{language === Language.Swedish ? 'Svenska' : 'Engelska'}</h2>
          <Container>
            <MenuComponent
              informationStore={this.informationStore}
              onAddItem={this.onAddItem}
              language={language}
            />
            <BodyComponent
              informationStore={this.informationStore}
              onAddItem={this.onAddItem}
              onSaveItem={this.onSaveItem}
              onDeleteItem={this.onDeleteItem}
              moveUp={this.onMoveUp}
              moveDown={this.onMoveDown}
              language={language}
            />
          </Container>
          <Snackbar
            open={this.informationUiStore.snackbarIsOpen}
            message={this.informationUiStore.snackbarMessage}
            autoHideDuration={4000}
            onRequestClose={() => this.informationUiStore.closeSnackbar()}
          />
        </div>
      );
    });
  }

  private createMenuItemVm(item: MenuItem) {
    return {
      id: item.id,
      parentId: item.parentId,
      menuContent: this.saveState(item),
      position: item.position
    };
  }

  private saveState(item: MenuItem) {
    return item.menuItemContent.map(content => {
      const contentState = content.editorState!.getCurrentContent();
      content.body = JSON.stringify(convertToRaw(contentState));
      return content;
    });
  }

  private moveItem(item: MenuItem, up: boolean, items: MenuItem[]) {
    for (let index = 0; index < items.length; index++) {
      // Ensure that the item has been found and that it isn't already the last or first item
      if (
        item.id === items[index].id &&
        ((!up && index < items.length - 1) || (up && index > 0))
      ) {
        let newPos = up ? index - 1 : index + 1;

        items[index].position = newPos;
        items[newPos].position = index;

        this.onSaveItem(items[newPos]);
        this.onSaveItem(items[index]);
        break;
      } else if (items[index].children) {
        items[index].children = this.moveItem(item, up, items[index].children);
      }
    }
    return items;
  }
}
