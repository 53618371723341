import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import {
  FlavourNoteLanguage,
  GetFlavourNote,
  GetFlavourNoteVariables,
} from "__generated__/graphql";

const GET_FLAVOUR_NOTE_ORDER = gql`
  query GetFlavourNote($orderId: Int!) {
    adminScope {
      flavourNoteOrders(
        first: 1
        where: { and: { orderId: { eq: $orderId } } }
      ) {
        nodes {
          id
          obtainedLanguage
        }
      }
    }
  }
`;

const FlavourNoteOrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { loading, data } = useQuery<GetFlavourNote, GetFlavourNoteVariables>(
    GET_FLAVOUR_NOTE_ORDER,
    {
      variables: { orderId: Number(orderId) },
    }
  );
  const order = data?.adminScope.flavourNoteOrders?.nodes![0]!;

  if (loading) return <Spinner />;

  if (!order) return null;

  return (
    <>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Språk</StatLabel>
            <StatNumber fontSize="lg">
              {order.obtainedLanguage === FlavourNoteLanguage.SWEDISH
                ? "Svenska"
                : "Engelska"}
            </StatNumber>
          </Stat>
        </StatGroup>
      </Box>
    </>
  );
};

export default FlavourNoteOrderDetails;
