import { envConfig } from "config";
import { LS_ACCESS_TOKEN } from "stores/domain/session";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const HOST = envConfig.REACT_APP_API_HOST;

class Api {
  get accessToken() {
    return localStorage.getItem(LS_ACCESS_TOKEN);
  }
  async get(
    url: string,
    authenticated: boolean = true,
    headers: any = {},
    search?: Object,
    namespace: string = "api",
    replaceHeaders: boolean = false
  ) {
    if (!replaceHeaders) {
      headers = { ...defaultHeaders, ...headers };
    }

    if (authenticated) {
      let accessToken = this.accessToken;
      if (!accessToken) {
        throw new Error("Can't make authorized request, no access token found");
      }

      headers = { ...headers, ...{ Authorization: `Bearer ${accessToken}` } };
    }

    if (search !== undefined) {
      let searchParams = new URLSearchParams(<URLSearchParams>search);
      url += `?${searchParams.toString()}`;
    }

    let request = new Request(`${HOST}/${namespace}/${url}`, {
      method: "GET",
      headers,
      mode: "cors",
    });

    return await fetch(request);
  }

  async post(
    url: string,
    authenticated: boolean = true,
    headers: any = {},
    body: Object | FormData = {},
    namespace: string = "api",
    replaceHeaders: boolean = false
  ) {
    if (!replaceHeaders) {
      headers = { ...defaultHeaders, ...headers };
    }

    if (authenticated) {
      let accessToken = this.accessToken;
      if (!accessToken) {
        throw new Error("Can't make authorized request, no access token found");
      }

      headers = { ...headers, ...{ Authorization: `Bearer ${accessToken}` } };
    }

    let requestBody = body instanceof FormData ? body : JSON.stringify(body);

    let request = new Request(`${HOST}/${namespace}/${url}`, {
      method: "POST",
      headers,
      body: requestBody,
      mode: "cors",
    });

    return await fetch(request);
  }

  async put(
    url: string,
    authenticated: boolean = true,
    headers: any = {},
    body: Object | FormData = {},
    namespace: string = "api",
    replaceHeaders: boolean = false
  ) {
    if (!replaceHeaders) {
      headers = { ...defaultHeaders, ...headers };
    }

    if (authenticated) {
      let accessToken = this.accessToken;
      if (!accessToken) {
        throw new Error("Can't make authorized request, no access token found");
      }

      headers = { ...headers, ...{ Authorization: `Bearer ${accessToken}` } };
    }

    let requestBody = body instanceof FormData ? body : JSON.stringify(body);

    let request = new Request(`${HOST}/${namespace}/${url}`, {
      method: "PUT",
      headers,
      body: requestBody,
      mode: "cors",
    });

    return await fetch(request);
  }

  async delete(
    url: string,
    authenticated: boolean = true,
    headers: any = {},
    body: Object | FormData = {},
    namespace: string = "api",
    replaceHeaders: boolean = false
  ) {
    if (!replaceHeaders) {
      headers = { ...defaultHeaders, ...headers };
    }

    if (authenticated) {
      let accessToken = this.accessToken;
      if (!accessToken) {
        throw new Error("Can't make authorized request, no access token found");
      }

      headers = { ...headers, ...{ Authorization: `Bearer ${accessToken}` } };
    }

    let requestBody = body instanceof FormData ? body : JSON.stringify(body);

    let request = new Request(`${HOST}/${namespace}/${url}`, {
      method: "DELETE",
      headers,
      body: requestBody,
      mode: "cors",
    });

    return await fetch(request);
  }
}

export default new Api();
