import Dialog from "material-ui/Dialog";
import IconButton from "material-ui/IconButton";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ActionEdit from "material-ui/svg-icons/image/edit";
import { observer } from "mobx-react";
import CaskType from "models/cask-type";
import * as React from "react";
import styled, { CSSProperties } from "styled-components";
import { formatPriceToLocale } from "utils/prices";

interface Props {
  caskType: CaskType;
  editCaskType: (caskType: CaskType) => void;
  removeCaskType: (caskType: CaskType) => Promise<void>;
}

const dialogStyle = {
  width: "400px",
};

const dialogButtonsStyle = {
  textAlign: "center",
  paddingLeft: "24px",
  paddingRight: "24px",
};

const ListItem = styled(Paper)`
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 80px;
  margin: 10px 0px;
`;

const CaskInfo = styled.div`
  height: 100%;
  padding-top: 15px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`;

const CaskTitle = styled.div`
  display: flex;
  align-items: center;
`;

const CaskName = styled.div`
  font-size: 16px;
`;

const CaskPrice = styled.div`
  font-size: 12px;
`;

const ActiveLabel = styled.div`
  margin-left: 10px;
  font-size: 14px;
  background-color: ${(props: { active: boolean }) =>
    props.active ? "#008000" : "#D50000"};
  border-radius: 2px;
  padding: 2px 5px;
  color: #fff;
`;

const CaskButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const CaskImage = styled.img`
  max-width: 120px;
  height: 100%;
  border-radius: 2px 0px 0px 2px;
`;

const DialogButton = styled(RaisedButton)`
  min-width: 100% !important;
  margin-top: 8px !important;
`;

const removeDialogButtonStyle = {
  backgroundColor: "red",
};

const StyledActionDelete = styled(ActionDelete)`
  top: 12px !important;
`;

const StyledActionEdit = styled(ActionEdit)`
  top: 12px !important;
`;

@observer
export default class CaskTypeListItem extends React.Component<Props, {}> {
  state = {
    open: false,
  };

  handleOpen = (caskType: CaskType) => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  removeCaskType = () => {
    this.setState({ open: false });
    this.props.removeCaskType(this.props.caskType);
  };

  render() {
    let { caskType, editCaskType } = this.props;
    const actions = [
      <DialogButton label="Avbryt" onClick={this.handleClose} />,
      <DialogButton
        buttonStyle={removeDialogButtonStyle}
        label="Ta bort"
        labelColor="#ffffff"
        onClick={this.removeCaskType}
      />,
    ];

    return (
      <div>
        <Dialog
          title="Ta bort fatet ur sortimentet?"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          contentStyle={dialogStyle}
          actionsContainerStyle={dialogButtonsStyle as CSSProperties}
        >
          Är du säker på att du vill ta bort fatet ur sortimentet? Fatet kommer
          inte länge att erbjudas på fatägarwebben.
        </Dialog>
        <ListItem key={caskType.id.toString()}>
          <CaskImage src={caskType.imageUrl} />
          <CaskInfo>
            <CaskTitle>
              <CaskName>{caskType.caskTypeInformation[0].name}</CaskName>
              {!caskType.outOfStock && (
                <ActiveLabel active={!caskType.outOfStock}>Erbjuds</ActiveLabel>
              )}
            </CaskTitle>
            <CaskPrice>{formatPriceToLocale(caskType.price)} SEK</CaskPrice>
          </CaskInfo>
          <CaskButtonContainer>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                editCaskType(caskType);
              }}
            >
              <StyledActionEdit />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.handleOpen(caskType);
              }}
            >
              <StyledActionDelete />
            </IconButton>
          </CaskButtonContainer>
        </ListItem>
      </div>
    );
  }
}
