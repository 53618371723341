import { gql, useLazyQuery } from "@apollo/client";
import { RepeatIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/DataTable";
import { useEffect, useState } from "react";
import { generatePath, NavLink } from "react-router-dom";
import { useDebounce } from "react-use";
import { useAsyncEffect } from "rooks";
import { formatDate } from "utils/dates";
import {
  GetCasks,
  GetCasksVariables,
  GetCasks_adminScope_casks_nodes,
  ListCask,
} from "__generated__/graphql";
import SyncCaskButton from "./SyncCaskButton";

const GET_CASKS = gql`
  query GetCasks(
    $search: String
    $searchNum: Int
    $after: String
    $caskImageFilter: CaskFilterInput!
  ) {
    adminScope {
      casks(
        first: 50
        after: $after
        where: {
          and: [{ vismaId: { neq: 0 } }, $caskImageFilter]
          or: [
            { vismaCaskNumber: { startsWith: $search } }
            { vismaId: { eq: $searchNum } }
            { stockNumber: { eq: $search } }
          ]
        }
      ) {
        nodes {
          ...ListCask
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }

  fragment ListCask on Cask {
    id
    vismaId
    vismaCaskNumber
    caskSign
    status
    stockNumber
    lastSyncedWithVisma
  }
`;

const columnHelper = createColumnHelper<ListCask>();

const columns = [
  columnHelper.accessor("vismaId", {
    cell: (info) => (
      <NavLink
        to={generatePath("/casks/:vismaId", { vismaId: info.getValue() })}
      >
        {info.getValue()}
      </NavLink>
    ),
    header: "Visma-id",
  }),
  columnHelper.accessor("vismaCaskNumber", {
    cell: (info) => info.getValue(),
    header: "Fatnummer",
  }),
  columnHelper.accessor("caskSign", {
    cell: (info) => info.getValue(),
    header: "Fatskylt",
  }),
  columnHelper.accessor("stockNumber", {
    cell: (info) => info.getValue(),
    header: "Lagerplats",
  }),
  columnHelper.accessor("lastSyncedWithVisma", {
    cell: (info) => formatDate(info.getValue() || ""),
    header: "Synkades",
  }),
  columnHelper.display({
    id: "actions",
    cell: (props) => (
      <SyncCaskButton vismaCaskId={props.row.original.vismaId} />
    ),
  }),
];

const CasksListPage = () => {
  const [after, setAfter] = useState<string>();
  const [search, setSearch] = useState<string>("");
  const [onlyWoImage, setOnlyWoImage] = useState<boolean>(false);
  const [rows, setRows] = useState<GetCasks_adminScope_casks_nodes[]>([]);
  const [input, setInput] = useState<string>("");
  const [getCasks, { loading, data }] = useLazyQuery<
    GetCasks,
    GetCasksVariables
  >(GET_CASKS);

  useDebounce(() => setSearch(input), 500, [input]);

  useAsyncEffect(async () => {
    const res = await getCasks({
      variables: {
        search,
        after,
        searchNum: isNaN(+search) ? 0 : Number(search),
        caskImageFilter: onlyWoImage ? { imageFull: { eq: null } } : {},
      },
    });

    const newRows =
      (res.data?.adminScope.casks
        ?.nodes as GetCasks_adminScope_casks_nodes[]) || [];
    setRows((r) => [...r, ...newRows]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, onlyWoImage, after]);

  useEffect(() => {
    setRows([]);
    setAfter(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyWoImage, search]);

  return (
    <>
      <InputGroup mb={2}>
        <InputLeftElement
          pointerEvents="none"
          children={loading ? <Spinner /> : <SearchIcon color="gray.300" />}
        />
        <Input
          onChange={(e) => setInput(e.target.value)}
          type="text"
          placeholder="Sök på fatnummer eller id i visma"
        />
      </InputGroup>
      <Checkbox
        mb={8}
        id="only-wo-image"
        checked={onlyWoImage}
        onChange={(e) => setOnlyWoImage(e.target.checked)}
      >
        Visa bara fat utan bild
      </Checkbox>
      <DataTable data={rows as ListCask[]} columns={columns} />
      <Flex mt={8}>
        <Button
          disabled={loading}
          leftIcon={<RepeatIcon />}
          variant="outline"
          onClick={async () =>
            setAfter(data?.adminScope.casks?.pageInfo.endCursor || "")
          }
        >
          Ladda fler
        </Button>
      </Flex>
    </>
  );
};

export default CasksListPage;
