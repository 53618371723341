import { SpinnerIcon } from "@chakra-ui/icons";
import Link from "components/UndecoratedLink";
import ListItem from "material-ui/List/ListItem";
import { Component } from "react";
import styled from "styled-components";
const caskSvg = require("assets/images/barrel-icon-horizontal.svg");

const UserSection = styled.div`
  margin-top: 25px;
`;

interface Props {
  logOut: () => void;
}

export default class DrawerMenuItemsComponent extends Component<Props, {}> {
  render() {
    return (
      <div>
        <Link to="/users">
          <ListItem
            key="users"
            primaryText="Användare"
            leftIcon={<i className="material-icons">person</i>}
          />
        </Link>
        <Link to="/casks">
          <ListItem
            key="casks"
            primaryText="Kundfat"
            leftIcon={
              <div style={{ transform: "rotate(-90deg)" }}>
                <img src={caskSvg.default} />
              </div>
            }
          />
        </Link>
        <Link to="/cask-images">
          <ListItem
            key="casks"
            primaryText="Fatbilder"
            leftIcon={<i className="material-icons">filter</i>}
          />
        </Link>
        <Link to="/cask-supply">
          <ListItem
            key="cask-supply"
            primaryText="Fatutbud"
            leftIcon={<img src={caskSvg.default} />}
          />
        </Link>

        <Link to="/recipe-supply">
          <ListItem
            key="recipe-supply"
            primaryText="Receptutbud"
            leftIcon={<i className="material-icons">local_drink</i>}
          />
        </Link>

        <Link to="/bottling-prices">
          <ListItem
            key="bottling-prices"
            primaryText="Prisvariabler"
            leftIcon={<i className="material-icons">attach_money</i>}
          />
        </Link>

        <Link to="/news">
          <ListItem
            key="news"
            primaryText="Nyheter"
            leftIcon={<i className="material-icons">subject</i>}
          />
        </Link>

        <Link to="/information">
          <ListItem
            key="information"
            primaryText="information"
            leftIcon={<i className="material-icons">info</i>}
          />
        </Link>

        <Link to="/sync-queue">
          <ListItem
            key="sync-queue"
            primaryText="Synk-kö"
            leftIcon={<SpinnerIcon />}
          />
        </Link>

        <UserSection>
          <ListItem
            key="cask-supply"
            primaryText="Logga ut"
            onClick={this.props.logOut}
            leftIcon={<i className="material-icons">power_settings_new</i>}
          />
        </UserSection>
      </div>
    );
  }
}
