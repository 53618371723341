import { gql, useMutation } from "@apollo/client";
import { CheckIcon, RepeatIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import React from "react";
import {
  RequeueSyncItem,
  RequeueSyncItemVariables,
} from "__generated__/graphql";

interface RequeueButtonProps {
  id: number;
}

const REQUEUE = gql`
  mutation RequeueSyncItem($id: Int!) {
    requeueSyncItem(id: $id) {
      id
      created
      ended
      runAfter
      function
      arguments
      status
    }
  }
`;

const RequeueButton: React.FC<RequeueButtonProps> = ({ id }) => {
  const [requeue, { loading, called }] = useMutation<
    RequeueSyncItem,
    RequeueSyncItemVariables
  >(REQUEUE, { variables: { id } });

  return (
    <>
      <Button
        bg="white"
        disabled={loading || called}
        leftIcon={called && !loading ? <CheckIcon /> : <RepeatIcon />}
        variant="outline"
        onClick={async () => await requeue()}
      >
        Köa om
      </Button>
    </>
  );
};

export default RequeueButton;
