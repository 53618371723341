import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import NewsStore from 'stores/domain/news';
import NewsFormUiStore from 'stores/ui/news';
import { Language } from 'models/translatable';
import NewsItem from 'models/news-item';
import NewsService, { NewsViewModel, 
    NewsContentViewModel } from 'services/news';
import UploadFileService from 'services/upload-file-service';
import NewsComponent from './NewsComponent';
import { UploadFile } from 'components/FileUploader/FileUploader';

interface Props extends RouteComponentProps<{}> {
  id: number;
  newsStore?: NewsStore;  
  newsFormUiStore?: NewsFormUiStore;
}

@inject('newsStore', 'newsFormUiStore')  
@observer
class NewsContainer extends React.Component<Props, {}> {
  
  componentWillMount() {
    this.newsFormUiStore.resetForm();
    if (this.props.id) {
        this.newsFormUiStore.setForm(this.findNewsItem(this.props.id));
    }
  }

  findNewsItem(id: number): NewsItem {
    return this.newsStore.find(id);
  }

  get newsStore() {
    return this.props.newsStore!;
  }

  get newsFormUiStore() {
      return this.props.newsFormUiStore!;
  }
  
    get setNewsViewModel(): NewsViewModel {
    
        let engNewsContent: NewsContentViewModel = {
            language: Language.English,
            title: this.newsFormUiStore.engTitle.value,
            body: this.newsFormUiStore.engBody.value
        };
        
        let sweNewsContent: NewsContentViewModel = {
            language: Language.Swedish,
            title: this.newsFormUiStore.sweTitle.value,
            body: this.newsFormUiStore.sweBody.value
        };

        let newsContent = Array<NewsContentViewModel>();
        newsContent.push(engNewsContent);
        newsContent.push(sweNewsContent);

        return {
            id: this.props.id,
            imageUrl: this.newsFormUiStore.imageUrl.value,
            newsContent: newsContent
        };
    }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.newsFormUiStore.labelFile.$) {
      let response = await UploadFileService.uploadNewsItemFiles([this.newsFormUiStore.labelFile.$]);
      
      if (!response.succeeded) {
        if (response.statusCode === 403) {
          throw new Error('Could\'t upload image: Access denied.');
        } else {
          throw new Error('Could\'t upload image.');
        }
      }
      this.newsFormUiStore.imageUrl.onChange(response.data.uploadedUrl);
    }

    let validation = await this.newsFormUiStore.form.validate();
    
    if (validation.hasError) {
        return;
    }

    let newsViewModel = this.setNewsViewModel;

    try {
      let response;
      if (this.props.id) {
        response = await NewsService.editNews(newsViewModel);
      } else {
        response = await NewsService.createNews(newsViewModel);        
      }
      
      if (!response.succeeded) {
        if (response.statusCode === 403) {
          throw new Error('Could\'t change news item: Access denied.');          
        } else {
          throw new Error('Could\'t change news item.');
        }
      }
    } catch (Error) {
      throw Error;
    }

    this.props.history.push(`/news`);    
  }

  onAddFiles= (files: UploadFile[]) => {
    let file = files[0];
    this.newsFormUiStore.addLabelFile(file);
  }

  render() {
    let hasFieldError = this.newsFormUiStore.form.hasFieldError;
    
    return (
      <div>
        <NewsComponent
          formStore={this.newsFormUiStore}
          hasFieldError={hasFieldError}
          onSubmit={this.onSubmit}
          onAddFiles={this.onAddFiles}
        />
      </div>
    );
  }
}

export default NewsContainer;