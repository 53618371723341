import Link from "components/UndecoratedLink";
import Drawer from "material-ui/Drawer";
import { PureComponent, ReactNode } from "react";
import styled from "styled-components";

const logoImage = require("assets/images/logo-dark.svg");

const Logo = styled.img`
  width: 80%;
`;

const TopContent = styled.div`
  padding: 15px;
  text-align: center;
`;

const LowerContent = styled.div`
  margin-top: 25px;
  padding: 15px;
`;

const LoggedInAsMessage = styled.div`
  font-weight: normal;
  font-size: 18px;
`;

const UsernameMessage = styled.div`
  font-size: 16px;
`;

interface Props {
  isAuthenticated: boolean;
  userEmail: string | undefined;
  children: ReactNode;
}

export default class DrawerComponent extends PureComponent<Props, {}> {
  render() {
    return (
      <Drawer open={true}>
        <TopContent>
          <Link to="/home">
            <Logo src={logoImage.default} />
          </Link>
        </TopContent>
        {this.props.isAuthenticated && this.props.children}
        {this.props.userEmail && (
          <LowerContent>
            <LoggedInAsMessage>Inloggad som:</LoggedInAsMessage>
            <UsernameMessage>{this.props.userEmail}</UsernameMessage>
          </LowerContent>
        )}
      </Drawer>
    );
  }
}
