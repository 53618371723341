import Api from './api';
import { Language } from 'models/translatable';
import CaskType from 'models/cask-type';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';

export interface CreateCaskTypeViewModel {
    id: number;
    caskTypeInformation: Array<CaskTypeInformationViewModel>;
    price: number;
    imageUrl: string;
    outOfStock: boolean;
    productIdSmoky: string;
    productIdNonSmoky: string;
    vismaId: number;
    size: number;
}

export interface CaskTypeInformationViewModel {
    language: Language;
    name: string;
    description: string;
}

interface DeleteResponse {
    data: {
        success: boolean;
    };
}

interface DeleteResponseData {
    success: boolean;
}

export default class CaskTypesService {
    static async fetchCaskTypes(): Promise<JsonApiResponse<CaskType[]>> {
        let response = await Api.get('caskTypes', false);
        return await toJsonApiResponse<CaskType[]>(response);
    }

    static async createCaskType(vm: CreateCaskTypeViewModel): Promise<JsonApiResponse<CaskType[]>> {
        let response = await Api.post('caskTypes', true, undefined, vm);
        return await toJsonApiResponse<CaskType[]>(response);
    }

    static async editCaskType(vm: CreateCaskTypeViewModel): Promise<JsonApiResponse<CaskType[]>> {
        let response = await Api.put('caskTypes', true, undefined, vm);
        return await toJsonApiResponse<CaskType[]>(response);
    }
    
    static async deleteCaskType(vm: CreateCaskTypeViewModel): Promise<JsonApiResponse<DeleteResponseData>> {
        let response = await Api.delete('caskTypes', true, undefined, vm);

        let jsonApiResponse = new JsonApiResponse<DeleteResponseData>();
        
        if (response.status !== 200) {
            jsonApiResponse.statusCode = response.status;
            jsonApiResponse.succeeded = false;
            jsonApiResponse.data = {
                success: false
            };
            return jsonApiResponse;
        }

        let deleteResponse = <DeleteResponse> await response.json();
        jsonApiResponse.statusCode = response.status;
        jsonApiResponse.succeeded = true;
                
        jsonApiResponse.data = { 
            success: deleteResponse.data.success
        };

        return jsonApiResponse;
    }
}