import * as React from 'react';
import { RouteComponentProps } from 'react-router';
// import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import NewsStore from 'stores/domain/news';
import { Language } from 'models/translatable';
import NewsItem from 'models/news-item';
import NewsService, { NewsViewModel, 
    NewsContentViewModel } from 'services/news';
import { List } from 'material-ui/List';
import Divider from 'material-ui/Divider';
// import RaisedButton from 'material-ui/RaisedButton';
import NewsListItem from './NewsListItem';

interface Props extends RouteComponentProps<{}> {
    newsStore?: NewsStore;
}

@inject('newsStore')  
@observer
export default class NewsItems extends React.Component<Props, {}> {

    setNewsViewModel(newsItem: NewsItem): NewsViewModel {
        
        let engNewsContent: NewsContentViewModel = {
            language: Language.English,
            title: newsItem.newsContent[0].title,
            body: newsItem.newsContent[0].body
        };
        
        let sweNewsContent: NewsContentViewModel = {
            language: Language.Swedish,
            title: newsItem.newsContent[1].title,
            body: newsItem.newsContent[1].body
        };

        let newsContent = Array<NewsContentViewModel>();
        newsContent.push(engNewsContent);
        newsContent.push(sweNewsContent);
    
        return {
            id: newsItem.id,
            imageUrl: newsItem.imageUrl,
            newsContent: newsContent
        };
    }

    removeNewsItem = async (newsItem: NewsItem) => {
    
        let newsViewModel = this.setNewsViewModel(newsItem);

        try {
            let response = await NewsService.deleteNews(newsViewModel);
        
            if (!response.succeeded || !response.data.success) {
                if (response.statusCode === 403) {
                    throw new Error('Could\'t remove news item: Access denied.');
                } else {
                    throw new Error('Could\'t remove news item.');
                }
            }
        } catch (Error) {
            throw Error;
        }

        this.newsStore.fetchNews();
    }
      
    componentWillMount() {
        
      this.newsStore.fetchNews();
    }

    get newsStore() {
        return this.props.newsStore!;
    }

    editNewsItem = (newsItem: NewsItem) => {
        this.props.history.push(`/news/edit/${newsItem.id}`);
    }

    render() {
        return (
            <div>
                <h1>Nyheter</h1>
                <List>
                    <Divider/>
                    {this.newsStore.news.map(newsItem => (
                        <NewsListItem
                            key={newsItem.id.toString()}
                            newsItem={newsItem}
                            editNewsItem={this.editNewsItem}
                            removeNewsItem={this.removeNewsItem}
                        />
                    ))}
                </List>
                {/* <Link to="/news/create">
                    <RaisedButton
                        label="+ Lägg till nyhet"
                        style={{ width: '200px' }}
                        backgroundColor="green"
                        labelColor="#ffffff"
                    />
                </Link> */}
            </div>
        );
    }
}