import { Box, FormLabel } from "@chakra-ui/react";
import { FlatButton, FontIcon, TextField } from "material-ui";
import * as React from "react";
import { FC } from "react";

export interface Props {
  isSearching: boolean;
  searchTerm: string;
  onSearch: () => void;
  onChangeSearchTerm: (newValue: string) => void;
}

const UsersSearchForm: FC<Props> = ({
  isSearching,
  onSearch,
  onChangeSearchTerm,
  searchTerm,
}) => (
  <form
    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSearch();
    }}
  >
    <Box>
      <FormLabel htmlFor="search">Sök användare i Fatägarwebben</FormLabel>
      <TextField
        value={searchTerm}
        hintText="Namn, email, id eller kundnummer"
        floatingLabelText="Sök användare"
        onChange={(evt: object, newValue: string) =>
          onChangeSearchTerm(newValue)
        }
      />

      <FlatButton
        icon={<FontIcon className="material-icons">search</FontIcon>}
        disabled={isSearching}
        onClick={onSearch}
      />
    </Box>
  </form>
);

export default UsersSearchForm;
