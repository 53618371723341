import * as React from 'react';
import { Component } from 'react';
import AppBar from './AppBar';

class AppBarContainer extends Component<{}, {}> {
  render() {
    return (
      <AppBar />
    );
  }
}

export default AppBarContainer;
