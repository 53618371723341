import { DownloadIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import React from "react";
import Api from "../../services/api";

interface DownloadSettlementButtonProps {
  vismaOrderHistoryId: number;
  language: "sv" | "en";
}
const getSettlementFile = async (
  vismaOrderHistoryId: number,
  language: "sv" | "en"
) => {
  let response = await Api.get(
    `admin/settlement/${vismaOrderHistoryId}`,
    true,
    {},
    {
      language,
    }
  );

  let buffer = await response.arrayBuffer();
  var file = new Blob([buffer], { type: "application/pdf" });

  saveAs(file, language === "sv" ? "avräkningsnota.pdf" : "settlement.pdf");
};

const DownloadSettlementButton: React.FC<DownloadSettlementButtonProps> = ({
  vismaOrderHistoryId,
  language,
}) => {
  return (
    <>
      <Button
        bg="white"
        leftIcon={<DownloadIcon />}
        variant="outline"
        onClick={async () =>
          await getSettlementFile(vismaOrderHistoryId, language)
        }
      >
        {language.toLocaleUpperCase()}
      </Button>
    </>
  );
};

export default DownloadSettlementButton;
