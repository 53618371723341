import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import RecipesStore from 'stores/domain/recipes';
import RecipesFormUiStore from 'stores/ui/recipes';
import { Language } from 'models/translatable';
import Recipe from 'models/recipe';
import RecipesService, {
  EditRecipeViewModel,
  RecipeInformationViewModel
} from 'services/recipes';
import LoadingSplash from 'components/LoadingSpinners/LoadingSplash';
import RecipeComponent from './RecipeComponent';

interface Props extends RouteComponentProps<{}> {
  recipesStore?: RecipesStore;
  recipesFormUiStore?: RecipesFormUiStore;
}

@inject('recipesStore', 'recipesFormUiStore')
@observer
export default class RecipeSupply extends React.Component<Props, {}> {
  componentWillMount() {
    this.recipesStore.fetchRecipes();
  }

  get recipesStore() {
    return this.props.recipesStore!;
  }

  get recipesFormStore() {
    return this.props.recipesFormUiStore!;
  }

  setEditRecipeViewModel(recipe: Recipe): EditRecipeViewModel {
    let formStore = this.recipesFormStore.findFormStore(recipe);

    let engRecipeInformation: RecipeInformationViewModel = {
      language: Language.English,
      name: recipe.recipeInformation[0].name,
      description: formStore.engDescription.value
    };

    let sweRecipeInformation: RecipeInformationViewModel = {
      language: Language.Swedish,
      name: recipe.recipeInformation[1].name,
      description: formStore.sweDescription.value
    };

    let recipeInformations = Array<RecipeInformationViewModel>();
    recipeInformations.push(engRecipeInformation);
    recipeInformations.push(sweRecipeInformation);

    return {
      id: recipe.id,
      recipeInformation: recipeInformations,
      price: formStore.price.value
    };
  }

  onSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    recipe: Recipe
  ) => {
    event.preventDefault();

    let validation = await this.recipesFormStore
      .findFormState(recipe)
      .validate();

    if (validation.hasError) {
      return;
    }

    let editRecipeViewModel = this.setEditRecipeViewModel(recipe);

    try {
      let response = await RecipesService.editRecipe(editRecipeViewModel);

      if (!response.succeeded) {
        if (response.statusCode === 403) {
          throw new Error('Could\'t change recipe: Access denied.');
        } else {
          throw new Error('Could\'t change recipe.');
        }
      }
    } catch (Error) {
      throw Error;
    }

    this.recipesStore.fetchRecipes();
  }

  render() {
    if (this.recipesStore.isLoading) {
      return (
        <div>
          <LoadingSplash />
        </div>
      );
    }

    return (
      <div>
        {this.recipesStore.recipes.map(recipe => (
          <RecipeComponent
            key={recipe.id.toString()}
            recipe={recipe}
            formState={this.recipesFormStore.findFormState(recipe)}
            formStore={this.recipesFormStore.findFormStore(recipe)}
            onSubmit={this.onSubmit}
            isPristine={this.recipesFormStore.isPristine}
          />
        ))}
      </div>
    );
  }
}
