import { action, makeObservable, observable } from "mobx";
import User from "models/user";

class UsersStore {
  @observable users: User[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async replaceUsers(newUsers: User[]) {
    this.users = newUsers;
  }

  @action
  async replaceUser(user: User) {
    let newUsersList = this.users.map((u) => {
      if (u.id === user.id) {
        return user;
      }

      return u;
    });

    this.users = newUsersList;
  }
}

export default UsersStore;
export const usersStore = new UsersStore();
