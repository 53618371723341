import User from "models/user";
import { JsonApiResponse, toJsonApiResponse } from "utils/json-api";
import Api from "./api";

export class SaveUserViewModel {
  id!: string;
  email!: string;
  emailConfirmed!: boolean;
  vismaUsers!: {
    vismaUserId: number;
    vismaCustomerNumber: number;
  }[];
}

export default class UsersService {
  static async searchUsers(
    searchTerm: string
  ): Promise<JsonApiResponse<User[]>> {
    let response = await Api.get(`users?searchTerm=${searchTerm}`, true);
    return await toJsonApiResponse<User[]>(response);
  }

  static async saveUser(
    userVm: SaveUserViewModel
  ): Promise<JsonApiResponse<User>> {
    let response = await Api.post(
      "users/saveUserDetails",
      true,
      undefined,
      userVm
    );
    return await toJsonApiResponse<User>(response);
  }

  static async syncUser(userId: string): Promise<JsonApiResponse<User>> {
    let response = await Api.get(`users/sync/${userId}`, true, undefined);
    return await response.json();
  }
}
