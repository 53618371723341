import * as React from "react";

const RecipeSupplyPage: React.FC<{ children?: React.ReactNode }> = (props) => (
  <div>
    <h1>Receptutbud</h1>
    {props.children}
  </div>
);

export default RecipeSupplyPage;
