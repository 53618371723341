import { gql, useLazyQuery } from "@apollo/client";
import { Box, Progress, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAsyncEffect } from "rooks";
import UploadFileService from "services/upload-file-service";
import styled from "styled-components";
import {
  GetCaskByNumber,
  GetCaskByNumberVariables,
} from "__generated__/graphql";
import ManualCaskImagesQueue from "./CaskImageQueue";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  font-size: 2rem;
`;

export const GET_CASK_BY_NUMBER = gql`
  query GetCaskByNumber($caskNumber: String!) {
    adminScope {
      casks(
        first: 1
        where: { and: { vismaCaskNumber: { eq: $caskNumber } } }
      ) {
        nodes {
          id
          vismaId
          vismaCaskNumber
        }
      }
    }
  }
`;

const CaskImagesPage = () => {
  const toast = useToast();
  const [getCask] = useLazyQuery<GetCaskByNumber, GetCaskByNumberVariables>(
    GET_CASK_BY_NUMBER
  );
  const [initialImages, setInitialImages] = useState<File[]>([]);
  const [manualImages, setManualImages] = useState<File[]>([]);
  const [remainingAutoIterations, setRemainingAutoIterations] =
    useState<number>(0);
  const [initialAutoLength, setInitialAutoLength] = useState<number>(0);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: { "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setInitialAutoLength(acceptedFiles.length);
      setRemainingAutoIterations(acceptedFiles.length);
      setInitialImages(acceptedFiles);
      setManualImages([]);
    }
  }, [acceptedFiles]);

  useAsyncEffect(async () => {
    if (remainingAutoIterations === 0) return;

    const file = initialImages.pop();
    if (!file) return setRemainingAutoIterations((i) => i - 1);

    const caskNumber = file?.name.replace(/\.[^/.]+$/, "");

    if (!caskNumber) {
      setManualImages((m) => [...m, file]);
      return setRemainingAutoIterations((i) => i - 1);
    }

    const caskQ = await getCask({ variables: { caskNumber } });
    const cask = caskQ.data?.adminScope.casks?.nodes?.at(0);

    if (!cask) {
      setManualImages((m) => [...m, file]);
      return setRemainingAutoIterations((i) => i - 1);
    }

    toast({
      id: caskNumber,
      title: "Fatbild laddas upp.",
      description: `Fatbilden för fat ${caskNumber} laddas upp.`,
      status: "loading",
      duration: 20000,
    });

    await UploadFileService.uploadCaskImage(file, cask!.id);

    toast.update(caskNumber, {
      title: "Fatbild laddades upp.",
      description: `Fatbilden för fat ${caskNumber} laddades upp.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    return setRemainingAutoIterations((i) => i - 1);
  }, [remainingAutoIterations]);

  return (
    <>
      <DropZone {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Släpp filer här eller klicka för att välja.</p>
      </DropZone>
      <Box mt={8}>
        {remainingAutoIterations > 0 && (
          <Box>
            <Text fontSize="md" mb={4}>{`Behandlar bild ${
              initialAutoLength - remainingAutoIterations + 1
            } av ${initialAutoLength}`}</Text>
            <Progress
              hasStripe
              isAnimated
              value={
                ((initialAutoLength - remainingAutoIterations + 1) /
                  initialAutoLength) *
                100
              }
            />
          </Box>
        )}
        {remainingAutoIterations === 0 && manualImages && (
          <ManualCaskImagesQueue files={manualImages} />
        )}
      </Box>
    </>
  );
};

export default CaskImagesPage;
