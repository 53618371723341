import { gql, useLazyQuery } from "@apollo/client";
import { RepeatIcon } from "@chakra-ui/icons";
import { Button, Checkbox } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/DataTable";
import { useEffect, useState } from "react";
import RequeueButton from "scenes/Casks/RequeueButton";
import { formatDate } from "utils/dates";
import {
  GetSyncQueue,
  GetSyncQueueVariables,
  GetSyncQueue_adminScope_syncQueue_nodes,
  SyncQueueItem,
  SyncRunStatus,
} from "__generated__/graphql";

const GET_SYNC_QUEUE = gql`
  query GetSyncQueue($after: String, $where: SyncQueueItemFilterInput) {
    adminScope {
      syncQueue(first: 50, after: $after, where: $where) {
        nodes {
          ...SyncQueueItem
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }

  fragment SyncQueueItem on SyncQueueItem {
    id
    created
    ended
    runAfter
    function
    arguments
    status
  }
`;

const columnHelper = createColumnHelper<SyncQueueItem>();

const columns = [
  columnHelper.accessor("function", {
    cell: (info) => info.getValue(),
    header: "Funktion",
  }),
  columnHelper.accessor("arguments", {
    cell: (info) => info.getValue(),
    header: "Argument",
  }),
  columnHelper.accessor("status", {
    cell: (info) => info.getValue(),
    header: "Status",
  }),
  columnHelper.accessor("created", {
    cell: (info) => formatDate(info.getValue() || ""),
    header: "Startade",
  }),
  columnHelper.accessor("ended", {
    cell: (info) => formatDate(info.getValue() || ""),
    header: "Slutade",
  }),
  columnHelper.accessor("id", {
    cell: (info) =>
      info.row.original.status === SyncRunStatus.ERROR && (
        <RequeueButton id={info.getValue()} />
      ),
    header: "",
  }),
  /* columnHelper.display({
    id: "actions",
    cell: (props) => (
      <SyncCaskButton vismaCaskId={props.row.original.vismaId} />
    ),
  }), */
];

const SyncQueueListPage = () => {
  const [after, setAfter] = useState<string>();
  const [rows, setRows] = useState<GetSyncQueue_adminScope_syncQueue_nodes[]>(
    []
  );
  const [showOnlyErrors, setShowOnlyErrors] = useState<boolean>(false);
  const [getSyncQueue, { loading, data }] = useLazyQuery<
    GetSyncQueue,
    GetSyncQueueVariables
  >(GET_SYNC_QUEUE);

  useEffect(() => {
    getSyncQueue({
      variables: {
        after,
        where: showOnlyErrors
          ? {
              or: [
                { status: { eq: SyncRunStatus.ERROR } },
                { status: { eq: SyncRunStatus.NOT_FOUND } },
              ],
            }
          : null,
      },
    }).then((v) => {
      const newRows =
        (v.data?.adminScope.syncQueue
          ?.nodes as GetSyncQueue_adminScope_syncQueue_nodes[]) || [];
      setRows((r) => [...r, ...newRows]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after, showOnlyErrors]);

  useEffect(() => {
    setRows([]);
    setAfter(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlyErrors]);

  return (
    <>
      <Checkbox
        checked={showOnlyErrors}
        onChange={(e) => setShowOnlyErrors(e.target.checked)}
      >
        Visa bara felande
      </Checkbox>
      <DataTable data={rows as SyncQueueItem[]} columns={columns} />
      <Button
        mt={8}
        disabled={loading}
        leftIcon={<RepeatIcon />}
        variant="outline"
        onClick={async () =>
          setAfter(data?.adminScope.syncQueue?.pageInfo.endCursor || "")
        }
      >
        Ladda fler
      </Button>
    </>
  );
};

export default SyncQueueListPage;
