import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TextField, RaisedButton, Paper } from 'material-ui';

interface Props {
  countryName: string;
  exchangeRate: number;
  vatRate: number;
  alcoholDutyRate: number;
  vendorFlatRate: number;
  vendorMarginRate: number;

  handleExchangeRateChange: (value: string) => void;
  handleVatRateChange: (value: string) => void;
  handleAlcoholDutyRateChange: (value: string) => void;
  handleVendorFlatRateChange: (value: string) => void;
  handleVendorMarginRateChange: (value: string) => void;
  handleSubmit: () => void;
}

const Wrapper = styled(Paper)`
  width: 600px;
  margin-top: 30px;
`;

const SubmitButton = styled(RaisedButton)`
  margin: 20px 20px 20px 0px;
`;

const Form = styled.form`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

@observer
class RecipeComponent extends React.Component<Props, {}> {
  render() {
    let {
      countryName,
      exchangeRate,
      vatRate,
      alcoholDutyRate,
      vendorFlatRate,
      vendorMarginRate,

      handleExchangeRateChange,
      handleVatRateChange,
      handleAlcoholDutyRateChange,
      handleVendorFlatRateChange,
      handleVendorMarginRateChange,
      handleSubmit
    } = this.props;

    return (
      <Wrapper>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <h2>{countryName}</h2>
          <TextField
            floatingLabelText={'Valutakurs'}
            type="number"
            value={isNaN(exchangeRate) ? '' : exchangeRate}
            onChange={(event, value) => handleExchangeRateChange(value)}
          />
          <TextField
            floatingLabelText={'Moms'}
            type="number"
            value={isNaN(vatRate) ? '' : vatRate}
            onChange={(event, value) => handleVatRateChange(value)}
          />
          <TextField
            floatingLabelText={'Skattesats-alkohol'}
            type="number"
            value={isNaN(alcoholDutyRate) ? '' : alcoholDutyRate}
            onChange={(event, value) => handleAlcoholDutyRateChange(value)}
          />
          <TextField
            floatingLabelText={'Butiksmarginal (platt avgift)'}
            type="number"
            value={isNaN(vendorFlatRate) ? '' : vendorFlatRate}
            onChange={(event, value) => handleVendorFlatRateChange(value)}
          />
          <TextField
            floatingLabelText={'Butiksmarginal (%)'}
            type="number"
            value={isNaN(vendorMarginRate) ? '' : vendorMarginRate}
            onChange={(event, value) => handleVendorMarginRateChange(value)}
          />
          <div>
            <SubmitButton
              primary={true}
              type="submit"
              label={'Spara'}
              style={{ width: '200px' }}
            />
          </div>
        </Form>
      </Wrapper>
    );
  }
}

export default RecipeComponent;
