import Api from './api';
import { Language } from 'models/translatable';
import NewsItem from 'models/news-item';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';

export interface NewsViewModel {
    id: number;
    newsContent: Array<NewsContentViewModel>;
    imageUrl: string;
}

export interface NewsContentViewModel {
    language: Language;
    title: string;
    body: string;
}

interface DeleteResponse {
    data: {
        success: boolean;
    };
}

interface DeleteResponseData {
    success: boolean;
}

export default class NewsService {
    static async fetchNews(): Promise<JsonApiResponse<NewsItem[]>> {
        let response = await Api.get('newsItems', true);
        return await toJsonApiResponse<NewsItem[]>(response);
    }

    static async createNews(vm: NewsViewModel): Promise<JsonApiResponse<NewsItem[]>> {
        let response = await Api.post('newsItems', true, undefined, vm);
        return await toJsonApiResponse<NewsItem[]>(response);
    }

    static async editNews(vm: NewsViewModel): Promise<JsonApiResponse<NewsItem[]>> {
        let response = await Api.put('newsItems', true, undefined, vm);
        return await toJsonApiResponse<NewsItem[]>(response);
    }
    
    static async deleteNews(vm: NewsViewModel): Promise<JsonApiResponse<DeleteResponseData>> {
        let response = await Api.delete('newsItems', true, undefined, vm);

        let jsonApiResponse = new JsonApiResponse<DeleteResponseData>();
        
        if (response.status !== 200) {
            jsonApiResponse.statusCode = response.status;
            jsonApiResponse.succeeded = false;
            jsonApiResponse.data = {
                success: false
            };
            return jsonApiResponse;
        }

        let deleteResponse = <DeleteResponse> await response.json();
        jsonApiResponse.statusCode = response.status;
        jsonApiResponse.succeeded = true;
                
        jsonApiResponse.data = { 
            success: deleteResponse.data.success
        };

        return jsonApiResponse;
    }
}