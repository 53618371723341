/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaskByNumber
// ====================================================

export interface GetCaskByNumber_adminScope_casks_nodes {
  __typename: "Cask";
  id: number;
  vismaId: number;
  vismaCaskNumber: string | null;
}

export interface GetCaskByNumber_adminScope_casks {
  __typename: "CasksConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetCaskByNumber_adminScope_casks_nodes | null)[] | null;
}

export interface GetCaskByNumber_adminScope {
  __typename: "AdminQueries";
  casks: GetCaskByNumber_adminScope_casks | null;
}

export interface GetCaskByNumber {
  adminScope: GetCaskByNumber_adminScope;
}

export interface GetCaskByNumberVariables {
  caskNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBottlingOrder
// ====================================================

export interface GetBottlingOrder_adminScope_bottlingOrders_nodes_bottleLabels {
  __typename: "BottleLabel";
  id: number;
  oldBOXLabel: boolean;
  customization: Customization;
  text: string | null;
  imageUrl: string | null;
}

export interface GetBottlingOrder_adminScope_bottlingOrders_nodes_deliveryOccasion {
  __typename: "BottlingDeliveryOccasion";
  id: number;
  name: string | null;
  deadline: GraphQL_DateTime;
  pickup: GraphQL_DateTime;
  internalActivityInformation: string | null;
  bottlingCountries: DeliveryOccasionLocation[] | null;
}

export interface GetBottlingOrder_adminScope_bottlingOrders_nodes {
  __typename: "BottlingOrder";
  id: number;
  bottlingAmount: BottlingOrderAmount;
  numberOfBottles: number | null;
  additionalLabelInfo: string | null;
  alcoholContent: GraphQL_Decimal | null;
  deliveryLocation: DeliveryOccasionLocation;
  afterBottlingAction: AfterBottlingAction;
  caskSignText: string | null;
  bankAccountType: BankAccountType;
  clearingNumber: string | null;
  bankAccountNumber: string | null;
  fullName: string | null;
  email: string | null;
  phoneNumber: string | null;
  comment: string | null;
  bottleLabels: (GetBottlingOrder_adminScope_bottlingOrders_nodes_bottleLabels | null)[] | null;
  deliveryOccasion: GetBottlingOrder_adminScope_bottlingOrders_nodes_deliveryOccasion | null;
}

export interface GetBottlingOrder_adminScope_bottlingOrders {
  __typename: "BottlingOrdersConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetBottlingOrder_adminScope_bottlingOrders_nodes | null)[] | null;
}

export interface GetBottlingOrder_adminScope {
  __typename: "AdminQueries";
  bottlingOrders: GetBottlingOrder_adminScope_bottlingOrders | null;
}

export interface GetBottlingOrder {
  adminScope: GetBottlingOrder_adminScope;
}

export interface GetBottlingOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaskOrder
// ====================================================

export interface GetCaskOrder_adminScope_orders_nodes_user {
  __typename: "User";
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetCaskOrder_adminScope_orders_nodes {
  __typename: "Order";
  id: number;
  vismaId: number;
  status: OrderStatus;
  created: GraphQL_DateTime;
  updated: GraphQL_DateTime;
  orderType: OrderType;
  totalPrice: GraphQL_Decimal;
  user: GetCaskOrder_adminScope_orders_nodes_user | null;
}

export interface GetCaskOrder_adminScope_orders {
  __typename: "OrdersConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetCaskOrder_adminScope_orders_nodes | null)[] | null;
}

export interface GetCaskOrder_adminScope {
  __typename: "AdminQueries";
  orders: GetCaskOrder_adminScope_orders | null;
}

export interface GetCaskOrder {
  adminScope: GetCaskOrder_adminScope;
}

export interface GetCaskOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaskOrders
// ====================================================

export interface GetCaskOrders_adminScope_orders_nodes_user {
  __typename: "User";
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetCaskOrders_adminScope_orders_nodes {
  __typename: "Order";
  id: number;
  vismaId: number;
  status: OrderStatus;
  created: GraphQL_DateTime;
  updated: GraphQL_DateTime;
  orderType: OrderType;
  totalPrice: GraphQL_Decimal;
  user: GetCaskOrders_adminScope_orders_nodes_user | null;
}

export interface GetCaskOrders_adminScope_orders {
  __typename: "OrdersConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetCaskOrders_adminScope_orders_nodes | null)[] | null;
}

export interface GetCaskOrders_adminScope {
  __typename: "AdminQueries";
  orders: GetCaskOrders_adminScope_orders | null;
}

export interface GetCaskOrders {
  adminScope: GetCaskOrders_adminScope;
}

export interface GetCaskOrdersVariables {
  caskId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCask
// ====================================================

export interface GetCask_adminScope_casks_nodes_recipe_recipeInformation {
  __typename: "RecipeInformation";
  language: Language;
  recipeId: number;
  name: string | null;
  description: string | null;
}

export interface GetCask_adminScope_casks_nodes_recipe {
  __typename: "Recipe";
  id: number;
  recipeInformation: (GetCask_adminScope_casks_nodes_recipe_recipeInformation | null)[] | null;
}

export interface GetCask_adminScope_casks_nodes_caskType_caskTypeInformation {
  __typename: "CaskTypeInformation";
  language: Language;
  name: string | null;
  description: string | null;
}

export interface GetCask_adminScope_casks_nodes_caskType {
  __typename: "CaskType";
  vismaId: number;
  caskTypeInformation: (GetCask_adminScope_casks_nodes_caskType_caskTypeInformation | null)[] | null;
}

export interface GetCask_adminScope_casks_nodes_caskUsers_vismaUser {
  __typename: "LocalVismaUser";
  vismaUserId: number;
  vismaCustomerNumber: number;
  phoneNumber: string | null;
  lastSyncedWithVisma: GraphQL_DateTime | null;
}

export interface GetCask_adminScope_casks_nodes_caskUsers_user {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface GetCask_adminScope_casks_nodes_caskUsers {
  __typename: "CaskUser";
  id: number;
  userType: UserType;
  vismaUser: GetCask_adminScope_casks_nodes_caskUsers_vismaUser | null;
  user: GetCask_adminScope_casks_nodes_caskUsers_user | null;
}

export interface GetCask_adminScope_casks_nodes {
  __typename: "Cask";
  id: number;
  vismaId: number;
  vismaCaskNumber: string | null;
  caskSign: string | null;
  status: CaskStatus;
  notRefillable: boolean;
  caskTypeText: string | null;
  recipeText: string | null;
  volumeFilled: GraphQL_Decimal | null;
  stockNumber: string | null;
  loanClaim: GraphQL_Decimal | null;
  alcoholPercentage: GraphQL_Decimal | null;
  filled: GraphQL_DateTime | null;
  liquorFilled: GraphQL_DateTime | null;
  percentSmoked: GraphQL_Decimal | null;
  warehouseArea: number | null;
  timesRefilled: number;
  currentVolume: GraphQL_Decimal | null;
  loadVolume: GraphQL_Decimal | null;
  lastSyncedWithVisma: GraphQL_DateTime | null;
  imageThumb: string | null;
  thumbImage: string | null;
  fullImage: string | null;
  recipe: GetCask_adminScope_casks_nodes_recipe | null;
  caskType: GetCask_adminScope_casks_nodes_caskType | null;
  caskUsers: (GetCask_adminScope_casks_nodes_caskUsers | null)[] | null;
}

export interface GetCask_adminScope_casks {
  __typename: "CasksConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetCask_adminScope_casks_nodes | null)[] | null;
}

export interface GetCask_adminScope {
  __typename: "AdminQueries";
  casks: GetCask_adminScope_casks | null;
}

export interface GetCask {
  adminScope: GetCask_adminScope;
}

export interface GetCaskVariables {
  vismaId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCasks
// ====================================================

export interface GetCasks_adminScope_casks_nodes {
  __typename: "Cask";
  id: number;
  vismaId: number;
  vismaCaskNumber: string | null;
  caskSign: string | null;
  status: CaskStatus;
  stockNumber: string | null;
  lastSyncedWithVisma: GraphQL_DateTime | null;
}

export interface GetCasks_adminScope_casks_pageInfo {
  __typename: "PageInfo";
  /**
   * Indicates whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
  /**
   * Indicates whether more edges exist prior the set defined by the clients arguments.
   */
  hasPreviousPage: boolean;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetCasks_adminScope_casks {
  __typename: "CasksConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetCasks_adminScope_casks_nodes | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetCasks_adminScope_casks_pageInfo;
}

export interface GetCasks_adminScope {
  __typename: "AdminQueries";
  casks: GetCasks_adminScope_casks | null;
}

export interface GetCasks {
  adminScope: GetCasks_adminScope;
}

export interface GetCasksVariables {
  search?: string | null;
  searchNum?: number | null;
  after?: string | null;
  caskImageFilter: CaskFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaskVismaOrder
// ====================================================

export interface GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_localVismaUser {
  __typename: "LocalVismaUser";
  id: number;
  vismaUserId: number;
  vismaCustomerNumber: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  country: string | null;
}

export interface GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_orderRows {
  __typename: "VismaCaskOrderHistoryOrderRow";
  finishDate: string | null;
  description: string | null;
  price: GraphQL_Decimal;
  amount: GraphQL_Decimal;
  productNumber: string | null;
  quantity: number;
  currencyName: string | null;
  alcoholTax: GraphQL_Decimal;
  storeMarkup: GraphQL_Decimal;
  settlementPriceInCurrency: GraphQL_Decimal;
  vat: GraphQL_Decimal;
  id: number;
}

export interface GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes {
  __typename: "VismaCaskOrderHistory";
  id: number;
  customerNumber: number;
  type: VismaCaskOrderHistoryType;
  vismaOrderId: number;
  activityName: string | null;
  actionName: string | null;
  bottlingAlcohol: GraphQL_Decimal | null;
  bottlingRoundName: string | null;
  bottlingVolumeName: string | null;
  dilution: string | null;
  status: string | null;
  preferedDeliveryTime: GraphQL_DateTime | null;
  alcohol: GraphQL_Decimal;
  comment: string | null;
  settlementCurrencyRate: string | null;
  isSettlement: boolean;
  settlementCurrencyName: string | null;
  localVismaUser: GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_localVismaUser | null;
  orderRows: (GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_orderRows | null)[] | null;
}

export interface GetCaskVismaOrder_adminScope_vismaCaskOrderHistory {
  __typename: "VismaCaskOrderHistoryConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes | null)[] | null;
}

export interface GetCaskVismaOrder_adminScope {
  __typename: "AdminQueries";
  vismaCaskOrderHistory: GetCaskVismaOrder_adminScope_vismaCaskOrderHistory | null;
}

export interface GetCaskVismaOrder {
  adminScope: GetCaskVismaOrder_adminScope;
}

export interface GetCaskVismaOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFlavourNote
// ====================================================

export interface GetFlavourNote_adminScope_flavourNoteOrders_nodes {
  __typename: "FlavourNoteOrder";
  id: number;
  obtainedLanguage: FlavourNoteLanguage;
}

export interface GetFlavourNote_adminScope_flavourNoteOrders {
  __typename: "FlavourNoteOrdersConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetFlavourNote_adminScope_flavourNoteOrders_nodes | null)[] | null;
}

export interface GetFlavourNote_adminScope {
  __typename: "AdminQueries";
  flavourNoteOrders: GetFlavourNote_adminScope_flavourNoteOrders | null;
}

export interface GetFlavourNote {
  adminScope: GetFlavourNote_adminScope;
}

export interface GetFlavourNoteVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequeueSyncItem
// ====================================================

export interface RequeueSyncItem_requeueSyncItem {
  __typename: "SyncQueueItem";
  id: number;
  created: GraphQL_DateTime;
  ended: GraphQL_DateTime | null;
  runAfter: GraphQL_DateTime | null;
  function: SyncFunction;
  arguments: (string | null)[] | null;
  status: SyncRunStatus;
}

export interface RequeueSyncItem {
  requeueSyncItem: RequeueSyncItem_requeueSyncItem;
}

export interface RequeueSyncItemVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSamplingOrder
// ====================================================

export interface GetSamplingOrder_adminScope_samplingOrders_nodes_event {
  __typename: "Event";
  id: number;
  vismaId: number;
  name: string | null;
  dateTime: GraphQL_DateTime;
  country: Country;
}

export interface GetSamplingOrder_adminScope_samplingOrders_nodes {
  __typename: "SamplingOrder";
  id: number;
  type: SamplingOrderType;
  distilleryDateTime: GraphQL_DateTime | null;
  systembolagetRequestNumber: string | null;
  event: GetSamplingOrder_adminScope_samplingOrders_nodes_event | null;
}

export interface GetSamplingOrder_adminScope_samplingOrders {
  __typename: "SamplingOrdersConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetSamplingOrder_adminScope_samplingOrders_nodes | null)[] | null;
}

export interface GetSamplingOrder_adminScope {
  __typename: "AdminQueries";
  samplingOrders: GetSamplingOrder_adminScope_samplingOrders | null;
}

export interface GetSamplingOrder {
  adminScope: GetSamplingOrder_adminScope;
}

export interface GetSamplingOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCaskToSyncQueue
// ====================================================

export interface AddCaskToSyncQueue {
  addCaskToSyncQueue: number;
}

export interface AddCaskToSyncQueueVariables {
  vismaCaskId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVismaOrderHistory
// ====================================================

export interface GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes_localVismaUser {
  __typename: "LocalVismaUser";
  id: number;
  vismaUserId: number;
  vismaCustomerNumber: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes_orderRows {
  __typename: "VismaCaskOrderHistoryOrderRow";
  description: string | null;
  id: number;
  finishDate: string | null;
}

export interface GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes {
  __typename: "VismaCaskOrderHistory";
  id: number;
  customerNumber: number;
  type: VismaCaskOrderHistoryType;
  vismaOrderId: number;
  activityName: string | null;
  actionName: string | null;
  bottlingAlcohol: GraphQL_Decimal | null;
  bottlingRoundName: string | null;
  bottlingVolumeName: string | null;
  dilution: string | null;
  status: string | null;
  preferedDeliveryTime: GraphQL_DateTime | null;
  alcohol: GraphQL_Decimal;
  comment: string | null;
  isSettlement: boolean;
  localVismaUser: GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes_localVismaUser | null;
  orderRows: (GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes_orderRows | null)[] | null;
}

export interface GetVismaOrderHistory_adminScope_vismaCaskOrderHistory {
  __typename: "VismaCaskOrderHistoryConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetVismaOrderHistory_adminScope_vismaCaskOrderHistory_nodes | null)[] | null;
}

export interface GetVismaOrderHistory_adminScope {
  __typename: "AdminQueries";
  vismaCaskOrderHistory: GetVismaOrderHistory_adminScope_vismaCaskOrderHistory | null;
}

export interface GetVismaOrderHistory {
  adminScope: GetVismaOrderHistory_adminScope;
}

export interface GetVismaOrderHistoryVariables {
  caskId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSyncQueue
// ====================================================

export interface GetSyncQueue_adminScope_syncQueue_nodes {
  __typename: "SyncQueueItem";
  id: number;
  created: GraphQL_DateTime;
  ended: GraphQL_DateTime | null;
  runAfter: GraphQL_DateTime | null;
  function: SyncFunction;
  arguments: (string | null)[] | null;
  status: SyncRunStatus;
}

export interface GetSyncQueue_adminScope_syncQueue_pageInfo {
  __typename: "PageInfo";
  /**
   * Indicates whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
  /**
   * Indicates whether more edges exist prior the set defined by the clients arguments.
   */
  hasPreviousPage: boolean;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetSyncQueue_adminScope_syncQueue {
  __typename: "SyncQueueConnection";
  /**
   * A flattened list of the nodes.
   */
  nodes: (GetSyncQueue_adminScope_syncQueue_nodes | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetSyncQueue_adminScope_syncQueue_pageInfo;
}

export interface GetSyncQueue_adminScope {
  __typename: "AdminQueries";
  syncQueue: GetSyncQueue_adminScope_syncQueue | null;
}

export interface GetSyncQueue {
  adminScope: GetSyncQueue_adminScope;
}

export interface GetSyncQueueVariables {
  after?: string | null;
  where?: SyncQueueItemFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncVismaUser
// ====================================================

export interface SyncVismaUser_syncVismaUser {
  __typename: "VismaUser";
  name: string | null;
}

export interface SyncVismaUser {
  syncVismaUser: SyncVismaUser_syncVismaUser;
}

export interface SyncVismaUserVariables {
  vismaUserId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListCask
// ====================================================

export interface ListCask {
  __typename: "Cask";
  id: number;
  vismaId: number;
  vismaCaskNumber: string | null;
  caskSign: string | null;
  status: CaskStatus;
  stockNumber: string | null;
  lastSyncedWithVisma: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SyncQueueItem
// ====================================================

export interface SyncQueueItem {
  __typename: "SyncQueueItem";
  id: number;
  created: GraphQL_DateTime;
  ended: GraphQL_DateTime | null;
  runAfter: GraphQL_DateTime | null;
  function: SyncFunction;
  arguments: (string | null)[] | null;
  status: SyncRunStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountType {
  COMPANY = "COMPANY",
  PERSON = "PERSON",
}

export enum AfterBottlingAction {
  PART_BOTTLING = "PART_BOTTLING",
  REFILL = "REFILL",
  REFILL_GIN = "REFILL_GIN",
  REFILL_SMOKEY = "REFILL_SMOKEY",
  REFILL_TWO_YEAR = "REFILL_TWO_YEAR",
  REFILL_TWO_YEAR_SMOKEY = "REFILL_TWO_YEAR_SMOKEY",
  SAVE = "SAVE",
  SCRAP = "SCRAP",
  SELL = "SELL",
}

export enum BankAccountType {
  INTERNATIONAL = "INTERNATIONAL",
  SWEDISH = "SWEDISH",
}

export enum BottlingOrderAmount {
  FULL_BOTTLING = "FULL_BOTTLING",
  PART_BOTTLING = "PART_BOTTLING",
}

export enum CaskStatus {
  FILLED = "FILLED",
  FULL_BOTTLED = "FULL_BOTTLED",
  FULL_BOTTLING_ORDERED = "FULL_BOTTLING_ORDERED",
  NOT_FILLED = "NOT_FILLED",
  REMOVED = "REMOVED",
}

export enum Country {
  DENMARK = "DENMARK",
  ESTONIA = "ESTONIA",
  GERMANY = "GERMANY",
  SWEDEN = "SWEDEN",
}

export enum Customization {
  FILE = "FILE",
  NONE = "NONE",
  PERSONAL_TEXT = "PERSONAL_TEXT",
}

export enum DeliveryOccasionLocation {
  DENMARK = "DENMARK",
  ESTONIA = "ESTONIA",
  GERMANY = "GERMANY",
  SWEDEN = "SWEDEN",
}

export enum FlavourNoteLanguage {
  ENGLISH = "ENGLISH",
  SWEDISH = "SWEDISH",
}

export enum Language {
  ENGLISH = "ENGLISH",
  SWEDISH = "SWEDISH",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum OrderType {
  BOTTLING = "BOTTLING",
  CASK = "CASK",
  FLAVOR_NOTE = "FLAVOR_NOTE",
  SAMPLING = "SAMPLING",
  UNKNOWN = "UNKNOWN",
}

export enum SamplingOrderType {
  DISTILLERY = "DISTILLERY",
  EVENT = "EVENT",
  SYSTEMBOLAGET = "SYSTEMBOLAGET",
}

export enum SyncFunction {
  SYNC_CASK_ASYNC = "SYNC_CASK_ASYNC",
  SYNC_CASK_ORDER_HISTORY_ASYNC = "SYNC_CASK_ORDER_HISTORY_ASYNC",
  SYNC_CUSTOMER_NUMBER_ASYNC = "SYNC_CUSTOMER_NUMBER_ASYNC",
  SYNC_ORDERED_CASK_ASYNC = "SYNC_ORDERED_CASK_ASYNC",
  SYNC_USER_ASYNC = "SYNC_USER_ASYNC",
  UPDATE_USER_IN_VISMA_ASYNC = "UPDATE_USER_IN_VISMA_ASYNC",
}

export enum SyncRunStatus {
  ERROR = "ERROR",
  NOT_FOUND = "NOT_FOUND",
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
}

export enum UserType {
  MEMBER = "MEMBER",
  OWNER = "OWNER",
}

export enum VismaCaskOrderHistoryType {
  PRODUCTION = "PRODUCTION",
  PURCHASE = "PURCHASE",
  STOCK_TRANSFER = "STOCK_TRANSFER",
  UNKNOWN = "UNKNOWN",
}

export interface AccountTypeOperationFilterInput {
  eq?: AccountType | null;
  neq?: AccountType | null;
  in?: AccountType[] | null;
  nin?: AccountType[] | null;
}

export interface BooleanOperationFilterInput {
  eq?: boolean | null;
  neq?: boolean | null;
}

export interface CaskFilterInput {
  and?: CaskFilterInput[] | null;
  or?: CaskFilterInput[] | null;
  id?: ComparableInt32OperationFilterInput | null;
  vismaId?: ComparableInt32OperationFilterInput | null;
  vismaCaskNumber?: StringOperationFilterInput | null;
  caskUsers?: ListFilterInputTypeOfCaskUserFilterInput | null;
  caskType?: CaskTypeFilterInput | null;
  caskTypeId?: ComparableNullableOfInt32OperationFilterInput | null;
  recipe?: RecipeFilterInput | null;
  recipeId?: ComparableNullableOfInt32OperationFilterInput | null;
  caskSign?: StringOperationFilterInput | null;
  status?: CaskStatusOperationFilterInput | null;
  notRefillable?: BooleanOperationFilterInput | null;
  caskTypeText?: StringOperationFilterInput | null;
  recipeText?: StringOperationFilterInput | null;
  volumeFilled?: ComparableNullableOfDecimalOperationFilterInput | null;
  stockNumber?: StringOperationFilterInput | null;
  loanClaim?: ComparableNullableOfDecimalOperationFilterInput | null;
  alcoholPercentage?: ComparableNullableOfDecimalOperationFilterInput | null;
  filled?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  liquorFilled?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  percentSmoked?: ComparableNullableOfDecimalOperationFilterInput | null;
  warehouseArea?: ComparableNullableOfInt32OperationFilterInput | null;
  timesRefilled?: ComparableInt32OperationFilterInput | null;
  currentVolume?: ComparableNullableOfDecimalOperationFilterInput | null;
  loadVolume?: ComparableNullableOfDecimalOperationFilterInput | null;
  lastSyncedWithVisma?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  imageThumb?: StringOperationFilterInput | null;
  imageFull?: StringOperationFilterInput | null;
  oldestOrderDate?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

export interface CaskStatusOperationFilterInput {
  eq?: CaskStatus | null;
  neq?: CaskStatus | null;
  in?: CaskStatus[] | null;
  nin?: CaskStatus[] | null;
}

export interface CaskTypeFilterInput {
  and?: CaskTypeFilterInput[] | null;
  or?: CaskTypeFilterInput[] | null;
  id?: ComparableInt32OperationFilterInput | null;
  vismaId?: ComparableInt32OperationFilterInput | null;
  caskTypeInformation?: ListFilterInputTypeOfCaskTypeInformationFilterInput | null;
  price?: ComparableDecimalOperationFilterInput | null;
  imageUrl?: StringOperationFilterInput | null;
  imagePath?: StringOperationFilterInput | null;
  outOfStock?: BooleanOperationFilterInput | null;
  archived?: BooleanOperationFilterInput | null;
  size?: ComparableDecimalOperationFilterInput | null;
  productIdSmoky?: StringOperationFilterInput | null;
  productIdNonSmoky?: StringOperationFilterInput | null;
}

export interface CaskTypeInformationFilterInput {
  and?: CaskTypeInformationFilterInput[] | null;
  or?: CaskTypeInformationFilterInput[] | null;
  caskTypeId?: ComparableInt32OperationFilterInput | null;
  language?: LanguageOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
}

export interface CaskUserFilterInput {
  and?: CaskUserFilterInput[] | null;
  or?: CaskUserFilterInput[] | null;
  id?: ComparableInt32OperationFilterInput | null;
  user?: UserFilterInput | null;
  vismaUser?: LocalVismaUserFilterInput | null;
  userType?: UserTypeOperationFilterInput | null;
}

export interface ComparableDateTimeOffsetOperationFilterInput {
  eq?: GraphQL_DateTime | null;
  neq?: GraphQL_DateTime | null;
  in?: GraphQL_DateTime[] | null;
  nin?: GraphQL_DateTime[] | null;
  gt?: GraphQL_DateTime | null;
  ngt?: GraphQL_DateTime | null;
  gte?: GraphQL_DateTime | null;
  ngte?: GraphQL_DateTime | null;
  lt?: GraphQL_DateTime | null;
  nlt?: GraphQL_DateTime | null;
  lte?: GraphQL_DateTime | null;
  nlte?: GraphQL_DateTime | null;
}

export interface ComparableDecimalOperationFilterInput {
  eq?: GraphQL_Decimal | null;
  neq?: GraphQL_Decimal | null;
  in?: GraphQL_Decimal[] | null;
  nin?: GraphQL_Decimal[] | null;
  gt?: GraphQL_Decimal | null;
  ngt?: GraphQL_Decimal | null;
  gte?: GraphQL_Decimal | null;
  ngte?: GraphQL_Decimal | null;
  lt?: GraphQL_Decimal | null;
  nlt?: GraphQL_Decimal | null;
  lte?: GraphQL_Decimal | null;
  nlte?: GraphQL_Decimal | null;
}

export interface ComparableInt32OperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: number[] | null;
  nin?: number[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ComparableNullableOfDateTimeOffsetOperationFilterInput {
  eq?: GraphQL_DateTime | null;
  neq?: GraphQL_DateTime | null;
  in?: (GraphQL_DateTime | null)[] | null;
  nin?: (GraphQL_DateTime | null)[] | null;
  gt?: GraphQL_DateTime | null;
  ngt?: GraphQL_DateTime | null;
  gte?: GraphQL_DateTime | null;
  ngte?: GraphQL_DateTime | null;
  lt?: GraphQL_DateTime | null;
  nlt?: GraphQL_DateTime | null;
  lte?: GraphQL_DateTime | null;
  nlte?: GraphQL_DateTime | null;
}

export interface ComparableNullableOfDecimalOperationFilterInput {
  eq?: GraphQL_Decimal | null;
  neq?: GraphQL_Decimal | null;
  in?: (GraphQL_Decimal | null)[] | null;
  nin?: (GraphQL_Decimal | null)[] | null;
  gt?: GraphQL_Decimal | null;
  ngt?: GraphQL_Decimal | null;
  gte?: GraphQL_Decimal | null;
  ngte?: GraphQL_Decimal | null;
  lt?: GraphQL_Decimal | null;
  nlt?: GraphQL_Decimal | null;
  lte?: GraphQL_Decimal | null;
  nlte?: GraphQL_Decimal | null;
}

export interface ComparableNullableOfInt32OperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: (number | null)[] | null;
  nin?: (number | null)[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface LanguageOperationFilterInput {
  eq?: Language | null;
  neq?: Language | null;
  in?: Language[] | null;
  nin?: Language[] | null;
}

export interface ListFilterInputTypeOfCaskTypeInformationFilterInput {
  all?: CaskTypeInformationFilterInput | null;
  none?: CaskTypeInformationFilterInput | null;
  some?: CaskTypeInformationFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfCaskUserFilterInput {
  all?: CaskUserFilterInput | null;
  none?: CaskUserFilterInput | null;
  some?: CaskUserFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfLocalVismaUserFilterInput {
  all?: LocalVismaUserFilterInput | null;
  none?: LocalVismaUserFilterInput | null;
  some?: LocalVismaUserFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfRecipeInformationFilterInput {
  all?: RecipeInformationFilterInput | null;
  none?: RecipeInformationFilterInput | null;
  some?: RecipeInformationFilterInput | null;
  any?: boolean | null;
}

export interface ListStringOperationFilterInput {
  all?: StringOperationFilterInput | null;
  none?: StringOperationFilterInput | null;
  some?: StringOperationFilterInput | null;
  any?: boolean | null;
}

export interface LocalVismaUserFilterInput {
  and?: LocalVismaUserFilterInput[] | null;
  or?: LocalVismaUserFilterInput[] | null;
  id?: ComparableInt32OperationFilterInput | null;
  vismaUserId?: ComparableInt32OperationFilterInput | null;
  vismaCustomerNumber?: ComparableInt32OperationFilterInput | null;
  personalIdentifier?: StringOperationFilterInput | null;
  firstName?: StringOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  country?: StringOperationFilterInput | null;
  orgNumber?: StringOperationFilterInput | null;
  orgName?: StringOperationFilterInput | null;
  birthDate?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  created?: ComparableDateTimeOffsetOperationFilterInput | null;
  lastLogin?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  address?: StringOperationFilterInput | null;
  city?: StringOperationFilterInput | null;
  zipCode?: StringOperationFilterInput | null;
  accountType?: AccountTypeOperationFilterInput | null;
  lastSyncedWithVisma?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  isActiveUser?: BooleanOperationFilterInput | null;
  phoneNumber?: StringOperationFilterInput | null;
}

export interface RecipeFilterInput {
  and?: RecipeFilterInput[] | null;
  or?: RecipeFilterInput[] | null;
  id?: ComparableInt32OperationFilterInput | null;
  recipeInformation?: ListFilterInputTypeOfRecipeInformationFilterInput | null;
  price?: ComparableDecimalOperationFilterInput | null;
}

export interface RecipeInformationFilterInput {
  and?: RecipeInformationFilterInput[] | null;
  or?: RecipeInformationFilterInput[] | null;
  recipeId?: ComparableInt32OperationFilterInput | null;
  language?: LanguageOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
}

export interface StringOperationFilterInput {
  and?: StringOperationFilterInput[] | null;
  or?: StringOperationFilterInput[] | null;
  eq?: string | null;
  neq?: string | null;
  contains?: string | null;
  ncontains?: string | null;
  in?: (string | null)[] | null;
  nin?: (string | null)[] | null;
  startsWith?: string | null;
  nstartsWith?: string | null;
  endsWith?: string | null;
  nendsWith?: string | null;
}

export interface SyncFunctionOperationFilterInput {
  eq?: SyncFunction | null;
  neq?: SyncFunction | null;
  in?: SyncFunction[] | null;
  nin?: SyncFunction[] | null;
}

export interface SyncQueueItemFilterInput {
  and?: SyncQueueItemFilterInput[] | null;
  or?: SyncQueueItemFilterInput[] | null;
  id?: ComparableInt32OperationFilterInput | null;
  created?: ComparableDateTimeOffsetOperationFilterInput | null;
  ended?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  runAfter?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  function?: SyncFunctionOperationFilterInput | null;
  arguments?: ListStringOperationFilterInput | null;
  status?: SyncRunStatusOperationFilterInput | null;
}

export interface SyncRunStatusOperationFilterInput {
  eq?: SyncRunStatus | null;
  neq?: SyncRunStatus | null;
  in?: SyncRunStatus[] | null;
  nin?: SyncRunStatus[] | null;
}

export interface UserFilterInput {
  and?: UserFilterInput[] | null;
  or?: UserFilterInput[] | null;
  vismaUsers?: ListFilterInputTypeOfLocalVismaUserFilterInput | null;
  created?: ComparableDateTimeOffsetOperationFilterInput | null;
  firstName?: StringOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  vismaUser?: LocalVismaUserFilterInput | null;
  id?: StringOperationFilterInput | null;
  userName?: StringOperationFilterInput | null;
  normalizedUserName?: StringOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  normalizedEmail?: StringOperationFilterInput | null;
  emailConfirmed?: BooleanOperationFilterInput | null;
  passwordHash?: StringOperationFilterInput | null;
  securityStamp?: StringOperationFilterInput | null;
  concurrencyStamp?: StringOperationFilterInput | null;
  phoneNumber?: StringOperationFilterInput | null;
  phoneNumberConfirmed?: BooleanOperationFilterInput | null;
  twoFactorEnabled?: BooleanOperationFilterInput | null;
  lockoutEnd?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
  lockoutEnabled?: BooleanOperationFilterInput | null;
  accessFailedCount?: ComparableInt32OperationFilterInput | null;
}

export interface UserTypeOperationFilterInput {
  eq?: UserType | null;
  neq?: UserType | null;
  in?: UserType[] | null;
  nin?: UserType[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
