import { UploadFile } from "components/FileUploader/FileUploader";
import {
  getImageMetaData,
  getImagePreview,
} from "components/FileUploader/FileUploaderPreview";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import CaskType from "models/cask-type";
import { Language } from "models/translatable";
import { minNumber, required, requiredType } from "utils/forms/validators";

type CaskTypeFormState = {
  sweName: FieldState<string>;
  engName: FieldState<string>;
  sweDescription: FieldState<string>;
  engDescription: FieldState<string>;
  labelFile: FieldState<any>;
  imageUrl: FieldState<string>;
  price: FieldState<number>;
  outOfStock: FieldState<boolean>;
  productIdSmoky: FieldState<string>;
  productIdNonSmoky: FieldState<string>;
  vismaId: FieldState<number>;
  size: FieldState<number>;
};

class CaskTypeFormUiStore {
  @observable labelFile: FieldState<any> = new FieldState(undefined).validators(
    () => {
      if (this.labelFile.value) {
        let size = this.labelFile.value!.file.size;

        if (size > 1000000) {
          return "Bilden kan max vara 1MB stor";
        }
      }
      return false;
    }
  );

  constructor() {
    makeObservable(this);
  }

  sweName: FieldState<string> = new FieldState("").validators(
    required("Svenskt namn måste finnas")
  );
  engName: FieldState<string> = new FieldState("").validators(
    required("Engelskt namn måste finnas")
  );
  sweDescription: FieldState<string> = new FieldState("").validators(
    required("Svensk beskrivning måste finnas")
  );
  engDescription: FieldState<string> = new FieldState("").validators(
    required("Engelsk beskrivning måste finnas")
  );
  imageUrl: FieldState<string> = new FieldState("").validators(
    required("En fatbild måste finnas")
  );
  price: FieldState<number> = new FieldState(0).validators(
    requiredType<number>("Ett pris måste anges"),
    minNumber(-1, "Ett högre pris måste anges")
  );
  outOfStock: FieldState<boolean> = new FieldState(false);
  productIdSmoky: FieldState<string> = new FieldState("").validators(
    required("Produkt-id rökigt måste finnas")
  );
  productIdNonSmoky: FieldState<string> = new FieldState("").validators(
    required("Produkt-id orökigt måste finnas")
  );
  vismaId: FieldState<number> = new FieldState(0).validators(
    requiredType<number>("Fattyp måste finnas")
  );
  size: FieldState<number> = new FieldState(0).validators(
    requiredType<number>("En fatvolym måste anges"),
    minNumber(-1, "En högre fatvolym måste anges")
  );

  form = new FormState<CaskTypeFormState>({
    sweName: this.sweName,
    engName: this.engName,
    sweDescription: this.sweDescription,
    engDescription: this.engDescription,
    labelFile: this.labelFile,
    imageUrl: this.imageUrl,
    price: this.price,
    outOfStock: this.outOfStock,
    productIdSmoky: this.productIdSmoky,
    productIdNonSmoky: this.productIdNonSmoky,
    vismaId: this.vismaId,
    size: this.size,
  });

  @action
  resetForm() {
    this.sweName.reset();
    this.engName.reset();
    this.sweDescription.reset();
    this.engDescription.reset();
    this.imageUrl.reset();
    this.price.reset();
    this.outOfStock.reset();
    this.labelFile.reset();
    this.productIdSmoky.reset();
    this.productIdNonSmoky.reset();
    this.vismaId.reset();
    this.size.reset();
  }

  @action
  setForm(caskType: CaskType) {
    var sweInfo = caskType.caskTypeInformation.find(
      (i) => i.language === Language.Swedish
    );
    var engInfo = caskType.caskTypeInformation.find(
      (i) => i.language === Language.English
    );
    this.sweName.value = sweInfo ? sweInfo.name : "";
    this.engName.value = engInfo ? engInfo.name : "";
    this.sweDescription.value = sweInfo ? sweInfo.description : "";
    this.engDescription.value = engInfo ? engInfo.description : "";
    this.imageUrl.value = caskType.imageUrl || "";
    this.price.value = caskType.price || 0;
    this.outOfStock.value = caskType.outOfStock || false;
    this.productIdSmoky.value = caskType.productIdSmoky || "";
    this.productIdNonSmoky.value = caskType.productIdNonSmoky || "";
    this.vismaId.value = caskType.vismaId || 0;
    this.size.value = caskType.size || 0;
  }

  @action
  async addLabelFile(file: UploadFile) {
    let imagePreview = await getImagePreview(file.file);
    let imageMetaData = await getImageMetaData(imagePreview);
    file.imagePreview = imagePreview;
    file.imageMetaData = imageMetaData;
    await this.labelFile.onChange(file);
  }
}

export default CaskTypeFormUiStore;
export const caskTypeFormUiStore = new CaskTypeFormUiStore();
