import * as React from 'react';
import { Component } from 'react';
import SessionStore from 'stores/domain/session';
import { inject, observer } from 'mobx-react';
import DrawerMenuItems from './DrawerMenuItems';

interface Props {
  sessionStore?: SessionStore;
}

@inject('sessionStore')
@observer
class DrawerMenuItemsContainer extends Component<Props, {}> {
  get sessionStore() {
    return this.props.sessionStore!;
  }

  logOut = () => {
    this.sessionStore.logOut();
  }

  render() {
    return (
      <DrawerMenuItems logOut={this.logOut}/>
    );
  }
}

export default DrawerMenuItemsContainer;
