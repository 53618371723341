import { UploadFile } from "components/FileUploader/FileUploader";
import {
  getImageMetaData,
  getImagePreview,
} from "components/FileUploader/FileUploaderPreview";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import NewsItem from "models/news-item";
import { required } from "utils/forms/validators";

type NewsFormState = {
  labelFile: FieldState<any>;
  sweTitle: FieldState<string>;
  engTitle: FieldState<string>;
  sweBody: FieldState<string>;
  engBody: FieldState<string>;
  imageUrl: FieldState<string>;
};

class NewsFormUiStore {
  @observable labelFile: FieldState<any> = new FieldState(undefined).validators(
    () => {
      if (this.labelFile.value) {
        let size = this.labelFile.value!.file.size;

        if (size > 1000000) {
          return "Bilden kan max vara 1MB stor";
        }
      }
      return false;
    }
  );

  constructor() {
    makeObservable(this);
  }

  sweTitle: FieldState<string> = new FieldState("").validators(
    required("Svensk titel måste finnas")
  );
  engTitle: FieldState<string> = new FieldState("").validators(
    required("Engelsk titel måste finnas")
  );
  sweBody: FieldState<string> = new FieldState("").validators(
    required("Svensk huvuddel måste finnas")
  );
  engBody: FieldState<string> = new FieldState("").validators(
    required("Engelsk huvuddel måste finnas")
  );
  imageUrl: FieldState<string> = new FieldState("").validators(
    required("En bild måste finnas")
  );

  form = new FormState<NewsFormState>({
    labelFile: this.labelFile,
    sweTitle: this.sweTitle,
    engTitle: this.engTitle,
    sweBody: this.sweBody,
    engBody: this.engBody,
    imageUrl: this.imageUrl,
  });

  @action
  resetForm() {
    this.sweTitle.reset();
    this.engTitle.reset();
    this.sweBody.reset();
    this.engBody.reset();
    this.imageUrl.reset();
    this.labelFile.reset();
  }

  @action
  setForm(newsItem: NewsItem) {
    this.sweTitle.value = newsItem.newsContent[1].title || "";
    this.engTitle.value = newsItem.newsContent[0].title || "";
    this.sweBody.value = newsItem.newsContent[1].body || "";
    this.engBody.value = newsItem.newsContent[0].body || "";
    this.imageUrl.value = newsItem.imageUrl || "";
  }

  @action
  async addLabelFile(file: UploadFile) {
    let imagePreview = await getImagePreview(file.file);
    let imageMetaData = await getImageMetaData(imagePreview);
    file.imagePreview = imagePreview;
    file.imageMetaData = imageMetaData;
    this.labelFile.onChange(file);
  }
}

export default NewsFormUiStore;
export const newsFormUiStore = new NewsFormUiStore();
