import * as React from 'react';
import { RaisedButton, FlatButton, Dialog } from 'material-ui';
import {
  ContentSave,
  ContentAdd,
  ActionDelete,
  HardwareKeyboardArrowDown,
  HardwareKeyboardArrowUp
} from 'material-ui/svg-icons';
import { inject, observer } from 'mobx-react';
import { InformationUiStore } from 'stores';
import styled from 'styled-components';

interface Props {
  onAdd: (event: React.MouseEvent<{}>) => void;
  onSave: (event: React.MouseEvent<{}>) => void;
  onDelete: (event: React.MouseEvent<{}>) => void;
  onMoveUp: (event: React.MouseEvent<{}>) => void;
  onMoveDown: (event: React.MouseEvent<{}>) => void;
  isSubitem: boolean;
  informationUiStore?: InformationUiStore;
}

const StyledFlatButton = styled(FlatButton)`
  margin: 5px 5px;
`;
const StyledRaisedButton = styled(RaisedButton)`
  margin: 5px 5px;
`;

@inject('informationUiStore')
@observer
export default class BodyActions extends React.Component<Props, {}> {
  deleteItem = (event: React.MouseEvent<{}>) => {
    this.props.onDelete(event);
    this.informationUiStore.closeDialog();
  }
  get informationUiStore() {
    return this.props.informationUiStore!;
  }

  get save() {
    return (
      <StyledRaisedButton
        icon={<ContentSave />}
        label="Spara"
        onClick={this.props.onSave}
      />
    );
  }

  get cancel() {
    return (
      <StyledFlatButton
        key="cancel"
        label="Avbryt"
        onClick={() => this.informationUiStore.closeDialog()}
      />
    );
  }

  get confirmDelete() {
    return (
      <StyledRaisedButton
        icon={<ActionDelete />}
        key="delete"
        label="Radera"
        onClick={this.deleteItem}
      />
    );
  }

  get delete() {
    return (
      <span>
        <StyledFlatButton
          icon={<ActionDelete />}
          label="Radera"
          onClick={() => this.informationUiStore.openDialog()}
        />
        <Dialog
          actions={[this.confirmDelete, this.cancel]}
          modal={false}
          open={this.informationUiStore.dialogIsOpen}
          onRequestClose={() => this.informationUiStore.closeDialog()}
        >
          <h1>Är du säker?</h1>
          <p>
            Alla olika språk och undermenyer för menyraden kommer att tas bort.
            Det kommer inte gå att återställa dem efter att de har raderats.
          </p>
        </Dialog>
      </span>
    );
  }

  get moveUp() {
    return (
      <StyledRaisedButton
        icon={<HardwareKeyboardArrowUp />}
        label="Flytta upp"
        onClick={this.props.onMoveUp}
      />
    );
  }

  get moveDown() {
    return (
      <StyledRaisedButton
        icon={<HardwareKeyboardArrowDown />}
        label="Flytta ner"
        onClick={this.props.onMoveDown}
      />
    );
  }

  get add() {
    return (
      <StyledRaisedButton
        icon={<ContentAdd />}
        label="Lägg till"
        onClick={this.props.onAdd}
      />
    );
  }
  render() {
    if (this.props.isSubitem) {
      return (
        <div>
          {this.save}
          {this.moveUp}
          {this.moveDown}
          {this.delete}
        </div>
      );
    } else {
      return (
        <div>
          {this.save}
          {this.add}
          {this.moveUp}
          {this.moveDown}
          {this.delete}
        </div>
      );
    }
  }
}
