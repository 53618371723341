import * as React from 'react';
import { Component } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router';
import SessionStore from 'stores/domain/session';
import { inject, observer } from 'mobx-react';

interface Props extends RouteProps {
    sessionStore?: SessionStore;
}

@inject('sessionStore')
@observer
export default class PublicOnlyRoute extends Component<Props, {}> {
    render() {
        let { isAuthenticated } = this.props.sessionStore!;
        
        return (
            <div>
                {!isAuthenticated ? (
                    <Route {...this.props}>
                        {this.props.children}
                    </Route>
                ) : (
                    <Redirect to={{ pathname: '/', state: {} }}/>
                )}
            </div>
        );
    }
}