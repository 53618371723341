import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import CaskTypesStore from 'stores/domain/cask-types';
import CaskTypeFormUiStore from 'stores/ui/cask-type';
import { Language } from 'models/translatable';
import CaskType from 'models/cask-type';
import CaskTypesService, { CreateCaskTypeViewModel, 
  CaskTypeInformationViewModel } from 'services/cask-types';
import UploadFileService from 'services/upload-file-service';
import CaskTypeComponent from './CaskTypeComponent';
import { UploadFile } from 'components/FileUploader/FileUploader';

interface Props extends RouteComponentProps<{}> {
  id: number;
  caskTypesStore?: CaskTypesStore;  
  caskTypeFormUiStore?: CaskTypeFormUiStore;
}

@inject('caskTypesStore', 'caskTypeFormUiStore')  
@observer
class CaskTypeContainer extends React.Component<Props, {}> {
  
  componentWillMount() {
    this.caskTypeFormUiStore.resetForm();
    if (this.props.id) {
      this.caskTypeFormUiStore.setForm(this.findCask(this.props.id));
    }
  }

  findCask(id: number): CaskType {
    return this.caskTypesStore.find(id);
  }

  get caskTypesStore() {
    return this.props.caskTypesStore!;
  }

  get caskTypeFormUiStore() {
    return this.props.caskTypeFormUiStore!;
  }
  
  get setCreateCaskTypeViewModel(): CreateCaskTypeViewModel {

    let engCaskTypeInformation: CaskTypeInformationViewModel = {
      language: Language.English,
      name: this.caskTypeFormUiStore.engName.value,
      description: this.caskTypeFormUiStore.engDescription.value
    };

    let sweCaskTypeInformation: CaskTypeInformationViewModel = {
      language: Language.Swedish,
      name: this.caskTypeFormUiStore.sweName.value,
      description: this.caskTypeFormUiStore.sweDescription.value
    };

    let caskTypeInformations = Array<CaskTypeInformationViewModel>();
    caskTypeInformations.push(engCaskTypeInformation);
    caskTypeInformations.push(sweCaskTypeInformation);

    return {
        id: this.props.id,
        price: this.caskTypeFormUiStore.price.value,
        imageUrl: this.caskTypeFormUiStore.imageUrl.value,
        caskTypeInformation: caskTypeInformations,
        outOfStock: this.caskTypeFormUiStore.outOfStock.value,
        productIdSmoky: this.caskTypeFormUiStore.productIdSmoky.value,
        productIdNonSmoky: this.caskTypeFormUiStore.productIdNonSmoky.value,
        vismaId: this.caskTypeFormUiStore.vismaId.value,
        size: this.caskTypeFormUiStore.size.value
      };
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.caskTypeFormUiStore.labelFile.$) {
      let response = await UploadFileService.uploadCaskTypeFiles([this.caskTypeFormUiStore.labelFile.$]);
      
      if (!response.succeeded) {
        if (response.statusCode === 403) {
          throw new Error('Could\'t upload image: Access denied.');
        } else {
          throw new Error('Could\'t upload image.');
        }
      }
      this.caskTypeFormUiStore.imageUrl.onChange(response.data.uploadedUrl);
    }

    let validation = await this.caskTypeFormUiStore.form.validate();
    
    if (validation.hasError) {
        return;
    }

    let createCaskTypeViewModel = this.setCreateCaskTypeViewModel;

    try {
      let response;
      if (this.props.id) {
        response = await CaskTypesService.editCaskType(createCaskTypeViewModel);
      } else {
        response = await CaskTypesService.createCaskType(createCaskTypeViewModel);        
      }
      
      if (!response.succeeded) {
        if (response.statusCode === 403) {
          throw new Error('Could\'t change cask type: Access denied.');          
        } else {
          throw new Error('Could\'t change cask type.');
        }
      }
    } catch (Error) {
      throw Error;
    }

    this.props.history.push(`/cask-supply`);    
  }

  onAddFiles= (files: UploadFile[]) => {
    let file = files[0];
    this.caskTypeFormUiStore.addLabelFile(file);
  }

  render() {
    let hasFieldError = this.caskTypeFormUiStore.form.hasFieldError;
    
    return (
      <div>
        <CaskTypeComponent
          formStore={this.caskTypeFormUiStore}
          hasFieldError={hasFieldError}
          onSubmit={this.onSubmit}
          onAddFiles={this.onAddFiles}
        />
      </div>
    );
  }
}

export default CaskTypeContainer;