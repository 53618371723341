import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { envConfig } from "config";
import { uniqBy } from "lodash";
import { LS_ACCESS_TOKEN } from "services/session";

const httpLink = createHttpLink({
  uri: envConfig.REACT_APP_API_HOST + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  let lsToken = localStorage.getItem(LS_ACCESS_TOKEN);
  let token = lsToken !== null ? lsToken : undefined;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      AdminQueries: {
        fields: {
          syncQueue: {
            merge(existing = { nodes: [] }, incoming) {
              if (!incoming) return existing;

              const { nodes, ...rest } = incoming;

              let result = rest;
              result.nodes = uniqBy([...existing.nodes, ...nodes], "__ref");

              return result;
            },
          },
        },
      },
    },
  }),
});
