import LoadingSplash from "components/LoadingSpinners/LoadingSplash";
import { FlatButton } from "material-ui";
import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import CaskTypesStore from "stores/domain/cask-types";
import CaskTypeContainer from "../../components/CaskTypeContainer";

interface Props {
  caskTypesStore?: CaskTypesStore;
}

interface PropsWithRoute extends Props, RouteComponentProps<{ id: string }> {}

@inject("caskTypesStore")
@observer
class EditCaskTypePage extends React.Component<PropsWithRoute, {}> {
  componentWillMount() {
    if (!this.caskTypesStore.exists(this.caskId)) {
      this.caskTypesStore.fetchCasks();
    }
  }

  get caskTypesStore() {
    return this.props.caskTypesStore!;
  }

  get caskId() {
    return parseInt(this.props.match.params.id, 10);
  }

  render() {
    if (this.caskTypesStore.isLoading) {
      return <LoadingSplash />;
    }

    return (
      <div>
        <Link to="/cask-supply">
          <FlatButton
            icon={<NavigationArrowBack />}
            label="Tillbaka"
            labelPosition="after"
          />
        </Link>
        <h1>Redigera fat</h1>
        <CaskTypeContainer
          id={this.caskId}
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default withRouter<PropsWithRoute, typeof EditCaskTypePage>(
  EditCaskTypePage
);
