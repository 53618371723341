import * as React from 'react';
import CaskTypeContainer from '../../components/CaskTypeContainer';
import { FlatButton } from 'material-ui';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps<{}> {
}

class CreateCaskTypePage extends React.Component<Props, {}> {
  render() {
    
    return (
      <div>
        <Link to="/cask-supply">
          <FlatButton
                icon={<NavigationArrowBack/>}
                label="Tillbaka"
                labelPosition="after"
          />
        </Link>
        <h1>Skapa nytt fat</h1>
        <CaskTypeContainer
          id={NaN}
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default CreateCaskTypePage;