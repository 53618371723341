import * as React from 'react';
import BottlingPriceVariablesManager from './components/BottlingPriceVariablesManager';

class BottlingPriceVariablesPage extends React.Component {
  render() {
    return (
      <div>
        <h1>Prisvariabler för buteljering</h1>
        <BottlingPriceVariablesManager />
      </div>
    );
  }
}

export default BottlingPriceVariablesPage;
