import { action, makeObservable, observable } from "mobx";
import BottlingPriceVariables from "models/bottling-price-variables";
import BottlingPriceVariablesService from "services/bottling-price-variables";
import { bottlingPriceVariablesFormUiStore } from "stores/ui/bottling-price-variables-form";

class BottlingPriceVariablesStore {
  @observable
  loading!: boolean;
  @observable bottlingPriceVariables: BottlingPriceVariables[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async fetchBottlingPriceVariables() {
    this.loading = true;
    let response =
      await BottlingPriceVariablesService.fetchBottlingPriceVariables();

    if (!response.succeeded) {
      throw new Error(`Couldn't fetch bottling prices`);
    } else {
      this.bottlingPriceVariables = response.data;
      this.setUiBottlingPriceVariables();
      this.loading = false;
    }
  }

  setUiBottlingPriceVariables() {
    this.bottlingPriceVariables.map((prices) => {
      bottlingPriceVariablesFormUiStore.setForm(prices);
    });
  }
}

export default BottlingPriceVariablesStore;
export const bottlingPriceVariablesStore = new BottlingPriceVariablesStore();
