import * as React from 'react';
import { Component } from 'react';
import UsersPage from './components/UsersPage';
import { inject, observer } from 'mobx-react';
import { UsersPageUiStore, UsersStore } from 'stores';
import EditUserModal from './components/EditUserModal';

export interface Props {
  usersStore: UsersStore;
  usersPageUiStore: UsersPageUiStore;
}

@inject('usersStore', 'usersPageUiStore')
@observer
export default class UserPageContainer extends Component<Props> {
  public render() {
    let {
      changeSearchTerm,
      editingUser,
      isSearching,
      hideEditUserModal,
      noUsersFound,
      saveUser,
      searchTerm,
      searchUsers,
      showEditUserModal,
    } = this.props.usersPageUiStore;
    return (
      <div>
        <UsersPage
          isSearching={isSearching}
          noUsersFound={noUsersFound}
          searchTerm={searchTerm}
          onSearchUsers={searchUsers}
          onChangeSearchTerm={changeSearchTerm}
          users={this.props.usersStore.users}
          onEditUserClick={showEditUserModal}
        />

        {editingUser && (
          <EditUserModal
            onClose={hideEditUserModal}
            onSaveClick={saveUser}
            user={editingUser}
            userPageUiStore={this.props.usersPageUiStore}
          />
        )}
      </div>
    );
  }
}
