import { Box, Divider } from "@chakra-ui/react";
import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import User from "models/user";
import { FC } from "react";
import styled from "styled-components";
import UserList from "./UserList";
import UserSearchForm from "./UserSearchForm";
import VismaUserSyncer from "./VismaUserSyncer";

const LoadingSpinnerWrapper = styled.div`
  margin-top: 50px;
  display: hidden;
`;
const NoUsers = styled.div`
  width: 100%;
  text-align: center;
  p {
    padding: 200px 100px;
    font-size: 30px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.3);
  }
`;
export interface Props {
  isSearching: boolean;
  noUsersFound: boolean;
  searchTerm: string;
  onSearchUsers: () => void;
  onChangeSearchTerm: (newValue: string) => void;
  users: User[];
  onEditUserClick: (userId: string) => void;
}

const UsersPage: FC<Props> = ({
  isSearching,
  noUsersFound,
  searchTerm,
  onEditUserClick,
  onSearchUsers,
  onChangeSearchTerm,
  users,
}) => (
  <div>
    <VismaUserSyncer />
    <Divider my={8} />
    <Box display="flex" justifyContent="space-between">
      <UserSearchForm
        onSearch={onSearchUsers}
        onChangeSearchTerm={onChangeSearchTerm}
        isSearching={isSearching}
        searchTerm={searchTerm}
      />
    </Box>

    {isSearching ? (
      <LoadingSpinnerWrapper>
        <LoadingSpinner text="Söker" />
      </LoadingSpinnerWrapper>
    ) : noUsersFound ? (
      <NoUsers>
        <p>Inga användare hittades</p>
      </NoUsers>
    ) : users.length === 0 ? (
      <NoUsers>
        <p>Använd sökrutan för att hitta användare</p>
      </NoUsers>
    ) : (
      <UserList users={users} onEditUserClick={onEditUserClick} />
    )}
  </div>
);

export default UsersPage;
