import { FC } from "react";
import styled, { css } from "styled-components";
import ThreeDotSpinner from "./ThreeDotSpinner";

interface Props {
  text?: string;
  size?: "small" | "normal";
}

const LoadingSpinnerContainer = styled.div<{ size: "small" | "normal" }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ size }) =>
    size === "small" &&
    css`
      transform: scale(0.4);
      transform-origin: left;
    `}
`;

const TextContainer = styled.div`
  color: #47525a;
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 20px;
`;

const SpinnerContainer = styled.div``;

const LoadingSpinner: FC<Props> = ({ text, size }) => {
  if (text === undefined) {
    return (
      <LoadingSpinnerContainer size={size || "normal"}>
        <SpinnerContainer>
          <ThreeDotSpinner />
        </SpinnerContainer>
      </LoadingSpinnerContainer>
    );
  }

  return (
    <LoadingSpinnerContainer size={size || "normal"}>
      <TextContainer>{text}</TextContainer>

      <SpinnerContainer>
        <ThreeDotSpinner />
      </SpinnerContainer>
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
