import { FieldState } from "formstate/lib";
import { inject, observer } from "mobx-react";
import BottlingPriceVariables from "models/bottling-price-variables";
import DeliveryOccasionLocation from "models/delivery-occation-location";
import * as React from "react";
import BottlingPriceVariablesService from "services/bottling-price-variables";
import BottlingPriceVariablesStore from "stores/domain/bottling-price-variables";
import BottlingPriceVariablesFormUiStore from "stores/ui/bottling-price-variables-form";
import Validator from "validator";
import BottlingPriceVariablesEditor from "./BottlingPriceVariablesEditor";

interface Props {
  bottlingPriceVariablesStore?: BottlingPriceVariablesStore;
  bottlingPriceVariablesFormUiStore?: BottlingPriceVariablesFormUiStore;
}

@inject("bottlingPriceVariablesStore", "bottlingPriceVariablesFormUiStore")
@observer
class BottlingPriceVariablesManager extends React.Component<Props, {}> {
  get store() {
    return this.props.bottlingPriceVariablesStore;
  }

  get formStore() {
    return this.props.bottlingPriceVariablesFormUiStore;
  }

  componentDidMount() {
    this.store!.fetchBottlingPriceVariables();
  }

  getCountryName = (value: DeliveryOccasionLocation): string => {
    switch (value) {
      case DeliveryOccasionLocation.Sweden:
        return "Sverige";
      case DeliveryOccasionLocation.Denmark:
        return "Danmark";
      case DeliveryOccasionLocation.Germany:
        return "Tyskland";
      case DeliveryOccasionLocation.Estonia:
        return "Estland";
      default:
        return "";
    }
  };

  handleFloatChange = (floatValue: string, fieldState: FieldState<number>) => {
    let parsedValue = parseFloat(floatValue);
    if (Validator.isNumeric(floatValue) || parseFloat(floatValue)) {
      fieldState.onChange(parsedValue);
    }

    if (floatValue === "") {
      fieldState.onChange(Number.NaN);
    }
  };

  handleIntegerChange = (
    integerValue: string,
    fieldState: FieldState<number>
  ) => {
    if (Validator.isNumeric(integerValue)) {
      let parsedValue = Number(integerValue);
      fieldState.onChange(parsedValue);
    }

    if (integerValue === "") {
      fieldState.onChange(Number.NaN);
    }
  };

  getEditModel(
    bottlingPriceVariables: BottlingPriceVariables
  ): BottlingPriceVariables {
    let formStore = this.formStore!.findFormStore(bottlingPriceVariables.id);

    let {
      vatRate,
      alcoholDutyRate,
      exchangeRate,
      vendorFlatRate,
      vendorMarginRate,
    } = formStore!;

    return {
      id: bottlingPriceVariables.id,
      country: bottlingPriceVariables.country,
      vatRate: vatRate.value,
      alcoholDutyRate: alcoholDutyRate.value,
      exchangeRate: exchangeRate.value,
      vendorMarginRate: vendorMarginRate.value,
      vendorFlatRate: vendorFlatRate.value,
    };
  }

  submitForm = async (bottlingPriceVariables: BottlingPriceVariables) => {
    let validation = await this.formStore!.findForm(
      bottlingPriceVariables.id
    ).validate();

    if (validation.hasError) {
      return;
    }

    let editModel = this.getEditModel(bottlingPriceVariables);

    try {
      let response =
        await BottlingPriceVariablesService.editBottlingPriceVariables(
          editModel
        );

      if (!response.succeeded) {
        if (response.statusCode === 403) {
          throw new Error("Could't change bottling prices: Access denied.");
        } else {
          throw new Error("Could't change bottling prices.");
        }
      }
    } catch (Error) {
      throw Error;
    }

    this.store!.fetchBottlingPriceVariables();
  };

  renderLoading() {
    return <div>Hämtar priser...</div>;
  }

  renderEditors = () => {
    let { bottlingPriceVariables } = this.store!;

    return bottlingPriceVariables
      .slice()
      .sort((a, b) => a.id - b.id)
      .map((prices) => {
        let form = this.formStore!.findFormStore(prices.id);
        let {
          exchangeRate,
          vatRate,
          alcoholDutyRate,
          vendorFlatRate,
          vendorMarginRate,
        } = form;
        return (
          <BottlingPriceVariablesEditor
            key={prices.id}
            countryName={this.getCountryName(prices.country)}
            exchangeRate={exchangeRate.value}
            vatRate={vatRate.value}
            alcoholDutyRate={alcoholDutyRate.value}
            vendorFlatRate={vendorFlatRate.value}
            vendorMarginRate={vendorMarginRate.value}
            handleExchangeRateChange={(value) =>
              this.handleFloatChange(value, exchangeRate)
            }
            handleVatRateChange={(value) =>
              this.handleFloatChange(value, vatRate)
            }
            handleAlcoholDutyRateChange={(value) =>
              this.handleFloatChange(value, alcoholDutyRate)
            }
            handleVendorFlatRateChange={(value) =>
              this.handleFloatChange(value, vendorFlatRate)
            }
            handleVendorMarginRateChange={(value) =>
              this.handleFloatChange(value, vendorMarginRate)
            }
            handleSubmit={() => this.submitForm(prices)}
          />
        );
      });
  };

  render() {
    let { loading } = this.store!;
    let { renderLoading, renderEditors } = this;
    if (loading) {
      return renderLoading();
    }
    return renderEditors();
  }
}

export default BottlingPriceVariablesManager;
