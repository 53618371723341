import InformationUiStore, { informationUiStore } from './information';
import LoginFormUiStore, { loginFormUiStore } from './login-form';
import CaskTypeFormUiStore, { caskTypeFormUiStore } from './cask-type';
import RecipesFormUiStore, { recipesFormUiStore } from './recipes';
import BottlingPriceVariablesFormUiStore, {
  bottlingPriceVariablesFormUiStore
} from './bottling-price-variables-form';
import NewsFormUiStore, { newsFormUiStore } from './news';
import UsersPageUiStore, { usersPageUiStore } from './users-page';

export default {
  // Instances
  loginFormUiStore,
  caskTypeFormUiStore,
  recipesFormUiStore,
  bottlingPriceVariablesFormUiStore,
  newsFormUiStore,
  usersPageUiStore,
  informationUiStore
};

export {
  // Classes
  LoginFormUiStore,
  CaskTypeFormUiStore,
  RecipesFormUiStore,
  BottlingPriceVariablesFormUiStore,
  NewsFormUiStore,
  UsersPageUiStore,
  InformationUiStore
};
