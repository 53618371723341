import { convertFromRaw, EditorState } from "draft-js";
import { action, makeObservable, observable } from "mobx";
import { MenuItem } from "models/menu-item";
import InformationService from "services/information";
import { Language } from "./../../models/translatable";

export default class InformationStore {
  @observable menuItems: MenuItem[] = [];
  @observable
  isLoading!: boolean;
  @observable selectedItem!: MenuItem | null;

  constructor() {
    makeObservable(this);
  }

  @action
  startLoading() {
    this.isLoading = true;
  }
  @action
  stopLoading() {
    this.isLoading = false;
  }

  @action
  setDefault() {
    if (this.menuItems && this.menuItems.length > 0) {
      this.selectedItem = this.menuItems[0];
    } else {
      this.selectedItem = null;
    }
  }

  @action
  async fetchInformation() {
    this.startLoading();
    const response = await InformationService.fetchInformation();
    if (!response.succeeded) {
      throw new Error(`Couldn't fetch menu items`);
    }

    this.menuItems = response.data.map((menuItem) => {
      return this.setupEditorState(menuItem);
    });

    this.sortItems();

    if (!this.selectedItem) {
      this.setDefault();
    }
    this.stopLoading();
  }

  @action
  setSelectedEditorState(state: EditorState, language: Language) {
    if (this.selectedItem !== null) {
      this.findContentByLanguage(this.selectedItem, language)!.editorState =
        state;
    }
  }
  @action
  setSelectedTitle(title: string, language: Language) {
    if (this.selectedItem !== null) {
      this.findContentByLanguage(this.selectedItem, language)!.title = title;
    }
  }

  @action
  changeSelected(item: MenuItem) {
    this.selectedItem = item;
  }

  @action
  addItem(menuItem: MenuItem, parentId?: number) {
    if (parentId) {
      this.find(parentId).children.push(menuItem);
    } else {
      this.menuItems.push(menuItem);
    }
  }

  @action
  updateMenuItems(menuItems: MenuItem[]) {
    this.menuItems = menuItems;
  }

  find(id: number, parentID: number = -1): MenuItem {
    let item: MenuItem | undefined = undefined;
    if (parentID !== -1) {
      let parentItem = this.menuItems.find((c) => c.id === parentID);
      if (parentItem && parentItem.children) {
        item = parentItem.children.find((c) => c.id === id);
      }
    } else {
      item = this.menuItems.find((c) => c.id === id);
    }

    if (!item) {
      throw new Error(`Menu item with id ${id} couldn't be found`);
    }

    return item;
  }

  @action
  sortItems() {
    this.menuItems = this.sort(this.menuItems);
  }

  /**
   * Checks the item for content with the given language. If no language is found undefinee will be returned.
   * @param item The item to check for content in.
   * @param language The lanugage to find.
   */
  private findContentByLanguage(item: MenuItem, language: Language) {
    return item.menuItemContent.find(
      (content) => content.language === language
    );
  }

  /**
   * This function will create a editor state from the body of the menu item.
   * If the menu item has any children this function will run recursivly on those children.
   * @param menuItem The item to setup the editor state for.
   */
  private setupEditorState(menuItem: MenuItem): MenuItem {
    const newContent = menuItem.menuItemContent.map((content) => {
      if (!content.editorState && content.body) {
        content.editorState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(content.body))
        );
      } else if (!content.editorState) {
        content.editorState = EditorState.createEmpty();
      }
      return content;
    });

    menuItem.menuItemContent = newContent;

    if (menuItem.children) {
      menuItem.children = menuItem.children.map((child) =>
        this.setupEditorState(child)
      );
    }
    return menuItem;
  }
  /**
   * Sort all items in the menu items array based on the position value.
   * Will also sort the child array of each menu item recurivly until there are no more children.
   * @param menuItems The Items to sort.
   */
  private sort(menuItems: MenuItem[]) {
    const sorted = menuItems.sort((a, b) => a.position - b.position);
    return sorted.map((item) => {
      if (item.children) {
        item.children = this.sort(item.children);
      }
      return item;
    });
  }
}

export const informationStore = new InformationStore();
