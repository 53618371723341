import Api from './api';
import { Language } from 'models/translatable';
import Recipe from 'models/recipe';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';

export interface EditRecipeViewModel {
    id: number;
    recipeInformation: Array<RecipeInformationViewModel>;
    price: number;
}

export interface RecipeInformationViewModel {
    language: Language;
    name: string;
    description: string;
}

export default class RecipesService {
    static async fetchRecipes(): Promise<JsonApiResponse<Recipe[]>> {
        let response = await Api.get('recipes', false);
        return await toJsonApiResponse<Recipe[]>(response);
    }

    static async editRecipe(vm: EditRecipeViewModel): Promise<JsonApiResponse<Recipe>> {
        let response = await Api.put('recipes', true, undefined, vm);
        return await toJsonApiResponse<Recipe>(response);
    }
}