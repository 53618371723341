import { format, formatDistance, formatRelative, parseISO } from "date-fns/esm";
import locale from "date-fns/locale/sv";

export const formatDate = (inputDate: Date | string) => {
  if (!inputDate) return "";
  const now = new Date();
  const date = typeof inputDate === "string" ? parseISO(inputDate) : inputDate;

  return formatDistance(date, now, {
    locale,
    addSuffix: true,
  });
};

export const formatRelativeDate = (inputDate: Date | string) => {
  if (!inputDate) return "";
  const date =
    typeof inputDate === "string"
      ? parseISO(inputDate.endsWith("Z") ? inputDate : `${inputDate}Z`)
      : inputDate;

  return formatRelative(date, new Date(), { locale });
};

export const formatDateString = (inputDate: Date | string) => {
  const date =
    typeof inputDate === "string"
      ? parseISO(inputDate.endsWith("Z") ? inputDate : `${inputDate}Z`)
      : inputDate;

  let out = "";
  try {
    out = format(date, "yyyy-MM-dd HH:mm");
  } catch (error) {}

  return out;
};
