import * as React from 'react';

class Home extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div>
            <h1>Home</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
