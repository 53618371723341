import { gql, useQuery } from "@apollo/client";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router";
import { formatDateString } from "utils/dates";
import {
  GetCaskOrder,
  GetCaskOrderVariables,
  OrderType,
} from "__generated__/graphql";
import BottlingOrderDetails from "./BottlingOrderDetails";
import { mapOrderStatus, mapOrderType } from "./CaskOrdersTable";
import FlavourNoteOrderDetails from "./FlavourNoteOrderDetails";
import SamplingOrderDetails from "./SamplingOrderDetails";

const GET_ORDER = gql`
  query GetCaskOrder($orderId: Int!) {
    adminScope {
      orders(first: 1, where: { and: { id: { eq: $orderId } } }) {
        nodes {
          id
          vismaId
          status
          created
          updated
          orderType
          totalPrice
          user {
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const CaskOrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { loading, data } = useQuery<GetCaskOrder, GetCaskOrderVariables>(
    GET_ORDER,
    {
      variables: { orderId: Number(orderId) },
    }
  );
  const order = data?.adminScope.orders?.nodes![0]!;
  const history = useHistory();

  if (loading) return <Spinner />;

  return (
    <>
      <Box mb={4}>
        <Button
          mb={4}
          leftIcon={<ChevronLeftIcon />}
          variant="outline"
          onClick={() => history.goBack()}
        >
          Tillbaka
        </Button>
      </Box>
      <Heading size="lg" mb={4}>
        {`Order ${order.vismaId}`}
      </Heading>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Visma-id</StatLabel>
            <StatNumber fontSize="lg">{order.vismaId}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Typ</StatLabel>
            <StatNumber fontSize="lg">
              {mapOrderType(order.orderType)}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Status</StatLabel>
            <StatNumber fontSize="lg">
              {mapOrderStatus(order.status)}
            </StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Beställare</StatLabel>
            <StatNumber fontSize="lg">{`${order.user?.firstName} ${order.user?.lastName}`}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Skapad</StatLabel>
            <StatNumber fontSize="lg">
              {formatDateString(order.created || "")}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Uppdaterad</StatLabel>
            <StatNumber fontSize="lg">
              {formatDateString(order.updated || "")}
            </StatNumber>
          </Stat>
        </StatGroup>
      </Box>
      <Box p={4}>
        <Heading size="lg" mb={2}>
          Orderinformation
        </Heading>
        {order.orderType === OrderType.BOTTLING && <BottlingOrderDetails />}
        {order.orderType === OrderType.SAMPLING && <SamplingOrderDetails />}
        {order.orderType === OrderType.FLAVOR_NOTE && (
          <FlavourNoteOrderDetails />
        )}
      </Box>
    </>
  );
};

export default CaskOrderPage;
