import { gql, useMutation } from "@apollo/client";
import { CheckIcon, RepeatIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import React from "react";
import {
  AddCaskToSyncQueue,
  AddCaskToSyncQueueVariables,
} from "__generated__/graphql";

interface SyncCaskButtonProps {
  vismaCaskId: number;
}

const SYNC_CASK = gql`
  mutation AddCaskToSyncQueue($vismaCaskId: Int!) {
    addCaskToSyncQueue(vismaCaskId: $vismaCaskId)
  }
`;

const SyncCaskButton: React.FC<SyncCaskButtonProps> = ({ vismaCaskId }) => {
  const [addToSync, { loading, called }] = useMutation<
    AddCaskToSyncQueue,
    AddCaskToSyncQueueVariables
  >(SYNC_CASK, { variables: { vismaCaskId } });

  return (
    <>
      <Button
        bg="white"
        disabled={loading || called}
        leftIcon={called && !loading ? <CheckIcon /> : <RepeatIcon />}
        variant="outline"
        onClick={async () => await addToSync()}
      >
        Synka
      </Button>
    </>
  );
};

export default SyncCaskButton;
