import Divider from "material-ui/Divider";
import { ListItem } from "material-ui/List";
import { observer } from "mobx-react";
import * as React from "react";
import styled, { CSSProperties } from "styled-components";
// import ActionDelete from 'material-ui/svg-icons/action/delete';
// import IconButton from 'material-ui/IconButton';
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import NewsItem from "models/news-item";

interface Props {
  newsItem: NewsItem;
  editNewsItem: (newsItem: NewsItem) => void;
  removeNewsItem: (newsItem: NewsItem) => Promise<void>;
}

const dialogStyle = {
  width: "400px",
};

const dialogButtonsStyle = {
  textAlign: "center",
  paddingLeft: "24px",
  paddingRight: "24px",
};

const DialogButton = styled(RaisedButton)`
  min-width: 100% !important;
  margin-top: 8px !important;
`;

const removeDialogButtonStyle = {
  backgroundColor: "red",
};

// const StyledActionDelete = styled(ActionDelete)`
//     top: 12px !important;
// `;

@observer
export default class NewsListItem extends React.Component<Props, {}> {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  removeNewsItem = () => {
    this.setState({ open: false });
    this.props.removeNewsItem(this.props.newsItem);
  };

  render() {
    let { newsItem, editNewsItem } = this.props;
    const actions = [
      <DialogButton label="Avbryt" onClick={this.handleClose} />,
      <DialogButton
        buttonStyle={removeDialogButtonStyle}
        label="Ta bort"
        labelColor="#ffffff"
        onClick={this.removeNewsItem}
      />,
    ];

    return (
      <div>
        <Dialog
          title="Ta bort nyheten?"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          contentStyle={dialogStyle}
          actionsContainerStyle={dialogButtonsStyle as CSSProperties}
        >
          Är du säker på att du vill ta bort nyheten? Nyheten kommer inte länge
          att visas på fatägarwebben.
        </Dialog>
        <ListItem
          key={newsItem.id.toString()}
          primaryText={newsItem.newsContent[1].title}
          onClick={(e) => editNewsItem(newsItem)}
          /* rightIconButton={
                        <IconButton
                            onClick={e => this.handleOpen()}
                        >
                            <StyledActionDelete/>
                        </IconButton>
                    } */
        />
        <Divider />
      </div>
    );
  }
}
