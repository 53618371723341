import User from "models/user";
import { FC } from "react";
import styled from "styled-components";

export interface Props {
  user: User;
}

const Table = styled.table`
  margin-top: 20px;
  margin-bottom: 20px;
  td:first-child {
    padding-right: 20px;
  }
  td:last-child {
    color: #47525a;
  }
`;

const EditUserInfo: FC<Props> = ({ user }) => (
  <Table>
    <tbody>
      <tr>
        <td>Namn</td>
        <td>
          {user.firstName} {user.lastName}
        </td>
      </tr>
      <tr>
        <td>AnvändarId på fatägarwebben</td>
        <td>{user.id}</td>
      </tr>
    </tbody>
  </Table>
);

export default EditUserInfo;
