import FileUploader, { UploadFile } from "components/FileUploader/FileUploader";
import FileUploaderPreview from "components/FileUploader/FileUploaderPreview";
import { RaisedButton, TextField } from "material-ui";
import Paper from "material-ui/Paper";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import NewsFormUiStore from "stores/ui/news";
import styled from "styled-components";

interface Props {
  formStore: NewsFormUiStore;
  hasFieldError: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onAddFiles: (files: UploadFile[]) => void;
}

const StyledPaper = styled(Paper)`
  width: 600px;
`;

const SubmitButton = styled(RaisedButton)`
  margin: 20px 20px 20px 0px;
`;

const AbortLink = styled(Link)`
  margin: 20px 0px 20px 20px;
`;

const Form = styled.form`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  width: 250px;
`;

@observer
class NewsComponent extends React.Component<Props, {}> {
  render() {
    let { formStore, hasFieldError, onSubmit, onAddFiles } = this.props;

    return (
      <StyledPaper>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <TextField
            floatingLabelText={"Titel (Svenska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.sweTitle.onChange(e.currentTarget.value)
            }
            value={formStore.sweTitle.value}
            errorText={formStore.sweTitle.error}
          />
          <TextField
            floatingLabelText={"Titel (Engelska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.engTitle.onChange(e.currentTarget.value)
            }
            value={formStore.engTitle.value}
            errorText={formStore.engTitle.error}
          />
          <FileUploader
            multiple={false}
            buttonLabel={"Välj bild"}
            onAddFiles={onAddFiles}
          />
          <span style={{ color: "red" }}>{formStore.labelFile.error}</span>
          {formStore.imageUrl.hasError && (
            <span style={{ color: "red" }}>{formStore.imageUrl.error}</span>
          )}
          {!formStore.labelFile.value && formStore.imageUrl.value && (
            <Img src={formStore.imageUrl.value} />
          )}
          {formStore.labelFile.value && (
            <FileUploaderPreview
              files={[formStore.labelFile.value]}
              imageStyle={{ height: "150px", maxWidth: "250px" }}
            />
          )}
          <TextField
            multiLine={true}
            floatingLabelText={"Huvuddel (Svenska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.sweBody.onChange(e.currentTarget.value)
            }
            value={formStore.sweBody.value}
            errorText={formStore.sweBody.error}
            rows={1}
            rowsMax={12}
            fullWidth
          />
          <TextField
            multiLine={true}
            floatingLabelText={"Huvuddel (Engelska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.engBody.onChange(e.currentTarget.value)
            }
            value={formStore.engBody.value}
            errorText={formStore.engBody.error}
            rows={1}
            rowsMax={12}
            fullWidth
          />
          <div>
            <SubmitButton
              primary={true}
              type="submit"
              label={"Spara"}
              disabled={hasFieldError}
              style={{ width: "200px" }}
            />
            <AbortLink to="/news">
              <RaisedButton label={"Avbryt"} style={{ width: "200px" }} />
            </AbortLink>
          </div>
        </Form>
      </StyledPaper>
    );
  }
}

export default NewsComponent;
