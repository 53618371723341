import { useLazyQuery } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormLabel,
  Input,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import * as cv from "@techstark/opencv-js";
import {
  GetCaskByNumber,
  GetCaskByNumberVariables,
} from "__generated__/graphql";
import React, { useEffect, useRef, useState } from "react";
import { Image as KonvaImage, Layer, Rect, Stage } from "react-konva";
import { useDebouncedValue } from "rooks";
import UploadFileService from "services/upload-file-service";
import { createWorker } from "tesseract.js";
import { GET_CASK_BY_NUMBER } from "./CaskImagesPage";

interface CaskImagePrevieProps {
  image: File;
  dequeue: () => File | undefined;
}

const MAX_HEIGHT = 600;
const rectWidth = 1 / 6;
const rectHeight = 1 / 24;

const CaskImagePreview: React.FC<CaskImagePrevieProps> = ({
  image,
  dequeue,
}) => {
  const toast = useToast();
  const [caskNumberValue, setCaskNumber] = useState("");
  const [caskNumber, immediatelyUpdateDebouncedCaskNumber] = useDebouncedValue(
    caskNumberValue,
    1000
  );
  const [getCask, { data, loading }] = useLazyQuery<
    GetCaskByNumber,
    GetCaskByNumberVariables
  >(GET_CASK_BY_NUMBER);
  const [{ width, height }, setDimensions] = useState({ width: 1, height: 1 });
  const [imageElement, setImageElement] = useState<HTMLImageElement>();
  const [{ x, y }, setSignPos] = useState<{ x?: number; y?: number }>({
    x: undefined,
    y: undefined,
  });
  const [{ x: hoverX, y: hoverY }, setHoverPos] = useState<{
    x?: number;
    y?: number;
  }>({
    x: undefined,
    y: undefined,
  });
  const scale = MAX_HEIGHT / height;
  const canvasWidth = Math.round(width * scale);
  const canvasHeight = Math.round(height * scale);
  const signRef = useRef<HTMLCanvasElement>(null);
  const cask = data?.adminScope.casks?.nodes?.at(0);

  const onSubmit = () => {
    dequeue();
    toast({
      id: caskNumber,
      title: "Fatbild laddas upp.",
      description: `Fatbilden för fat ${caskNumber} laddas upp.`,
      status: "loading",
      duration: 20000,
    });

    UploadFileService.uploadCaskImage(image, cask!.id).then(() =>
      toast.update(caskNumber, {
        title: "Fatbild laddades upp.",
        description: `Fatbilden för fat ${caskNumber} laddades upp.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    );

    immediatelyUpdateDebouncedCaskNumber("");
    setCaskNumber("");
  };

  useEffect(() => {
    var fr = new FileReader();

    fr.onload = () => {
      const img = new Image();
      setImageElement(img);

      img.onload = function () {
        setDimensions({ width: img.width, height: img.height });
      };
      img.src = fr.result as any;
    };

    fr.readAsDataURL(image);
  }, [image]);

  useEffect(() => {
    if (signRef.current && signRef.current.width > 10) {
      createWorker({
        //logger: (m) => console.log(m)
      }).then((worker) => {
        (async () => {
          await worker.loadLanguage("eng");
          await worker.initialize("eng");
          const {
            data: { text },
          } = await worker.recognize(signRef.current!);

          let result = text.replace(/\D/g, "");
          result = result.substring(0, 4) + "-" + result.substring(4);

          if (result.length > 9) result = result.substring(0, 9);

          immediatelyUpdateDebouncedCaskNumber(result);
          setCaskNumber(result);
          await worker.terminate();
        })();
      });
    }
  }, [signRef, x, y]);

  useEffect(() => {
    if (imageElement && x && y)
      try {
        const src = cv.imread(imageElement);

        const cut = new cv.Rect(
          Math.round(x / scale) - (width * rectWidth) / 2,
          Math.round(y / scale) - (height * rectHeight) / 2,
          width * rectWidth,
          height * rectHeight
        );

        let dst = src.roi(cut);

        cv.cvtColor(dst, dst, cv.COLOR_RGBA2GRAY, 0);
        const ksize = new cv.Size(3, 3);
        // You can try more different parameters
        cv.GaussianBlur(dst, dst, ksize, 0, 0, cv.BORDER_DEFAULT);

        const M = cv.Mat.ones(2, 2, cv.CV_8U);
        const anchor = new cv.Point(-1, -1);
        cv.erode(
          dst,
          dst,
          M,
          anchor,
          1,
          cv.BORDER_CONSTANT,
          cv.morphologyDefaultBorderValue()
        );

        //cv.threshold(dst, dst, 0, 255, cv.THRESH_BINARY_INV + cv.THRESH_OTSU);
        let dsize = new cv.Size(cut.width * 4, cut.height * 4);
        cv.resize(dst, dst, dsize, 0, 0, cv.INTER_CUBIC);

        cv.imshow(signRef.current!, dst);
        dst.delete();
        src.delete();
      } catch (error) {
        console.log(error);
      }
  }, [signRef, x, y]);

  useEffect(() => {
    const no =
      caskNumber && caskNumber.replace(/\D/g, "").replaceAll("-", "").trim();
    if (no.length > 5) {
      console.log(no);
      getCask({ variables: { caskNumber } });
    }
  }, [caskNumber]);

  return (
    <Box display="flex">
      <Box maxW="50%">
        <Stage
          width={canvasWidth}
          height={canvasHeight}
          onClick={(e: any) =>
            setSignPos({ x: e.evt.offsetX, y: e.evt.offsetY })
          }
          onMouseLeave={() => setHoverPos({ x: undefined, y: undefined })}
          onMouseMove={(e: any) => {
            setHoverPos({ x: e.evt.offsetX, y: e.evt.offsetY });
          }}
        >
          <Layer>
            <KonvaImage
              image={imageElement}
              width={canvasWidth}
              height={canvasHeight}
            />
            {x && y && (
              <Rect
                x={x - (canvasWidth * rectWidth) / 2}
                y={y - (canvasHeight * rectHeight) / 2}
                width={canvasWidth * rectWidth}
                height={canvasHeight * rectHeight}
                stroke={"#FF0000"}
              />
            )}
            {hoverX && hoverY && (
              <Rect
                x={hoverX - (canvasWidth * rectWidth) / 2}
                y={hoverY - (canvasHeight * rectHeight) / 2}
                width={canvasWidth * rectWidth}
                height={canvasHeight * rectHeight}
                stroke={"#FFFFFF"}
              />
            )}
          </Layer>
        </Stage>
      </Box>
      <Box pl={8}>
        {loading && <Skeleton w="full" h="50px" mb={6} />}
        {!loading && caskNumber.length < 5 && (
          <Alert status="info" mb={6} borderRadius={4}>
            <AlertIcon />
            Klicka på fatnumret i bilden eller ange det manuellt
          </Alert>
        )}
        {!loading && caskNumber.length > 5 && !cask && (
          <Box>
            <Alert status="warning" mb={6} borderRadius={4}>
              <AlertIcon />
              Hittade inget fat, klicka på bilden igen eller ange fatnummer
              manuellt.
            </Alert>
            <Button
              variant="solid"
              colorScheme="yellow"
              mb={4}
              onClick={() => dequeue()}
            >
              Hoppa över bild
            </Button>
          </Box>
        )}
        {!loading && caskNumber && cask && (
          <Box>
            <Alert status="success" mb={6} borderRadius={4} fontSize="md">
              <AlertIcon />
              {`Hittade fat #${cask.vismaId} med nummer ${cask.vismaCaskNumber}`}
            </Alert>
            <Button variant="solid" mb={4} onClick={onSubmit}>
              Ladda upp bild
            </Button>
          </Box>
        )}

        <FormLabel>Fatnummer</FormLabel>
        <Input
          mb={6}
          value={caskNumberValue}
          onChange={(e) => setCaskNumber(e.target.value)}
        ></Input>
        <canvas style={{ maxWidth: "100%" }} ref={signRef} />
      </Box>
    </Box>
  );
};

export default CaskImagePreview;
