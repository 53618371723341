import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { client } from "apollo";
import "material-design-icons/iconfont/material-icons.css";
import { MuiThemeProvider } from "material-ui/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { Provider } from "mobx-react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import App from "scenes/Application";
import stores from "stores";
import { ThemeProvider } from "styled-components";
import { supressWarnings } from "utils/supress-warnings";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";

const boxTheme = getMuiTheme({
  palette: {
    primary1Color: "#374953",
    accent1Color: "#8a7b52",
    accent2Color: "#ffc015",
    textColor: "#5f5f5f"
  },
  button: {
    textTransform: "none"
  }
});

const queryClient = new QueryClient();
supressWarnings();

const root = ReactDOM.createRoot(document.getElementById(
  "root"
) as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <Provider {...stores}>
        <BrowserRouter>
          <MuiThemeProvider muiTheme={boxTheme}>
            <ThemeProvider theme={boxTheme}>
              <ChakraProvider>
                <App>
                  <Routes />
                </App>
              </ChakraProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
