import FileUploader, { UploadFile } from "components/FileUploader/FileUploader";
import FileUploaderPreview from "components/FileUploader/FileUploaderPreview";
import { Checkbox, RaisedButton, TextField } from "material-ui";
import Paper from "material-ui/Paper";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import CaskTypeFormUiStore from "stores/ui/cask-type";
import styled from "styled-components";
import { isNumber } from "utils/forms/validators";

interface Props {
  formStore: CaskTypeFormUiStore;
  hasFieldError: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onAddFiles: (files: UploadFile[]) => void;
}

const StyledPaper = styled(Paper)`
  width: 600px;
`;

const SubmitButton = styled(RaisedButton)`
  margin: 20px 20px 20px 0px;
`;

const AbortLink = styled(Link)`
  margin: 20px 0px 20px 20px;
`;

const Form = styled.form`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  margin-top: 15px;
  width: 250px;
`;

@observer
class CaskTypeComponent extends React.Component<Props, {}> {
  setPrice = (value: string) => {
    if (isNumber(value)) {
      let valueNumber = parseInt(value, 10);
      this.props.formStore.price.onChange(valueNumber);
    }
  };

  setCaskNumber = (value: string) => {
    if (isNumber(value)) {
      let valueNumber = parseInt(value, 10);
      this.props.formStore.vismaId.onChange(valueNumber);
    }
  };

  setCaskSize = (value: string) => {
    if (isNumber(value)) {
      let valueNumber = parseFloat(value);
      this.props.formStore.size.onChange(valueNumber);
    }
  };

  render() {
    let { formStore, hasFieldError, onSubmit, onAddFiles } = this.props;

    return (
      <StyledPaper>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <TextField
            floatingLabelText={"Fatnamn(Svenska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.sweName.onChange(e.currentTarget.value)
            }
            value={formStore.sweName.value}
            errorText={formStore.sweName.error}
          />
          <TextField
            floatingLabelText={"Fatnamn(Engelska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.engName.onChange(e.currentTarget.value)
            }
            value={formStore.engName.value}
            errorText={formStore.engName.error}
          />
          <FileUploader
            multiple={false}
            buttonLabel={"Välj fatbild"}
            onAddFiles={onAddFiles}
          />
          <span style={{ color: "red" }}>{formStore.labelFile.error}</span>
          <span style={{ color: "red" }}>{formStore.imageUrl.error}</span>
          {!formStore.labelFile.value && formStore.imageUrl.value && (
            <Img src={formStore.imageUrl.value} />
          )}
          {formStore.labelFile.value && (
            <FileUploaderPreview
              files={[formStore.labelFile.value]}
              imageStyle={{ height: "150px", maxWidth: "250px" }}
            />
          )}
          <TextField
            multiLine={true}
            floatingLabelText={"Fatbeskrivning(Svenska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.sweDescription.onChange(e.currentTarget.value)
            }
            value={formStore.sweDescription.value}
            errorText={formStore.sweDescription.error}
            rows={4}
            rowsMax={12}
          />
          <TextField
            multiLine={true}
            floatingLabelText={"Fatbeskrivning(Engelska)"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.engDescription.onChange(e.currentTarget.value)
            }
            value={formStore.engDescription.value}
            errorText={formStore.engDescription.error}
            rows={4}
            rowsMax={12}
          />
          <TextField
            floatingLabelText={"Fattyp"}
            type="number"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              this.setCaskNumber(e.currentTarget.value)
            }
            value={
              formStore.vismaId.value || formStore.vismaId.value === 0
                ? formStore.vismaId.value
                : ""
            }
            errorText={formStore.vismaId.error}
          />
          <TextField
            floatingLabelText={"Fatvolym"}
            type="number"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              this.setCaskSize(e.currentTarget.value)
            }
            value={
              formStore.size.value || formStore.size.value === 0
                ? formStore.size.value
                : ""
            }
            errorText={formStore.size.error}
          />
          <TextField
            floatingLabelText={"Produkt-id rökigt"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.productIdSmoky.onChange(e.currentTarget.value)
            }
            value={formStore.productIdSmoky.value}
            errorText={formStore.productIdSmoky.error}
          />
          <TextField
            floatingLabelText={"Produkt-id orökigt"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              formStore.productIdNonSmoky.onChange(e.currentTarget.value)
            }
            value={formStore.productIdNonSmoky.value}
            errorText={formStore.productIdNonSmoky.error}
          />
          <TextField
            floatingLabelText={"Fatpris(SEK)"}
            type="number"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              this.setPrice(e.currentTarget.value)
            }
            value={
              formStore.price.value || formStore.price.value === 0
                ? formStore.price.value
                : ""
            }
            errorText={formStore.price.error}
          />
          <Checkbox
            style={{ marginTop: "15px" }}
            label={"Erbjud på fatägarwebben"}
            onCheck={() =>
              formStore.outOfStock.onChange(!formStore.outOfStock.value)
            }
            checked={!formStore.outOfStock.value}
          />

          <div>
            <SubmitButton
              primary={true}
              type="submit"
              label={"Spara"}
              disabled={hasFieldError}
              style={{ width: "200px" }}
            />
            <AbortLink to="/cask-supply">
              <RaisedButton label={"Avbryt"} style={{ width: "200px" }} />
            </AbortLink>
          </div>
        </Form>
      </StyledPaper>
    );
  }
}

export default CaskTypeComponent;
