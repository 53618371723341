import { List } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import { inject, observer } from "mobx-react";
import CaskType from "models/cask-type";
import { Language } from "models/translatable";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import CaskTypesService, {
  CaskTypeInformationViewModel,
  CreateCaskTypeViewModel,
} from "services/cask-types";
import CaskTypesStore from "stores/domain/cask-types";
import CaskTypeListItem from "./CaskTypeListItem";

interface Props extends RouteComponentProps<{}> {
  caskTypesStore?: CaskTypesStore;
}

@inject("caskTypesStore")
@observer
export default class CaskTypeSupply extends React.Component<Props, {}> {
  setCreateCaskTypeViewModel(caskType: CaskType): CreateCaskTypeViewModel {
    let engCaskTypeInformation: CaskTypeInformationViewModel = {
      language: Language.English,
      name: caskType.caskTypeInformation[0].name,
      description: caskType.caskTypeInformation[0].description,
    };

    let sweCaskTypeInformation: CaskTypeInformationViewModel = {
      language: Language.Swedish,
      name: caskType.caskTypeInformation[1].name,
      description: caskType.caskTypeInformation[1].description,
    };

    let caskTypeInformations = Array<CaskTypeInformationViewModel>();
    caskTypeInformations.push(engCaskTypeInformation);
    caskTypeInformations.push(sweCaskTypeInformation);

    return {
      id: caskType.id,
      price: caskType.price,
      imageUrl: caskType.imageUrl,
      caskTypeInformation: caskTypeInformations,
      outOfStock: caskType.outOfStock,
      productIdSmoky: caskType.productIdSmoky,
      productIdNonSmoky: caskType.productIdNonSmoky,
      vismaId: caskType.vismaId,
      size: caskType.size,
    };
  }

  removeCaskType = async (caskType: CaskType) => {
    let createCaskTypeViewModel = this.setCreateCaskTypeViewModel(caskType);

    try {
      let response = await CaskTypesService.deleteCaskType(
        createCaskTypeViewModel
      );

      if (!response.succeeded || !response.data.success) {
        if (response.statusCode === 403) {
          throw new Error("Could't remove cask type: Access denied.");
        } else {
          throw new Error("Could't remove cask type.");
        }
      }
    } catch (Error) {
      throw Error;
    }

    this.caskTypesStore.fetchCasks();
  };

  componentWillMount() {
    this.caskTypesStore.fetchCasks();
  }

  get caskTypesStore() {
    return this.props.caskTypesStore!;
  }

  editCaskType = (caskType: CaskType) => {
    this.props.history.push(`/cask-supply/edit/${caskType.id}`);
  };

  render() {
    return (
      <div>
        <h1>Fat</h1>
        <Link to="/cask-supply/create">
          <RaisedButton
            label="+ Lägg till fat"
            style={{ width: "200px" }}
            backgroundColor="green"
            labelColor="#ffffff"
          />
        </Link>
        <List>
          {this.caskTypesStore.caskTypes
            .slice()
            .sort((a, b) => {
              if (a.outOfStock) {
                return +1;
              }
              return -1;
            })
            .map((caskType) => (
              <CaskTypeListItem
                key={caskType.id.toString()}
                caskType={caskType}
                editCaskType={this.editCaskType}
                removeCaskType={this.removeCaskType}
              />
            ))}
        </List>
      </div>
    );
  }
}
