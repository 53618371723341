import RaisedButton from "material-ui/RaisedButton";
import * as React from "react";
import { Component } from "react";

export interface UploadFile {
  key: number;
  imagePreview?: string;
  file: File;
  imageMetaData?: ImageMetadata;
}

export interface ImageMetadata {
  height: number;
  width: number;
}

interface Props {
  multiple?: boolean;
  fileTypes?: string;
  onAddFiles: (files: Array<UploadFile>) => void;
  buttonLabel: string;
}

class FileUploader extends Component<Props, {}> {
  static defaultProps: Partial<Props> = {
    multiple: false,
    fileTypes: "image/x-png,image/gif,image/jpeg",
  };

  input!: HTMLInputElement;

  lastUniqueKey: number = 0;

  handleOnChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    let target = evt.target as HTMLInputElement;

    if (target.files === null) {
      return;
    }

    let files = this.getFiles(target.files);
    this.props.onAddFiles(files);
  };

  getFiles(fileList: FileList): Array<UploadFile> {
    let files = new Array<UploadFile>();

    for (let i = 0; i < fileList.length; i++) {
      files.push({
        key: this.lastUniqueKey++,
        file: fileList.item(i) as any,
      });
    }

    return files;
  }

  onClick = () => {
    this.input.click();
  };

  render() {
    return (
      <div>
        <input
          style={{ display: "none" }}
          className="file-upload"
          multiple={this.props.multiple}
          type="file"
          ref={(input) => {
            this.input = input!;
          }}
          onChange={(e) => this.handleOnChange(e)}
          accept={this.props.fileTypes}
        />
        <RaisedButton
          label={this.props.buttonLabel}
          onClick={() => this.onClick()}
        />
      </div>
    );
  }
}

export default FileUploader;
