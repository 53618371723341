import Dialog from "material-ui/Dialog";
import { inject } from "mobx-react";
import React, { ReactNode } from "react";
import { LoginFormUiStore, SessionStore } from "stores";
import styled from "styled-components";
import LogInForm from "./LogInFormContainer";

interface Props {
  sessionStore?: SessionStore;
  loginFormUiStore?: LoginFormUiStore;
  children: ReactNode;
}

const BoxHeader = styled.div`
  height: 50px;
  background-color: #374953;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const materialModalContentStyle = {
  width: 400,
};

const materialModalBodyStyle = {
  padding: 0,
};

@inject("sessionStore", "loginFormUiStore")
class LogInModalComponent extends React.Component<Props, {}> {
  render() {
    return (
      <Dialog
        modal={true}
        open={true}
        contentStyle={materialModalContentStyle}
        bodyStyle={materialModalBodyStyle}
      >
        <BoxHeader>Box Admin</BoxHeader>
        <LogInForm
          sessionStore={this.props.sessionStore}
          loginFormUiStore={this.props.loginFormUiStore}
        />
      </Dialog>
    );
  }
}

export default LogInModalComponent;
