import { gql, useQuery } from "@apollo/client";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/DataTable";
import { useHistory, useParams } from "react-router";
import { formatDateString } from "utils/dates";
import {
  GetCaskVismaOrder,
  GetCaskVismaOrderVariables,
  GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_orderRows,
} from "__generated__/graphql";
import DownloadSettlementButton from "./DownloadSettlementButton";
import { mapOrderType } from "./VismaOrderHistoryTable";

const GET_VISMA_ORDER = gql`
  query GetCaskVismaOrder($orderId: Int!) {
    adminScope {
      vismaCaskOrderHistory(
        first: 1
        where: { and: { id: { eq: $orderId } } }
      ) {
        nodes {
          id
          customerNumber
          type
          vismaOrderId
          activityName
          actionName
          bottlingAlcohol
          bottlingRoundName
          bottlingVolumeName
          dilution
          status
          preferedDeliveryTime
          alcohol
          bottlingAlcohol
          comment
          settlementCurrencyRate
          isSettlement
          settlementCurrencyName
          localVismaUser {
            id
            vismaUserId
            vismaCustomerNumber
            firstName
            lastName
            email
            country
          }
          orderRows {
            finishDate
            description
            price
            amount
            productNumber
            quantity
            currencyName
            alcoholTax
            storeMarkup
            settlementPriceInCurrency
            vat
            id
          }
        }
      }
    }
  }
`;

const columnHelper =
  createColumnHelper<GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_orderRows>();

const columns = [
  columnHelper.accessor("description", {
    cell: (info) => info.getValue(),
    header: "Namn",
  }),
  columnHelper.accessor("productNumber", {
    cell: (info) => info.getValue(),
    header: "Produkt",
  }),
  columnHelper.accessor("amount", {
    cell: (info) => info.getValue(),
    header: "Antal",
  }),
  columnHelper.accessor("quantity", {
    cell: (info) => info.getValue(),
    header: "Kvantitet",
  }),
  columnHelper.accessor("price", {
    cell: (info) => info.getValue(),
    header: "Pris",
  }),
  columnHelper.accessor("vat", {
    cell: (info) => info.getValue(),
    header: "Moms",
  }),
  columnHelper.accessor("alcoholTax", {
    cell: (info) => info.getValue(),
    header: "Alkoholskatt",
  }),
  columnHelper.accessor("currencyName", {
    cell: (info) => info.getValue(),
    header: "Valuta",
  }),
  columnHelper.accessor("storeMarkup", {
    cell: (info) => info.getValue(),
    header: "Butiks-påslag",
  }),
  columnHelper.accessor("finishDate", {
    cell: (info) => formatDateString(info.getValue() || ""),
    header: "Datum",
  }),
];

const CaskVismaOrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { loading, data } = useQuery<
    GetCaskVismaOrder,
    GetCaskVismaOrderVariables
  >(GET_VISMA_ORDER, {
    variables: { orderId: Number(orderId) },
  });
  const order = data?.adminScope.vismaCaskOrderHistory?.nodes![0]!;
  const history = useHistory();

  if (loading) return <Spinner />;

  return (
    <>
      <Box mb={4}>
        <Button
          mb={4}
          leftIcon={<ChevronLeftIcon />}
          variant="outline"
          onClick={() => history.goBack()}
        >
          Tillbaka
        </Button>
      </Box>
      <Heading size="lg" mb={4}>
        {`Order ${order.vismaOrderId}`}
      </Heading>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Visma-id</StatLabel>
            <StatNumber fontSize="lg">{order.vismaOrderId}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Typ</StatLabel>
            <StatNumber fontSize="lg">{mapOrderType(order.type)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Status</StatLabel>
            <StatNumber fontSize="lg">{order.status}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Plats</StatLabel>
            <StatNumber fontSize="lg">{order.activityName}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Runda</StatLabel>
            <StatNumber fontSize="lg">{order.bottlingRoundName}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Buteljeringsvolym</StatLabel>
            <StatNumber fontSize="lg">{order.bottlingVolumeName}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Alkoholhalt</StatLabel>
            <StatNumber fontSize="lg">{order.alcohol}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Buteljerings-alkoholhalt</StatLabel>
            <StatNumber fontSize="lg">{order.bottlingAlcohol}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Kommentar</StatLabel>
            <StatNumber fontSize="lg">{order.comment}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Beställare</StatLabel>
            <StatNumber fontSize="lg">{`${order.localVismaUser?.firstName} ${order.localVismaUser?.lastName}`}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Kundnummer</StatLabel>
            <StatNumber fontSize="lg">{`${order.customerNumber}`}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Avräkningsnota</StatLabel>
            <StatNumber fontSize="lg">
              {order.isSettlement && (
                <Box display="flex">
                  <DownloadSettlementButton
                    vismaOrderHistoryId={order.id}
                    language="sv"
                  />
                  <Box ml={2}>
                    <DownloadSettlementButton
                      vismaOrderHistoryId={order.id}
                      language="en"
                    />
                  </Box>
                </Box>
              )}
            </StatNumber>
          </Stat>
        </StatGroup>
      </Box>
      <Box p={4}>
        <Heading size="lg" mb={2}>
          Orderrader
        </Heading>
        <DataTable
          data={
            order.orderRows as GetCaskVismaOrder_adminScope_vismaCaskOrderHistory_nodes_orderRows[]
          }
          columns={columns}
        />
      </Box>
    </>
  );
};

export default CaskVismaOrderPage;
