import { MenuItem } from 'models/menu-item';
import { Language } from 'models/translatable';
import { toJsonApiResponse, JsonApiResponse } from 'utils/json-api';
import Api from './api';

export interface MenuItemViewModel {
  id: number;
  parentId?: number;
  menuContent: MenuItemContentViewModel[];
  position: number;
}

export interface MenuItemContentViewModel {
  language: Language;
  title: string;
  body: string;
}

interface DeleteResponse {
  data: {
    success: boolean;
  };
}

interface DeleteResponseData {
  success: boolean;
}

const Endpoint = 'informationpage';

export default class InformationService {
  static async fetchInformation(): Promise<JsonApiResponse<MenuItem[]>> {
    let response = await Api.get(Endpoint, true);
    return toJsonApiResponse<MenuItem[]>(response);
  }
  static async createMenuItem(
    vm: MenuItemViewModel
  ): Promise<JsonApiResponse<MenuItem>> {
    let response = await Api.post(Endpoint, true, undefined, vm);
    return toJsonApiResponse<MenuItem>(response);
  }

  static async editMenuItem(
    vm: MenuItemViewModel
  ): Promise<JsonApiResponse<MenuItem[]>> {
    let response = await Api.put(Endpoint, true, undefined, vm);
    return toJsonApiResponse<MenuItem[]>(response);
  }

  static async deleteMenuItem(
    vm: MenuItemViewModel
  ): Promise<JsonApiResponse<DeleteResponseData>> {
    let response = await Api.delete(Endpoint, true, undefined, vm);
    let jsonApiResponse = new JsonApiResponse<DeleteResponseData>();

    if (response.status !== 200) {
      jsonApiResponse.statusCode = response.status;
      jsonApiResponse.succeeded = false;
      jsonApiResponse.data = {
        success: false
      };
      return jsonApiResponse;
    }

    let deleteResponse = <DeleteResponse> await response.json();
    jsonApiResponse.statusCode = response.status;
    jsonApiResponse.succeeded = true;

    jsonApiResponse.data = {
      success: deleteResponse.data.success
    };

    return jsonApiResponse;
  }
}
