import AppBar from "components/AppBar/AppBarContainer";
import Drawer from "components/Drawer";
import DrawerMenuItems from "components/DrawerMenuItems";
import LoadingSplash from "components/LoadingSpinners/LoadingSplash";
import { inject } from "mobx-react";
import { Component } from "react";
import SessionStore from "stores/domain/session";
import styled from "styled-components";

interface Props {
  sessionStore?: SessionStore;
  children?: React.ReactNode;
}

interface State {
  isLoading: boolean;
}

const Content = styled.div`
  margin: 64px 0px 0px 256px;
  padding: 30px;
`;

@inject("sessionStore")
class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoading: true };
  }

  get sessionStore() {
    return this.props.sessionStore!;
  }

  async UNSAFE_componentWillMount() {
    await this.sessionStore.logInCurrentUser();
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingSplash />;
    }

    return (
      <div>
        <Drawer>
          <DrawerMenuItems />
        </Drawer>
        <AppBar />
        <Content>{this.props.children}</Content>
      </div>
    );
  }
}

export default App;
