import * as React from "react";
import styled, { keyframes } from "styled-components";
import ThreeDotSpinner from "./ThreeDotSpinner";
const logo = require("assets/images/logo-dark.svg");

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
  position: absolute;
  top: 33%;
  left: 0;
  margin: 0 auto;
  width: 100%;
  animation: ${fadeIn} 0.2s ease-in, 1;
`;

const LogoContainer = styled.div`
  margin: 0 auto;
  max-width: 250px;
  flex: 1;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 100%;
`;

const SpinnerContainer = styled.div`
  margin-top: 7.5px;
  justify-content: center;
`;

class LoadingSpash extends React.Component<{}, {}> {
  render() {
    return (
      <Wrapper>
        <LogoContainer>
          <Logo src={logo.defualt} />
        </LogoContainer>
        <SpinnerContainer>
          <ThreeDotSpinner />
        </SpinnerContainer>
      </Wrapper>
    );
  }
}

export default LoadingSpash;
