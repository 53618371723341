import * as React from 'react';
import { observer } from 'mobx-react';
import { Paper, List, ListItem, FlatButton, makeSelectable } from 'material-ui';
import InformationStore from 'stores/domain/information';
import styled from 'styled-components';
import { Language } from 'models/translatable';
import { MenuItem } from 'models/menu-item';
import LoadingSpinner from 'components/LoadingSpinners/LoadingSpinner';

interface Props {
  informationStore: InformationStore;
  onAddItem: Function;
  language: Language;
}

const SelectableList = makeSelectable(List);

const StyledPaper = styled(Paper)`
  flex: 0 1 200px;
  margin-bottom: 30px;
  align-self: flex-start;
`;

@observer
class MenuComponent extends React.Component<Props, {}> {
  handleClick = (item: MenuItem) => {
    this.props.informationStore.changeSelected(item);
  }

  get listItems() {
    return this.props.informationStore.menuItems.map(item => {
      let submenus: JSX.Element[] = [];
      if (item && item.children) {
        submenus = item.children.map(subItem => {
          return this.getListItem(subItem);
        });
      }
      return this.getListItem(item, submenus);
    });
  }

  render() {
    if (this.props.informationStore.isLoading) {
      return (
        <StyledPaper>
          <LoadingSpinner />
        </StyledPaper>
      );
    } else {
      return (
        <StyledPaper>
          <SelectableList
            value={
              this.props.informationStore.selectedItem
                ? this.props.informationStore.selectedItem.id
                : 0
            }
          >
            {this.listItems}
          </SelectableList>
          <FlatButton
            fullWidth={true}
            onClick={() => {
              this.props.onAddItem();
            }}
            primary={true}
            label="Lägg till menyrad"
          />
        </StyledPaper>
      );
    }
  }

  private getListItem(item: MenuItem, submenus: JSX.Element[] = []) {
    const title = item.menuItemContent.find(
      content => content.language === this.props.language
    )!.title;

    return (
      <ListItem
        key={item.id}
        value={item.id}
        primaryText={title}
        nestedItems={submenus}
        onClick={() => {
          this.handleClick(item);
        }}
      />
    );
  }
}
export default MenuComponent;
