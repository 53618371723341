import { UploadFile } from "components/FileUploader/FileUploader";
import { JsonApiResponse } from "utils/json-api";
import Api from "./api";

interface FileUploadResponse {
  data: {
    uploadedUrl: string;
  };
}

interface FileUploadResponseData {
  uploadedUrl: string;
}

interface CaskImageUploadResponseData {
  thumbUrl: string;
  fullUrl: string;
}

export default class UploadFileService {
  static async uploadCaskTypeFiles(
    files: UploadFile[]
  ): Promise<JsonApiResponse<FileUploadResponseData>> {
    let formData = new FormData();
    formData.append("file", files[0].file, files[0].file.name);

    let response = await Api.post(
      "file-upload/cask-type",
      true,
      { Accept: "application/json" },
      formData,
      undefined,
      true
    );

    let jsonApiResponse = new JsonApiResponse<FileUploadResponseData>();

    if (response.status !== 200) {
      jsonApiResponse.statusCode = response.status;
      jsonApiResponse.succeeded = false;
      return jsonApiResponse;
    }

    let fileUploadResponse = <FileUploadResponse>await response.json();
    jsonApiResponse.statusCode = response.status;
    jsonApiResponse.succeeded = true;

    jsonApiResponse.data = {
      uploadedUrl: fileUploadResponse.data.uploadedUrl,
    };

    return jsonApiResponse;
  }

  static async uploadNewsItemFiles(
    files: UploadFile[]
  ): Promise<JsonApiResponse<FileUploadResponseData>> {
    let formData = new FormData();
    formData.append("file", files[0].file, files[0].file.name);

    let response = await Api.post(
      "file-upload/news-item",
      true,
      { Accept: "application/json" },
      formData,
      undefined,
      true
    );

    let jsonApiResponse = new JsonApiResponse<FileUploadResponseData>();

    if (response.status !== 200) {
      jsonApiResponse.statusCode = response.status;
      jsonApiResponse.succeeded = false;
      return jsonApiResponse;
    }

    let fileUploadResponse = <FileUploadResponse>await response.json();
    jsonApiResponse.statusCode = response.status;
    jsonApiResponse.succeeded = true;

    jsonApiResponse.data = {
      uploadedUrl: fileUploadResponse.data.uploadedUrl,
    };

    return jsonApiResponse;
  }

  static async uploadCaskImage(
    file: File,
    caskId: number
  ): Promise<JsonApiResponse<CaskImageUploadResponseData>> {
    let formData = new FormData();
    formData.append("file", file, caskId + "");

    let response = await Api.post(
      "file-upload/cask-image/" + caskId,
      true,
      { Accept: "application/json" },
      formData,
      undefined,
      true
    );

    let jsonApiResponse = new JsonApiResponse<CaskImageUploadResponseData>();

    if (response.status !== 200) {
      jsonApiResponse.statusCode = response.status;
      jsonApiResponse.succeeded = false;
      return jsonApiResponse;
    }

    let fileUploadResponse = <CaskImageUploadResponseData>await response.json();
    jsonApiResponse.statusCode = response.status;
    jsonApiResponse.succeeded = true;

    jsonApiResponse.data = {
      thumbUrl: fileUploadResponse.thumbUrl,
      fullUrl: fileUploadResponse.fullUrl,
    };

    return jsonApiResponse;
  }
}
