import { action, computed, makeObservable, observable } from "mobx";
import CaskType from "models/cask-type";
import CaskTypesService from "services/cask-types";

class CaskTypesStore {
  @observable caskTypes: CaskType[] = [];
  @observable
  isLoading!: boolean;

  constructor() {
    makeObservable(this);
  }

  @computed
  get totalCasksCount() {
    return this.caskTypes.length;
  }

  @action
  async fetchCasks() {
    this.isLoading = true;
    let response = await CaskTypesService.fetchCaskTypes();

    if (!response.succeeded) {
      throw new Error(`Couldn't fetch cask types`);
    }

    this.caskTypes = response.data;
    this.isLoading = false;
  }

  find(id: number): CaskType {
    let caskType = this.caskTypes.find((c) => c.id === id);
    if (!caskType) {
      throw new Error(`Cask type with id ${id} couldn't be found`);
    }

    return caskType;
  }

  exists(id: number): boolean {
    return this.caskTypes.find((c) => c.id === id) !== undefined;
  }
}

export default CaskTypesStore;
export const caskTypesStore = new CaskTypesStore();
