import {
  FlatButton,
  FontIcon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui";
import User from "models/user";
import { FC } from "react";
import styled from "styled-components";
import SyncUserButton from "./SyncUserButton";

interface Props {
  users: User[];
  onEditUserClick: (userId: string) => void;
}

const Wrapper = styled.div`
  margin-top: 30px;
`;
function getName(firstName: string, lastName: string) {
  return `${firstName} ${lastName}`;
}

const UserList: FC<Props> = ({ users, onEditUserClick }) => (
  <Wrapper>
    <Table>
      <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
        <TableRow>
          <TableHeaderColumn>Namn</TableHeaderColumn>
          <TableHeaderColumn>Email</TableHeaderColumn>
          <TableHeaderColumn />
          <TableHeaderColumn />
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {users.map((user) => (
          <TableRow key={user.id} selectable={false}>
            <TableRowColumn>
              <span title={getName(user.firstName, user.lastName)}>
                {getName(user.firstName, user.lastName)}
              </span>
            </TableRowColumn>
            <TableRowColumn>
              <span title={user.email}>{user.email}</span>
            </TableRowColumn>
            <TableRowColumn>
              <FlatButton
                icon={<FontIcon className="material-icons">edit</FontIcon>}
                onClick={() => onEditUserClick(user.id)}
              />
            </TableRowColumn>
            <TableRowColumn>
              <SyncUserButton userId={user.id} />
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Wrapper>
);

export default UserList;
