import { gql, useQuery } from "@apollo/client";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/DataTable";
import { NavLink } from "react-router-dom";
import { formatDateString } from "utils/dates";
import {
  GetCaskOrders,
  GetCaskOrdersVariables,
  GetCaskOrders_adminScope_orders_nodes,
  OrderStatus,
  OrderType,
} from "__generated__/graphql";

const GET_CASK_ORDERS = gql`
  query GetCaskOrders($caskId: Int!) {
    adminScope {
      orders(where: { and: { caskId: { eq: $caskId } } }) {
        nodes {
          id
          vismaId
          status
          created
          updated
          orderType
          totalPrice
          user {
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const mapOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CANCELLED:
      return "Avbruten";
    case OrderStatus.COMPLETED:
      return "Slutförd";
    case OrderStatus.DECLINED:
      return "Avvisad";
    case OrderStatus.PENDING:
      return "Väntande";
    default:
      return "Okänd";
  }
};

export const mapOrderType = (status: OrderType) => {
  switch (status) {
    case OrderType.BOTTLING:
      return "Buteljering";
    case OrderType.CASK:
      return "Fat";
    case OrderType.FLAVOR_NOTE:
      return "Smaknot";
    case OrderType.SAMPLING:
      return "Fatprov";
    default:
      return "Okänd";
  }
};

const columnHelper =
  createColumnHelper<GetCaskOrders_adminScope_orders_nodes>();

const columns = [
  columnHelper.accessor("vismaId", {
    cell: (info) => (
      <NavLink to={window.location.pathname + "/order/" + info.row.original.id}>
        {info.getValue()}
      </NavLink>
    ),
    header: "Visma-id",
  }),
  columnHelper.accessor("orderType", {
    cell: (info) => mapOrderType(info.getValue()),
    header: "Typ",
  }),
  columnHelper.accessor("status", {
    cell: (info) => mapOrderStatus(info.getValue()),
    header: "Status",
  }),
  columnHelper.accessor("user", {
    cell: (info) =>
      `${info.getValue()?.firstName} ${info.getValue()?.lastName}`,
    header: "Namn",
  }),
  columnHelper.accessor("user.email", {
    cell: (info) => info.getValue(),
    header: "E-post",
  }),
  columnHelper.accessor("created", {
    cell: (info) => formatDateString(info.getValue() || ""),
    header: "Skapad",
  }),
  columnHelper.accessor("updated", {
    cell: (info) => formatDateString(info.getValue() || ""),
    header: "Uppdaterad",
  }),
];

const CaskOrdersTable: React.FC<{ caskId: number }> = ({ caskId }) => {
  const { loading, data } = useQuery<GetCaskOrders, GetCaskOrdersVariables>(
    GET_CASK_ORDERS,
    {
      variables: { caskId },
    }
  );
  const orders = data?.adminScope.orders?.nodes;

  if (loading) return <Spinner />;

  return (
    <>
      <Box mt={4} p={4}>
        <Heading size="lg" mb={2}>
          Ordrar från fatägarwebben
        </Heading>
        <DataTable
          data={orders as GetCaskOrders_adminScope_orders_nodes[]}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default CaskOrdersTable;
