import * as React from 'react';
import User from 'models/user';
import {
  Dialog,
  FlatButton,
  TextField,
  RaisedButton,
  IconButton,
  Checkbox
} from 'material-ui';
import styled from 'styled-components';
import EditUserInfo from './EditUserInfo';
import { UsersPageUiStore } from 'stores';
import { observer } from 'mobx-react';
import VismaInput from './VismaInput';
import { ContentAdd } from 'material-ui/svg-icons';

const Input = styled(TextField)`
  margin-right: 20px;
  display: block !important;
`;
const Error = styled.p`
  color: #ff0000;
  font-size: 12px;
`;

const AddUserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const StyledForm = styled.form`
  > * {
    margin-top: 20px;
  }
`;

export interface Props {
  user: User;
  onClose: () => void;
  onSaveClick: () => void;
  userPageUiStore: UsersPageUiStore;
}

@observer
class EditUserModal extends React.Component<Props> {
  vismaInputs() {
    const store = this.props.userPageUiStore;
    return store.vismaInputs.$.map((inputs, index) => {
      const { vismaCustomerNumber, vismaId } = inputs.$;
      return (
        <VismaInput
          key={index}
          vismaCustomerNumber={vismaCustomerNumber}
          vismaId={vismaId}
          onDelete={() => store.deleteVismaRow(index)}
          showButton={store.vismaInputs.$.length > 1}
        />
      );
    });
  }
  render() {
    const { onClose, onSaveClick, user, userPageUiStore } = this.props;
    return (
      <Dialog
        title="Redigera användare"
        actions={[
          <FlatButton key="cancel" onClick={onClose} label="Avbryt" />,
          <RaisedButton
            label="Spara"
            primary={true}
            disabled={userPageUiStore.form.hasError}
            key="Save"
            onClick={onSaveClick}
            type="submit"
            style={{ backgroundColor: 'red' }}
          />
        ]}
        modal={true}
        open={true}
        onRequestClose={onClose}
        autoScrollBodyContent={true}
      >
        <EditUserInfo user={user} />
        <div>
          <StyledForm
            onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
              evt.preventDefault();
              onSaveClick();
            }}
          >
            <Input
              type="email"
              value={userPageUiStore.email.value}
              errorText={userPageUiStore.email.error}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                userPageUiStore.email.onChange(e.currentTarget.value)
              }
              floatingLabelText="E-postadress"
            />
            <Checkbox
              label="Verifierad e-postadress"
              checked={userPageUiStore.emailConfirmed.value}
              onCheck={(e, checked) =>
                userPageUiStore.emailConfirmed.onChange(checked)
              }
            />
            {this.vismaInputs()}
          </StyledForm>
          <AddUserWrapper>
            <p>Lägg till användare</p>
            <IconButton onClick={() => userPageUiStore.addVismaInput()}>
              <ContentAdd />
            </IconButton>
          </AddUserWrapper>
          <Error>{userPageUiStore.saveError}</Error>
        </div>
      </Dialog>
    );
  }
}

export default EditUserModal;
