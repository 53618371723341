import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import BottlingPriceVariables from "scenes/BottlingPriceVariables";
import CaskImagesPage from "scenes/CaskImages/CaskImagesPage";
import CasksPage from "scenes/Casks";
import CaskOrderPage from "scenes/Casks/CaskOrderPage";
import CaskPage from "scenes/Casks/CaskPage";
import CasksListPage from "scenes/Casks/CasksListPage";
import CaskVismaOrderPage from "scenes/Casks/CaskVismaOrderPage";
import CaskTypeSupplyPage from "scenes/CaskTypeSupply";
import CaskTypeSupply from "scenes/CaskTypeSupply/components/CaskTypeSupply";
import CreateCaskTypePage from "scenes/CaskTypeSupply/scenes/CreateCaskType";
import EditCaskTypePage from "scenes/CaskTypeSupply/scenes/EditCaskType";
import Home from "scenes/Home";
import InformationPage from "scenes/Information";
import InformationContainer from "scenes/Information/components/InformationContainer";
import LogIn from "scenes/LogIn";
import NewsPage from "scenes/News";
import NewsItems from "scenes/News/components/NewsItems";
import EditNewsPage from "scenes/News/scenes/EditNews";
import RecipeSupplyPage from "scenes/RecipeSupply";
import RecipeSupply from "scenes/RecipeSupply/components/RecipeSupply";
import SyncQueuePage from "scenes/SyncQueue";
import SyncQueueListPage from "scenes/SyncQueue/SyncQueueListPage";
import UsersPage from "scenes/Users";
import ProtectedRoute from "utils/routes/ProtectedRoute";
import PublicOnlyRoute from "utils/routes/PublicOnlyRoute";

class Routes extends Component<{}, {}> {
  render() {
    return (
      <Switch>
        <ProtectedRoute path="/sync-queue">
          <SyncQueuePage>
            <Route exact path="/sync-queue" component={SyncQueueListPage} />
          </SyncQueuePage>
        </ProtectedRoute>
        <ProtectedRoute path="/casks">
          <CasksPage>
            <Route exact path="/casks" component={CasksListPage} />
            <Route exact path="/casks/:vismaId" component={CaskPage} />
            <Route
              exact
              path="/casks/:vismaId/order/:orderId"
              component={CaskOrderPage}
            />
            <Route
              exact
              path="/casks/:vismaId/visma-order/:orderId"
              component={CaskVismaOrderPage}
            />
          </CasksPage>
        </ProtectedRoute>
        <ProtectedRoute path="/cask-images">
          <Route exact path="/cask-images" component={CaskImagesPage} />
        </ProtectedRoute>
        <ProtectedRoute path="/cask-supply">
          <CaskTypeSupplyPage>
            <Switch>
              <Route exact path="/cask-supply" component={CaskTypeSupply} />
              <Route
                exact
                path="/cask-supply/edit/:id"
                component={EditCaskTypePage}
              />
              <Route
                exact
                path="/cask-supply/create"
                component={CreateCaskTypePage}
              />
            </Switch>
          </CaskTypeSupplyPage>
        </ProtectedRoute>
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute path="/news">
          <NewsPage>
            <Switch>
              <Route exact path="/news" component={NewsItems} />
              <Route exact path="/news/edit/:id" component={EditNewsPage} />
            </Switch>
          </NewsPage>
        </ProtectedRoute>
        <ProtectedRoute path="/information">
          <InformationPage>
            <Switch>
              <Route
                exact
                path="/information"
                component={InformationContainer}
              />
            </Switch>
          </InformationPage>
        </ProtectedRoute>
        <ProtectedRoute path="/recipe-supply">
          <RecipeSupplyPage>
            <Switch>
              <Route exact path="/recipe-supply" component={RecipeSupply} />
            </Switch>
          </RecipeSupplyPage>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/bottling-prices"
          component={BottlingPriceVariables}
        />
        <ProtectedRoute exact path="/users" component={UsersPage} />
        <PublicOnlyRoute exact path="/login" component={LogIn} />
      </Switch>
    );
  }
}

export default Routes;
