import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import { FontIcon, RaisedButton } from "material-ui";
import * as React from "react";
import { useMutation } from "react-query";
import UsersService from "services/users";

interface ISyncUserButtonProps {
  userId: string;
}

const SyncUserButton: React.FunctionComponent<ISyncUserButtonProps> = (
  props
) => {
  const { isLoading, isSuccess, mutateAsync, isError } = useMutation(() =>
    UsersService.syncUser(props.userId)
  );

  if (isLoading) return <LoadingSpinner size="small" />;
  if (isSuccess) return <FontIcon className="material-icons">done</FontIcon>;
  if (isError) return <FontIcon className="material-icons">close</FontIcon>;

  return (
    <RaisedButton onClick={async () => await mutateAsync()}>Synka</RaisedButton>
  );
};

export default SyncUserButton;
