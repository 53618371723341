import SessionStore, { sessionStore } from './session';
import CaskTypesStore, { caskTypesStore } from './cask-types';
import RecipesStore, { recipesStore } from './recipes';
import BottlingPriceVariablesStore, { bottlingPriceVariablesStore } from './bottling-price-variables';
import NewsStore, { newsStore } from './news';
import UsersStore, { usersStore } from './users';
import InformationStore, { informationStore } from './information';

export default {
    // Instances
    sessionStore,
    caskTypesStore,
    recipesStore,
    bottlingPriceVariablesStore,
    newsStore,
    usersStore,
    informationStore
};

export {
    // Classes
    SessionStore,
    CaskTypesStore,
    RecipesStore,
    BottlingPriceVariablesStore,
    NewsStore,
    UsersStore,
    InformationStore
};