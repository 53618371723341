import Paper from "material-ui/Paper";
import muiThemeable from "material-ui/styles/muiThemeable";
import { Component } from "react";
import styled from "styled-components";

const MuAppBar = styled(Paper)`
  top: 0px;
  left: 0px;
  position: fixed !important;
  z-index: 20;
  width: 100%;
  height: 64px;
  padding: 0px 10px;
`;

interface Props {
  muiTheme?: __MaterialUI.Styles.MuiTheme;
}

class AppBar extends Component<Props, {}> {
  get styles() {
    return {
      appBar: {
        backgroundColor: this.props.muiTheme!.palette!.primary1Color,
        borderRadius: "0",
        zIndex: 20,
      },
    };
  }
  render() {
    return (
      <MuAppBar style={this.styles.appBar}>
        <div>Stuff</div>
      </MuAppBar>
    );
  }
}

export default muiThemeable()(AppBar);
