import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import Recipe from "models/recipe";
import { minNumber, required, requiredType } from "utils/forms/validators";

export type RecipeFormState = {
  sweDescription: FieldState<string>;
  engDescription: FieldState<string>;
  price: FieldState<number>;
};

class RecipesFormUiStore {
  @observable recipeForms: {
    recipe: Recipe;
    formState: FormState<RecipeFormState>;
    formStore: RecipeFormState;
  }[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  resetForm() {
    this.recipeForms = [];
  }

  isPristine = (recipe: Recipe): boolean => {
    let recipeInstance = this.recipeForms.find(
      (element) => element.recipe.id === recipe.id
    );

    if (!recipeInstance) {
      throw new Error(
        `Could not check pristiness, recipe with id ${recipe.id} couldn't be found`
      );
    }

    if (recipeInstance.formStore.sweDescription.dirty) {
      return false;
    }
    if (recipeInstance.formStore.engDescription.dirty) {
      return false;
    }
    if (recipeInstance.formStore.price.dirty) {
      return false;
    }

    return true;
  };

  findFormStore(recipe: Recipe): RecipeFormState {
    let recipeInstance = this.recipeForms.find(
      (element) => element.recipe.id === recipe.id
    );

    if (!recipeInstance) {
      throw new Error(
        `Form store for recipe with id ${recipe.id} couldn't be found`
      );
    }
    return recipeInstance.formStore;
  }

  findFormState(recipe: Recipe): FormState<RecipeFormState> {
    let recipeInstance = this.recipeForms.find(
      (element) => element.recipe.id === recipe.id
    );

    if (!recipeInstance) {
      throw new Error(
        `Form state for recipe with id ${recipe.id} couldn't be found`
      );
    }
    return recipeInstance.formState;
  }

  @action
  setForm(recipe: Recipe) {
    let recipeInstance = this.recipeForms.find(
      (element) => element.recipe.id === recipe.id
    );

    let sweDescription = new FieldState(
      recipe.recipeInformation[1].description
    ).validators(required("Svensk beskrivning måste finnas"));
    let engDescription = new FieldState(
      recipe.recipeInformation[0].description
    ).validators(required("Engelsk beskrivning måste finnas"));
    let price = new FieldState(recipe.price).validators(
      requiredType<number>("Ett pris måste anges"),
      minNumber(-1, "Ett högre pris måste anges")
    );

    let formStore: RecipeFormState = {
      sweDescription: sweDescription,
      engDescription: engDescription,
      price: price,
    };

    let formState = new FormState<RecipeFormState>({
      sweDescription: sweDescription,
      engDescription: engDescription,
      price: price,
    });

    if (!recipeInstance) {
      this.recipeForms.push({ recipe, formState, formStore });
    } else {
      recipeInstance.recipe = recipe;
      recipeInstance.formState = formState;
      recipeInstance.formStore = formStore;
    }
  }
}

export default RecipesFormUiStore;
export const recipesFormUiStore = new RecipesFormUiStore();
