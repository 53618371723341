import Api from './api';
import BottlingPriceVariables from 'models/bottling-price-variables';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';

export default class BottlingPriceVariablesService {
    static async fetchBottlingPriceVariables(): Promise<JsonApiResponse<BottlingPriceVariables[]>> {
        let response = await Api.get('bottling-prices', false);
        return await toJsonApiResponse<BottlingPriceVariables[]>(response);
    }

    static async editBottlingPriceVariables(bottlingPriceVariables: BottlingPriceVariables):
    Promise<JsonApiResponse<BottlingPriceVariables>> {
        let response = await Api.put('bottling-prices', true, undefined, bottlingPriceVariables);
        return await toJsonApiResponse<BottlingPriceVariables>(response);
    }
}